<div class="col-12">
	<p>The following documents have been Approved <span><img src="../../../assets/icon-close.png" class="close-button"
				(click)="onNoClick()" style="height: 19px;"></span> </p>
</div>
<mat-accordion class="example-headers-align">
	<mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
		<mat-expansion-panel-header>
			<mat-panel-title>
				<strong> Document Check List</strong>
			</mat-panel-title>
		</mat-expansion-panel-header>
		<strong>
			<p style="margin-right: 150px;" class="text-right">Remarks</p>
		</strong>
		<div>
			<mat-checkbox class="example-margin" [checked]="allComplete" [indeterminate]="someCompleteIdentity()"
				(change)="setAllIdentity($event.checked)" *ngIf="showidentiydocuments">Identity Documents</mat-checkbox>
			<mat-checkbox class="example-margin" [checked]="allComplete" [indeterminate]="someCompleteIdentity()"
				(change)="setAllIdentity($event.checked)" *ngIf="aprovedlist.length===0">Identity Documents
			</mat-checkbox>
			<span class="example-list-section">
				<ul class="ul-width">
					<li class="approved-array-tr" *ngFor="let subtask of identityDocuments;let i=index">
						<span *ngIf="aprovedlist.indexOf(subtask.name) > -1">
							<mat-checkbox [(ngModel)]="subtask.completed" name="{{subtask.name}}"
								(change)="updateAllIdentityComplete($event,i,subtask.name,'cb')">
								{{subtask.name}}
							</mat-checkbox>
							<span class="li-width">
								<input class="form-control"
									(change)="updateAllIdentityComplete($event,i,subtask.name,'ip')" type="text"
									name="{{subtask.name}}" ngDefaultControl
									[value]="subtask.docName ? subtask.docName: ''"></span>
						</span>
						<span *ngIf="aprovedlist.length===0">
							<mat-checkbox [(ngModel)]="subtask.completed" name="{{subtask.name}}"
								(change)="updateAllIdentityComplete($event,i,subtask.name,'cb')">
								{{subtask.name}}
							</mat-checkbox>
							<span class="li-width">
								<input class="form-control"
									(change)="updateAllIdentityComplete($event,i,subtask.name,'ip')" type="text"
									name="{{subtask.name}}" ngDefaultControl
									[value]="subtask.docName ? subtask.docName: ''"></span>
						</span>
					</li>
					<!-- </div> -->
				</ul>
			</span>
		</div>
		<div>
			<mat-checkbox class="example-margin" [checked]="allComplete" [indeterminate]="someCompleteEducational()"
				(change)="setAllEducational($event.checked)" *ngIf="showeducationaldocuments">Educational Documents
			</mat-checkbox>
			<mat-checkbox class="example-margin" [checked]="allComplete" [indeterminate]="someCompleteEducational()"
				(change)="setAllEducational($event.checked)" *ngIf="aprovedlist.length===0">Educational Documents
			</mat-checkbox>
			<span class="example-list-section">
				<ul class="ul-width">
					<li class="approved-array-tr" *ngFor="let subtask of educationalDocuments;let i=index">
						<span *ngIf="aprovedlist.indexOf(subtask.name) > -1">
							<mat-checkbox [(ngModel)]="subtask.completed" name="{{subtask.name}}"
								(change)="updateAllEducationalComplete($event,i,subtask.name,'cb')">
								{{subtask.name}}
							</mat-checkbox>
							<span class="li-width"><input type="text" class="form-control"
									(change)="updateAllEducationalComplete($event,i,subtask.name,'ip')" maxlength="299"
									ngDefaultControl name="{{subtask.name}}"
									[value]="subtask.remarks ? subtask.remarks : ''">
							</span>
						</span>
						<span *ngIf="aprovedlist.length===0">
							<mat-checkbox [(ngModel)]="subtask.completed" name="{{subtask.name}}"
								(change)="updateAllEducationalComplete($event,i,subtask.name,'cb')">
								{{subtask.name}}
							</mat-checkbox>
							<span class="li-width"><input type="text" class="form-control"
									(change)="updateAllEducationalComplete($event,i,subtask.name,'ip')" maxlength="299"
									ngDefaultControl name="{{subtask.name}}"
									[value]="subtask.remarks ? subtask.remarks : ''">
							</span>
						</span>
					</li>
				</ul>
			</span>
		</div>
		<div>
			<mat-checkbox class="example-margin" [checked]="allComplete" [indeterminate]="someCompleteEmployment()"
				(change)="setAllEmployment($event.checked)" *ngIf="showemploymentdocuments">Employment Documents
			</mat-checkbox>
			<mat-checkbox class="example-margin" [checked]="allComplete" [indeterminate]="someCompleteEmployment()"
				(change)="setAllEmployment($event.checked)" *ngIf="aprovedlist.length===0">Employment Documents
			</mat-checkbox>
			<span class="example-list-section">
				<ul class="ul-width">
					<li class="approved-array-tr" *ngFor="let subtask of employmentDocuments;let i=index">
						<span *ngIf="aprovedlist.indexOf(subtask.name) > -1">
							<mat-checkbox [(ngModel)]="subtask.completed" name="{{subtask.name}}"
								(change)="updateAllEmploymentComplete($event,i,subtask.name,'cb')">
								{{subtask.name}}
							</mat-checkbox>
							<span class="li-width"><input type="text" class="form-control"
									(change)="updateAllEmploymentComplete($event,i,subtask.name,'ip')" maxlength="299"
									ngDefaultControl name="{{subtask.name}}"
									[value]="subtask.remarks ? subtask.remarks : ''"></span>
						</span>
						<span *ngIf="aprovedlist.length===0">
							<mat-checkbox [(ngModel)]="subtask.completed" name="{{subtask.name}}"
								(change)="updateAllEmploymentComplete($event,i,subtask.name,'cb')">
								{{subtask.name}}
							</mat-checkbox>
							<span class="li-width"><input type="text" class="form-control"
									(change)="updateAllEmploymentComplete($event,i,subtask.name,'ip')" maxlength="299"
									ngDefaultControl name="{{subtask.name}}"
									[value]="subtask.remarks ? subtask.remarks : ''"></span>
						</span>
					</li>
				</ul>
			</span>
		</div>
		<div>
			<mat-checkbox class="example-margin" [checked]="allComplete" [indeterminate]="someCompleteOthers()"
				(change)="setAllOthers($event.checked)" *ngIf="showotherdocuments">Other Documents</mat-checkbox>
			<mat-checkbox class="example-margin" [checked]="allComplete" [indeterminate]="someCompleteOthers()"
				(change)="setAllOthers($event.checked)" *ngIf="aprovedlist.length===0">Other Documents</mat-checkbox>
			<span class="example-list-section">
				<ul class="ul-width">
					<li class="approved-array-tr" *ngFor="let subtask of otherDocuments;let i=index">
						<span *ngIf="aprovedlist.indexOf(subtask.name) > -1">
							<mat-checkbox [(ngModel)]="subtask.completed" name="{{subtask.name}}"
								(change)="updateAllOthersComplete($event,i,subtask.name,'cb')">
								{{subtask.name}}
							</mat-checkbox>
							<span class="li-width"><input type="text" class="form-control"
									(change)="updateAllOthersComplete($event,i,subtask.name,'ip')" ngDefaultControl
									name="{{subtask.name}}" [value]="subtask.remarks ? subtask.remarks : ''"></span>
						</span>
						<span *ngIf="aprovedlist.length===0">
							<mat-checkbox [(ngModel)]="subtask.completed" name="{{subtask.name}}"
								(change)="updateAllOthersComplete($event,i,subtask.name,'cb')">
								{{subtask.name}}
							</mat-checkbox>
							<span class="li-width"><input type="text" class="form-control"
									(change)="updateAllOthersComplete($event,i,subtask.name,'ip')" ngDefaultControl
									name="{{subtask.name}}" [value]="subtask.remarks ? subtask.remarks : ''"></span>
						</span>
					</li>
				</ul>
			</span>
		</div>
	</mat-expansion-panel>
</mat-accordion>
<br>
<div *ngIf="largeFileSize">
	<div class="error_msg_class" id="error_msg_id"> <img src="../../../../assets/round_cancel_black_24dp.png"
			alt="Error"> The selected file could not be uploaded. Please select the file which is less than 200mb.
	</div>
</div>
<div class="row">
	<div class="col-12">
		<div class="shaded_box">
			<label *ngIf="!filestoUpload" class="choose_file_text" id="choose_file_text" for="file">Choose a zip file to
				upload</label>
			<label *ngIf="filestoUpload" class="choose_file_text" id="choose_file_text" for="file">Upload the zip
				file</label>
			<div id="hideFileName">
				<p id="hide_fName_class">File Name:
					<b>{{displayFileName}}</b>
					<img *ngIf="displayFileName" id="delete-icon" style="margin-left: 12px;"
						(click)="removeFile($event)" src="../../../../assets/delete.png" class="cancel-icon"
						alt="Cancel" />
				</p>
			</div>
			<p *ngIf="!filestoUpload" class="img_container" id="img_container"><img
					src="../../../../assets/baseline_description_black_24dp.png" alt="File Upload"></p>
			<p *ngIf="!filestoUpload" class="btn_container" id="btn_container">
				<input type="file" id="file" accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed" (change)="handleFileInput($event.target.files)"
					id="file-upload-input" class="select_file_btn" />
			</p>
			<div id="progress-bar-div" class="col-11" style="float: left">
				<mat-progress-bar mode="determinate" value="{{percentDone}}" *ngIf="progressDisplay"
					class="progressbar"></mat-progress-bar>
				<span *ngIf="progressDisplay">{{percentDone}}% completed.</span>
			</div>
			<span class="cdk-visually-hidden" *ngIf="uploadComplete">
				upload complete
			</span>
			<div *ngIf="filestoUpload" style="text-align: center;">
				<button mat-raised-button color="primary" id="upload-id-btn" (click)="uploadFileToActivity()"
					[disabled]="uploadComplete">Upload</button>
			</div>
		</div>
		<div>
			<p style="color: rgb(218, 8, 8);">Only "Zip" Files are allowed and maximum file size limit is 200MB</p>
		</div>
	</div>
</div>
<div class="col-12 success-message" *ngIf="uploadComplete">
	<p>The file has been uploaded successfully!</p>
</div>
<div class="col-12" style="text-align: center;">
	<button type="submit" (click)="submitApproveDialog()" id="approve-id-btn" [disabled]="!isfileuploaded"
		mat-raised-button class="submit-button-css">Submit</button>
	<button style="margin-left: 42px;" class="cancel_Approved" (click)="closeApproveDialog()" type="Cancel"
		id="pseudoCancel" mat-raised-button class="submit-button-css">Cancel</button>
</div>
