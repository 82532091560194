<div class="row emp-info-row">
	<div class="col-12 layout_class">
		<h5 class="heading_class"> Background Verification</h5>
		<hr class="employee-info-hr">
		<div class="wrapper_class">
			<mat-tab-group mat-align-tabs="start" (selectedTabChange)="tabChanged($event)"
				[(selectedIndex)]="selectedIndex">
				<!-- <div> -->
				<mat-tab label="Registration">
					<h6 class="sub_text">Please fill in the following details.</h6>
					<form [formGroup]="registrationBGVForm">
						<div class="row">
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Employee Full Name*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<input matInput formControlName="name" maxlength="100"
										(focus)="omit_special_char($event);lettersOnly($event)" required>
									<mat-error *ngIf="registrationBGVForm.get('name').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
							<div class="col-sm-12 col-lg-4 col-md-6 personal-info-columns">
								<div>
									<p class="label_class">Date of Birth*</p>
									<mat-form-field appearance="outline" class="form_field_style">
										<input formControlName="dob" matInput [matDatepicker]="personalInfodob" readonly
											[max]="maxDob1">
										<mat-datepicker-toggle matSuffix [for]="personalInfodob">
										</mat-datepicker-toggle>
										<mat-datepicker #personalInfodob disabled></mat-datepicker>
									</mat-form-field>
								</div>
							</div>
							<div class="col-sm-12 col-lg-4 col-md-6 personal-info-columns">
								<div>
									<p class="label_class">Gender*</p>
									<mat-form-field appearance="outline" class="form_field_style">
										<mat-select formControlName="gender" disabled>
											<mat-option value="M"> Male</mat-option>
											<mat-option value="F"> Female</mat-option>
											<mat-option value="O">Other</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-12 col-lg-4 col-md-6 personal-info-columns">
								<div>
									<p class="label_class">Email Address*</p>
									<mat-form-field appearance="outline" class="form_field_style">
										<input class="email-address-input" formControlName="email" maxlength="200"
											autocomplete="off" style="text-transform: lowercase;"
											pattern="^[A-Za-z0-9._%+-]+@gspann\.com$" matInput required>
										<mat-error *ngIf="registrationBGVForm.get('email').hasError('required')">
											This is a required field. </mat-error>
										<mat-error *ngIf="registrationBGVForm.get('email').hasError('email')">Enter
											a proper email address.</mat-error>
										<mat-error *ngIf="registrationBGVForm.get('email').hasError('pattern')">Enter
											GSPANN email id</mat-error>
										<mat-hint>Only GSPANN email id is allowed.</mat-hint>
									</mat-form-field>
								</div>
							</div>
							<div class="col-sm-12 col-lg-4 col-md-6 personal-info-columns">
								<div>
									<p class="label_class">Employee Mobile Number*</p>
									<mat-form-field appearance="outline" class="form_field_style">
										<input class="email-address-input" id="phone" name="phoneNumber"
											formControlName="phone" autocomplete="off"
											(keypress)="validateNumber($event)" pattern="([0-9]+)" minlength="10"
											maxlength="10" matInput required>
										<mat-error *ngIf="registrationBGVForm.get('phone').hasError('required')">
											This is a required field. </mat-error>
										<mat-error *ngIf="registrationBGVForm.get('phone').hasError('minlength')">
											A mobile number should be of 10 digits. </mat-error>
									</mat-form-field>
								</div>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Father's Name*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<input matInput formControlName="fathersName" maxlength="100"
										(focus)="omit_special_char($event);lettersOnly($event)" required>
									<mat-error *ngIf="registrationBGVForm.get('fathersName').hasError('required')">
										This is a required field. </mat-error>
								</mat-form-field>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Employee ID</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<input matInput formControlName="employeeID" maxlength="20">
								</mat-form-field>
							</div>
							<div class="col-sm-12 col-lg-4 col-md-6 personal-info-columns">
								<div>
									<p class="label_class">Date of Joining</p>
									<mat-form-field appearance="outline" class="form_field_style">
										<input formControlName="joiningDate" matInput [matDatepicker]="joiningDate"
											readonly>
										<mat-datepicker-toggle matSuffix [for]="joiningDate">
										</mat-datepicker-toggle>
										<mat-datepicker #joiningDate disabled></mat-datepicker>
									</mat-form-field>
								</div>
							</div>
							<div class="col-sm-12 col-lg-4 col-md-6 personal-info-columns">
								<p class="label_class">Aadhar Number*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<input matInput formControlName="aadharNumber" maxlength="100" required>
									<mat-error *ngIf="registrationBGVForm.get('aadharNumber').hasError('required')">
										This is a required field.
									</mat-error>
									<mat-error *ngIf="registrationBGVForm.get('aadharNumber').hasError('pattern')">
										Please enter a valid Aadhar number.
									</mat-error>
								</mat-form-field>
							</div>
						</div>
						<div class="row">
							<h5 class="col-12 personal-info-columns">Professional Details</h5>
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Profession*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<mat-select formControlName="professionId"
										(selectionChange)="professionSelected($event)" required>
										<mat-option *ngFor="let profession of professionList" [value]="profession.id">
											{{profession.value}}</mat-option>
									</mat-select>
									<mat-error *ngIf="registrationBGVForm.get('professionId').hasError('required')">This
										is a required field.</mat-error>
								</mat-form-field>
							</div>
							<div *ngIf="displayOtherField" class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Designation*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<input type="text" matInput formControlName="otherProfession" maxlength="50">
								</mat-form-field>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Working City*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<input matInput formControlName="workingCity" maxlength="100"
										(focus)="omit_special_char($event);lettersOnly($event)" required>
									<mat-error *ngIf="registrationBGVForm.get('workingCity').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
						</div>
						<div class="row">
							<h5 class="col-12 personal-info-columns">Address</h5>
							<div class="col-6 personal-info-columns">
								<p class="label_class">Current Address</p>
								<mat-form-field appearance="outline"  required class="form_field_style">
									<textarea matInput formControlName="currentAddress" style="text-transform: capitalize;"
										maxlength="300"></textarea>
									<mat-error *ngIf="registrationBGVForm.get('currentAddress').hasError('required')">
										This is a required field. </mat-error>
								</mat-form-field>
							</div>
							<div class="col-6 personal-info-columns">
								<p class="label_class">Permanent Address</p>
								<mat-form-field appearance="outline" required class="form_field_style">
									<textarea matInput formControlName="permanentAddress" style="text-transform: capitalize;"
										maxlength="300"></textarea>
									<mat-error *ngIf="registrationBGVForm.get('permanentAddress').hasError('required')">
										This is a required field. </mat-error>
								</mat-form-field>
							</div>
						</div>
						<!-- <div class="row">
							<div class="col-12 personal-info-columns">
								<mat-checkbox formControlName="hasConsentFlag" ngDefaultControl><strong> Consent for
										performing Aadhaar and Identity verification</strong></mat-checkbox>
							</div>
						</div>
						<div class="row">
							<div class="col-12 personal-info-columns">
								<p formControlName="hasConsentText" class="consent-text-class" ngDefaultControl>
									<em>{{consentText}}</em>
								</p>
							</div>

						</div> -->
						<div class="row">
							<div class="col-12 buttons-row-class">
								<button *ngIf="newRegister && candidateView" mat-button
									[disabled]="!registrationBGVForm.valid" color="primary" class="add-button-css"
									(click)="registerCandidate()">Register</button>
								<button *ngIf="newRegister && HRView" mat-button [disabled]="!registrationBGVForm.valid"
									color="primary" class="add-button-css" (click)="registerCandidate()">Register &
									Next</button>
								<button *ngIf="alreadyRegister && HRView" class="add-button-css" mat-button
									color="primary" (click)="moveToNextTab()">Next</button>
							</div>
						</div>
						<div class="row" *ngIf="showRegistrationID">
							<div class="col-12 personal-info-columns">
								<p class="ccrvState"> Registration ID: <b><em>{{individualID}}</em></b></p>
							</div>
						</div>
						<div class="dark-overlay" *ngIf="registerLoad">
							<mat-spinner class="loader" strokeWidth="3" [diameter]="50"> </mat-spinner>
						</div>
					</form>
				</mat-tab>
				<mat-tab label="Education" *ngIf="HRView">
					<h6 class="sub_text">Please fill in your educational details.</h6>
					<form [formGroup]="educationalVerificationForm" #eduForm="ngForm">
						<div class="row">
							<div class="col-sm-12 col-lg-4 col-md-6 personal-info-columns">
								<p class="label_class">Name as per Document*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<input matInput formControlName="nameAsPerDocument" maxlength="100" required>
									<mat-error
										*ngIf="educationalVerificationForm.get('nameAsPerDocument').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
							<div class="col-sm-12 col-lg-4 col-md-6 personal-info-columns">
								<p class="label_class">Education*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<mat-select formControlName="level" required (selectionChange)="educationSelected($event)">
										<mat-option value="TENTH_STD">High School (10th) </mat-option>
										<mat-option value="TWELFTH_STD">Pre-University (+2)</mat-option>
										<mat-option value="DIPLOMA">Diploma</mat-option>
										<mat-option value="GRADUATE">Graduate</mat-option>
										<mat-option value="MASTERS">Masters</mat-option>
										<mat-option value="PHD">PHD</mat-option>
										<mat-option value="POST_DOC">Postdoctoral Fellow</mat-option>
										<mat-option value="POST_GRADUATE_DIPLOMA">Post Graduate Diploma</mat-option>
										<mat-option value="PROFESSIONAL_COURSE">Professional Course</mat-option>
										<mat-option value="OTHER">Other</mat-option>


									</mat-select>
									<mat-error *ngIf="educationalVerificationForm.get('level').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
							<div class="col-sm-12 col-lg-4 col-md-6 personal-info-columns">
								<p class="label_class">Name of the Institute*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<input matInput formControlName="nameOfInstitute" maxlength="100" required>
									<mat-error
										*ngIf="educationalVerificationForm.get('nameOfInstitute').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Date of Issue*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<input formControlName="issueDate" matInput [matDatepicker]="issueDate" readonly
										[max]="maxIssueDate">
									<mat-datepicker-toggle matSuffix [for]="issueDate">
									</mat-datepicker-toggle>
									<mat-datepicker #issueDate></mat-datepicker>
								</mat-form-field>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Registration Number*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<input matInput formControlName="registrationNumber" maxlength="100" required>
									<mat-error
										*ngIf="educationalVerificationForm.get('registrationNumber').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
						</div>

						<div class="row">
							<div class="col-12">
								<h6 class="sub_text">Please attach the supporting educational document to be verified
								</h6>
								<p class="file_formats edu_file_format">The allowed file formats are .pdf,.jpg,.jpeg</p>
							</div>
						</div>
						<div class="row">
							<div class="col-2 personal-info-columns">
								<h6>Educational Document</h6>
								<!-- <sub>Attach last 3 months payslips.</sub> -->
							</div>
							<div class="col-2 personal-info-columns" id="upload_button">
								<label>
									<input formControlName="fileInput" #fileInput type="file" required
										accept=".pdf, .jpg, .jpeg" id="file-upload"
										(change)="handleFileInput($event.target.files)" (click)="fileInput.value = null"
										style="outline: none;" />
									<span class="btn btn-sm btn-outline-primary label-attach" mat-raised-button>
										<span class="plus-icon"></span>
										Document
									</span>
								</label>
							</div>
							<!-- <div class="col-2 personal-info-columns" *ngIf="displayJobFileName">
							</div> -->
							<div class="col-6 personal-info-columns">
								<section class="scroll">
									<ul class="list-group list-group-vertical">
										<li class="list-group-item d-flex justify-content-between align-items-center"
											*ngFor="let name of displayEducationList; let in = index;">
											{{name}}
											<img class="resume-remove-class" src="../../../assets/rejected-1.png"
												alt="remove" (click)="removeEduDocs(in)">
										</li>
									</ul>
								</section>
							</div>
						</div>
					</form>
					<hr>
					<h6 class="sub_text" *ngIf="showInsufficiencyFormFields">Please fill in the insufficiency list.</h6>
					<form [formGroup]="educationInsufficiencyForm">
						<div class="row" *ngIf="showInsufficiencyFormFields">
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Status*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<mat-select (selectionChange)="statusSelected($event,'education')" formControlName="insufficiencyStatus" required>
										<mat-option value="Resolved">Resolved</mat-option>
										<mat-option value="Closed">Closed</mat-option>
									</mat-select>
									<!-- <input matInput formControlName="insufficiencyStatus" maxlength="100" required> -->
									<mat-error
										*ngIf="educationInsufficiencyForm.get('insufficiencyStatus').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Reason*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<mat-select formControlName="insufficiencyReason" required>
										<mat-option value="LeftJob">Left Job</mat-option>
										<mat-option value="DataNotAvailable">Data Not Available</mat-option>
									</mat-select>
									<!-- <input matInput formControlName="insufficiencyReason" maxlength="100" required> -->
									<mat-error
										*ngIf="educationInsufficiencyForm.get('insufficiencyReason').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Remarks*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<input matInput formControlName="insufficiencyRemarks" maxlength="100" required>
									<mat-error
										*ngIf="educationInsufficiencyForm.get('insufficiencyRemarks').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
						</div>
					</form>
					<div class="row">
						<div class="col-12 buttons-row-class">
							<button *ngIf="!educaationDocID && !showInsufficiencyFormFields"
								class="add-button-css float_right" mat-raised-button
								[disabled]="!educationalVerificationForm.valid" color="primary"
								(click)="saveEducationalDocuments()">Submit</button>
							<button *ngIf="showInsufficiencyFormFields" class="add-button-css float_right" type="button"
								mat-raised-button (click)="updateInsufficiencies('education')">Update Insufficiencies</button>
							<button style="margin-right:20px;" mat-raised-button class="add-button-css float_right"
								type="button" (click)="resetEducationalData()">Reset</button>
						</div>
					</div>
					<div class="row">
						<div class="col-12 member-added">
							<h6 class="sub_text">My Educational Details</h6>
						</div>
						<div class="col-12">
							<p class="sub_text" *ngIf="educationDataSource.data.length == 0">No details added.</p>
						</div>
						<div class="col-12">
							<mat-table id="mat-workEx-table" [dataSource]="educationDataSource"
								*ngIf="educationDataSource.data.length > 0" class="mat-elevation-z0">
								<ng-container matColumnDef="srNo">
									<mat-header-cell *matHeaderCellDef> Sr No. </mat-header-cell>
									<mat-cell *matCellDef="let element;let i=index"> {{i+1}} </mat-cell>
								</ng-container>
								<ng-container matColumnDef="nameAsPerDocument">
									<mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
									<mat-cell *matCellDef="let element"> {{element.nameAsPerDocument}}
									</mat-cell>
								</ng-container>
								<ng-container matColumnDef="level">
									<mat-header-cell *matHeaderCellDef> Degree </mat-header-cell>
									<mat-cell *matCellDef="let element"> {{element.level}} </mat-cell>
								</ng-container>

								<ng-container matColumnDef="files">
									<mat-header-cell *matHeaderCellDef> Attached File </mat-header-cell>
									<mat-cell *matCellDef="let element">
										<div class="loop-list-item">
											<div>
												<li *ngFor="let file of element.files">
													<a href="{{file.servingUrl}}" target="_blank">{{file.fileName}}</a>
												</li>
											</div>
										</div>
									</mat-cell>
								</ng-container>

								<ng-container matColumnDef="state">
									<mat-header-cell *matHeaderCellDef> State </mat-header-cell>
									<mat-cell *matCellDef="let element"> {{element.state}} </mat-cell>
								</ng-container>
								<ng-container matColumnDef="options">
									<mat-header-cell *matHeaderCellDef></mat-header-cell>
									<mat-cell *matCellDef="let element">
										<button class="icons-class"> <img src="../../../assets/edit.png"
												(click)="updateEduData(element)" alt="edit"></button>
									</mat-cell>
								</ng-container>
								<mat-header-row *matHeaderRowDef="educationDisplayedColumns"></mat-header-row>
								<mat-row *matRowDef="let row; columns: educationDisplayedColumns;"></mat-row>
							</mat-table>
						</div>
					</div>
					<div class="dark-overlay" *ngIf="eduLoad">
						<mat-spinner class="loader" strokeWidth="3" [diameter]="50"> </mat-spinner>
					</div>
				</mat-tab>
				<mat-tab label="Employment" *ngIf="HRView">
					<h6 class="sub_text">Please fill in your previous employment details.</h6>
					<form [formGroup]="employmentVerificationForm" #myForm="ngForm">
						<div>
							<div>
								<div class="row">
									<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
										<p class="label_class">Name as per Employer Records*</p>
										<mat-form-field appearance="outline" class="form_field_style">
											<input matInput formControlName="nameAsPerEmployerRecords" maxlength="100"
												required>
											<mat-error
												*ngIf="employmentVerificationForm.get('nameAsPerEmployerRecords').hasError('required')">
												This is a required field.
											</mat-error>
										</mat-form-field>
									</div>
									<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
										<p class="label_class">Employer Name*</p>
										<mat-form-field appearance="outline" class="form_field_style">
											<mat-select formControlName="employerName"
												(selectionChange)="employerSelected($event)" required>
												<mat-option *ngFor="let profession of employerNameList"
													[value]="profession">{{profession}}</mat-option>
											</mat-select>
											<mat-error
												*ngIf="employmentVerificationForm.get('employerName').hasError('required')">
												This is a required field.
											</mat-error>
										</mat-form-field>
									</div>
									<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
										<p class="label_class">Designation</p>
										<mat-form-field appearance="outline" class="form_field_style">
											<input matInput formControlName="designation" maxlength="100" required [(ngModel)]="testDesg">
											<mat-error
												*ngIf="employmentVerificationForm.get('designation').hasError('required')">
												This is a required field.
											</mat-error>
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
										<p class="label_class">Date of Joining*</p>
										<mat-form-field appearance="outline" class="form_field_style">
											<input formControlName="joiningDate" matInput
												[matDatepicker]="dateOfJoining" readonly="true">
											<mat-datepicker-toggle matSuffix [for]="dateOfJoining">
											</mat-datepicker-toggle>
											<mat-datepicker #dateOfJoining readonly="true"></mat-datepicker>
										</mat-form-field>
									</div>
									<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
										<p class="label_class">Date of Leaving*</p>
										<mat-form-field appearance="outline" class="form_field_style">
											<input formControlName="lastWorkingDate" matInput
												[matDatepicker]="dateOfLeaving" readonly="true">
											<mat-datepicker-toggle matSuffix [for]="dateOfLeaving">
											</mat-datepicker-toggle>
											<mat-datepicker #dateOfLeaving readonly="true"></mat-datepicker>
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<h6 class="col-12 sub_text">Please upload any of the following documents</h6>
									<p class="file_formats">The allowed file formats are .pdf,.jpg,.jpeg</p>
								</div>
								<div class="row">
									<div class="col-2 personal-info-columns">
										<h6>Salary Slip - three months</h6>
										<!-- <sub>Attach last 3 months payslips.</sub> -->
									</div>
									<div class="col-2 personal-info-columns" id="upload_button"
										*ngIf="displaySalaryList.length !== 3">
										<label>
											<input #salaryDetails formControlName="salaryDetails" type="file"
												(change)="handleSalaryDetails($event.target.files,i)"
												accept=".pdf, .jpg, .jpeg" required>
											<span class="btn btn-sm btn-outline-primary label-attach" mat-raised-button>
												<span class="plus-icon"></span>
												Document
											</span>
										</label>
									</div>
									<div class="col-2 personal-info-columns" *ngIf="displaySalaryList.length === 3">
									</div>
									<div class="col-6 personal-info-columns" id="{{'upload_document-'+i}}"
										*ngIf="displaySalaryFileName">
										<section class="scroll">
											<ul class="list-group list-group-vertical">
												<li class="list-group-item d-flex justify-content-between align-items-center"
													*ngFor="let name of displaySalaryList; let in = index;">
													{{name}}
													<img class="resume-remove-class"
														src="../../../assets/rejected-1.png" alt="remove"
														(click)="removeSalarySlip(in)">
												</li>
											</ul>
										</section>

									</div>
								</div>
								<br><br>
								<div class="row">
									<div class="col-2 personal-info-columns">
										<h6>Appointment Letter</h6>
									</div>
									<div class="col-2 personal-info-columns" id="upload_button"
										*ngIf="!displayApptJobFileName">
										<label>
											<input #fileInput3 type="file" formControlName="appointmentDetails"
												(change)="attachAppointmentLetter($event.target.files)"
												(click)="fileInput3.value = null" accept=".pdf, .jpg, .jpeg" required>
											<span class="btn btn-sm btn-outline-primary label-attach" mat-raised-button>
												<span class="plus-icon"></span>
												Document
											</span>
										</label>
									</div>
									<div class="col-2 personal-info-columns" *ngIf="displayApptJobFileName"></div>
									<div class="col-6 personal-info-columns" id="upload_document"
										*ngIf="displayApptJobFileName">
										<section class="scroll">
											<ul class="list-group list-group-vertical">
												<li
													class="list-group-item d-flex justify-content-between align-items-center">
													{{displayApptJobFileName}}
													<img class="resume-remove-class"
														src="../../../assets/rejected-1.png" alt="remove"
														(click)="removeAppointmentLetter()">
												</li>
											</ul>
										</section>
									</div>
								</div>
								<br><br>
								<div class="row">
									<div class="col-2 personal-info-columns">
										<h6>Experience Letter</h6>
									</div>
									<div class="col-2 personal-info-columns" id="upload_button"
										*ngIf="!displayexpJobFileName">
										<label>
											<input #fileInput4 type="file"
												(change)="attachExperienceLetter($event.target.files)"
												(click)="fileInput4.value = null" accept=".pdf, .jpg, .jpeg" required>
											<span class="btn btn-sm btn-outline-primary label-attach" mat-raised-button>
												<span class="plus-icon"></span>
												Document
											</span>
										</label>
									</div>
									<div class="col-2 personal-info-columns" *ngIf="displayexpJobFileName"></div>
									<div class="col-6 personal-info-columns" id="upload_document"
										*ngIf="displayexpJobFileName">
										<section class="scroll">
											<ul class="list-group list-group-vertical">
												<li
													class="list-group-item d-flex justify-content-between align-items-center">
													{{displayexpJobFileName}}
													<img class="resume-remove-class"
														src="../../../assets/rejected-1.png" alt="remove"
														(click)="removeAttachedFiles()">
												</li>
											</ul>
										</section>
									</div>
								</div>
							</div>
						</div>
					</form>
					<h6 class="sub_text" *ngIf="showEmpInsufficiencyFormFields">Please fill in the insufficiency list.</h6>
					<form [formGroup]="employmentInsufficiencyForm">
						<div class="row" *ngIf="showEmpInsufficiencyFormFields">
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Status*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<mat-select (selectionChange)="statusSelected($event,'employment')" formControlName="insufficiencyStatus" required>
										<mat-option value="Resolved">Resolved</mat-option>
										<mat-option value="Closed">Closed</mat-option>
									</mat-select>
									<mat-error
										*ngIf="employmentInsufficiencyForm.get('insufficiencyStatus').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Reason*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<mat-select formControlName="insufficiencyReason" required>
										<mat-option value="LeftJob">Left Job</mat-option>
										<mat-option value="DataNotAvailable">Data Not Available</mat-option>
									</mat-select>
									<mat-error
										*ngIf="employmentInsufficiencyForm.get('insufficiencyReason').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Remarks*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<input matInput formControlName="insufficiencyRemarks" maxlength="100" required>
									<mat-error
										*ngIf="employmentInsufficiencyForm.get('insufficiencyRemarks').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
						</div>
					</form>
							<div class="row">
								<div class="col-12">
									<span  *ngIf="!isUpdateEmployment && !showEmpInsufficiencyFormFields">
									<button mat-raised-button
										class="add-button-css float_right"
										[disabled]="!employmentVerificationForm.valid && !(apptjobFileSelected || jobFileSelected || expjobFileSelected)"
										(click)="postEmploymentData()">Submit
									</button></span>
									<button *ngIf="isUpdateEmployment && showEmpInsufficiencyFormFields"
										[disabled]="!employmentVerificationForm.valid && !(displayApptJobFileName || displaySalaryList.length === 3 || displayexpJobFileName)"
										 mat-raised-button class="add-button-css float_right"
										(click)="postEmploymentData()">Update</button>
									<button style="margin-right:20px ;" mat-raised-button
										class="add-button-css float_right" type="button"
										(click)="resetEmploymentData()">Reset</button>
										<button *ngIf="showEmpInsufficiencyFormFields" style="margin-right:20px;" mat-raised-button
										class="add-button-css float_right" type="button"
										(click)="updateInsufficiencies('employment')">Update Insufficiencies</button>
								</div>
							</div>
							<hr>
							<div class="row">
								<div class="col-12 member-added">
									<h6 class="sub_text">My Employers</h6>
								</div>
								<div class="col-12">
									<p class="sub_text" *ngIf="workExDataSource.data.length == 0">No details added.</p>
								</div>
								<div class="col-12">
									<mat-table id="mat-workEx-table" [dataSource]="workExDataSource"
										*ngIf="workExDataSource.data.length > 0" class="mat-elevation-z0">
										<ng-container matColumnDef="srNo">
											<mat-header-cell *matHeaderCellDef> Sr No. </mat-header-cell>
											<mat-cell *matCellDef="let element;let i=index"> {{i+1}} </mat-cell>
										</ng-container>
										<ng-container matColumnDef="nameAsPerEmployerRecords">
											<mat-header-cell *matHeaderCellDef> Employee Name </mat-header-cell>
											<mat-cell *matCellDef="let element"> {{element.nameAsPerEmployerRecords}}
											</mat-cell>
										</ng-container>
										<ng-container matColumnDef="employerName">
											<mat-header-cell *matHeaderCellDef> Employer Name </mat-header-cell>
											<mat-cell *matCellDef="let element"> {{element.employerName}} </mat-cell>
										</ng-container>
										<ng-container matColumnDef="documents">
											<mat-header-cell *matHeaderCellDef> Attached File </mat-header-cell>
											<mat-cell *matCellDef="let element">
												<div class="loop-list-item">
													<div *ngFor="let abc of element.documents">
														<li *ngFor="let file of abc.files">
															<a href="{{file.servingUrl}}"
																target="_blank">{{abc.documentType}}</a>
														</li>
													</div>
												</div>
											</mat-cell>
										</ng-container>

										<ng-container matColumnDef="state">
											<mat-header-cell *matHeaderCellDef> State </mat-header-cell>
											<mat-cell *matCellDef="let element"> {{element.state}} </mat-cell>
										</ng-container>
										<ng-container matColumnDef="options">
											<mat-header-cell *matHeaderCellDef></mat-header-cell>
											<mat-cell *matCellDef="let element">
												<button class="icons-class"> <img src="../../../assets/edit.png"
														(click)="updateEmpData(element)" alt="edit"></button>
											</mat-cell>
										</ng-container>
										<mat-header-row *matHeaderRowDef="workExDisplayedColumns"></mat-header-row>
										<mat-row *matRowDef="let row; columns: workExDisplayedColumns;"></mat-row>
									</mat-table>
								</div>
							</div>
						<div class="dark-overlay" *ngIf="empLoad">
							<mat-spinner class="loader" strokeWidth="3" [diameter]="50"> </mat-spinner>
						</div>
				</mat-tab>
				<mat-tab label="Additional Verification" *ngIf="HRView">
					<h6 class="sub_text">Criminal Court Record Verification</h6>
					<form [formGroup]="ccrvForm">
						<div class="row">
							<div class="col-4 personal-info-columns">
								<p class="label_class"><b> Current Address - </b></p><em>{{currentAddress}}</em>
							</div>
							<div class="col-4 personal-info-columns">
								<p class="label_class"><b> Permanent Address - </b></p><em>{{permanentAddress}}</em>
							</div>
							<div *ngIf="!ccrvState" class="col-4 personal-info-columns buttons-row-class">
								<button mat-button color="primary" class="add-button-css next-button"
									(click)="initiateCCRV()">Initiate CCRV</button>
							</div>
							<div *ngIf="ccrvState" class="col-4 personal-info-columns">
								<p class="ccrvState next-button" formControlName="state" ngDefaultControl>State of CCRV
									for the
									candidate : <b><em>{{ccrvState}}</em></b></p>
							</div>
						</div>
					</form>
					<h6 class="sub_text" *ngIf="showCCRVInsufficiencyFormFields">Please fill in the insufficiency list.</h6>
					<form [formGroup]="ccrvInsufficiencyForm">
						<div class="row" *ngIf="showCCRVInsufficiencyFormFields">
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Status*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<mat-select (selectionChange)="statusSelected($event,'ccrv')" formControlName="insufficiencyStatus" required>
										<mat-option value="Resolved">Resolved</mat-option>
										<mat-option value="Closed">Closed</mat-option>
									</mat-select>
									<!-- <input matInput formControlName="insufficiencyStatus" maxlength="100" required> -->
									<mat-error
										*ngIf="ccrvInsufficiencyForm.get('insufficiencyStatus').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Reason*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<mat-select formControlName="insufficiencyReason" required>
										<mat-option value="LeftJob">Left Job</mat-option>
										<mat-option value="DataNotAvailable">Data Not Available</mat-option>
									</mat-select>
									<!-- <input matInput formControlName="insufficiencyReason" maxlength="100" required> -->
									<mat-error
										*ngIf="ccrvInsufficiencyForm.get('insufficiencyReason').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Remarks*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<input matInput formControlName="insufficiencyRemarks" maxlength="100" required>
									<mat-error
										*ngIf="ccrvInsufficiencyForm.get('insufficiencyRemarks').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
						</div>
					</form>
					<div class="row" *ngIf="showCCRVInsufficiencyFormFields">
						<div class="col-12">
							<button style="margin-right:20px ;" mat-raised-button
										class="add-button-css float_right" type="button"
										(click)="updateInsufficiencies('ccrv')">Update Insufficiencies
							</button>
						</div>
					</div>
					<div class="dark-overlay" *ngIf="ccrvLoad">
						<mat-spinner class="loader" strokeWidth="3" [diameter]="50"> </mat-spinner>
					</div>
					<hr>
					<h6 class="sub_text">Credit Check</h6>
					<form [formGroup]="creditCheckForm">
						<div class="row">
							<div class="col-8 personal-info-columns">
								<p class="label_class"><b> PAN Number - </b></p><em
									style="text-transform:uppercase;">{{panNumber}}</em>
							</div>
							<div *ngIf="!ccState" class="col-4">
								<button mat-button color="primary" class="add-button-css next-button"
									(click)="initiateCCProcess()">Initiate Process</button>
							</div>
							<div *ngIf="ccState" class="col-4 personal-info-columns">
								<p class="next-button" formControlName="state" ngDefaultControl>State of Credit Check
									for
									the candidate : <b><em>{{ccState}}</em></b></p>
							</div>
						</div>
					</form>
					<h6 class="sub_text" *ngIf="showCCInsufficiencyFormFields">Please fill in the insufficiency list.</h6>
					<form [formGroup]="ccInsufficiencyForm">
						<div class="row" *ngIf="showCCInsufficiencyFormFields">
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Status*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<mat-select (selectionChange)="statusSelected($event,'cc')" formControlName="insufficiencyStatus" required>
										<mat-option value="Resolved">Resolved</mat-option>
										<mat-option value="Closed">Closed</mat-option>
									</mat-select>
									<mat-error
										*ngIf="ccInsufficiencyForm.get('insufficiencyStatus').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Reason*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<mat-select formControlName="insufficiencyReason" required>
										<mat-option value="LeftJob">Left Job</mat-option>
										<mat-option value="DataNotAvailable">Data Not Available</mat-option>
									</mat-select>
									<mat-error
										*ngIf="ccInsufficiencyForm.get('insufficiencyReason').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Remarks*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<input matInput formControlName="insufficiencyRemarks" maxlength="100" required>
									<mat-error
										*ngIf="ccInsufficiencyForm.get('insufficiencyRemarks').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
						</div>
					</form>
					<div class="row" *ngIf="showCCInsufficiencyFormFields">
						<div class="col-12">
							<button style="margin-right:20px ;" mat-raised-button
										class="add-button-css float_right" type="button"
										(click)="updateInsufficiencies('cc')">Update Insufficiencies
							</button>
						</div>
					</div>
					<div class="dark-overlay" *ngIf="ccLoad">
						<mat-spinner class="loader" strokeWidth="3" [diameter]="50"> </mat-spinner>
					</div>
					<hr>
					<h6 class="sub_text">Address Verification</h6>
					<form [formGroup]="addressVerificationForm" #addForm="ngForm">
						<div class="row"
							*ngIf="(LAVState === '' || LAPVState === '' || LADVState === '') || (PAVState === '' || PADVState === '' || PAPVState === '')">
							<div class="col-4 personal-info-columns">
								<p class="label_class">Select Address Verification Type</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<mat-select formControlName="addressType" required
										(selectionChange)="addressVerificationSelected($event)">
										<mat-option value="lav">Local Address Verification</mat-option>
										<mat-option value="pav">Permanent Address Verification</mat-option>
									</mat-select>
									<mat-error
										*ngIf="addressVerificationForm.get('addressType').hasError('required')">This is
										a required field.</mat-error>
								</mat-form-field>
							</div>
							<div class="col-4 personal-info-columns" *ngIf="showverificationMode">
								<p class="label_class">Select Mode of Verification</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<mat-select formControlName="addressMode"
										(selectionChange)="verificationModeSelected($event,'permanent')">
										<mat-option value="pav">Physical</mat-option>
										<mat-option value="papv">Postal</mat-option>
										<mat-option value="padv">Digital</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-4 personal-info-columns" *ngIf="showCAVerificationMode">
								<p class="label_class">Select Mode of Verification</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<mat-select formControlName="addressMode"
										(selectionChange)="verificationModeSelected($event,'current')">
										<mat-option value="lav">Physical</mat-option>
										<mat-option value="lapv">Postal</mat-option>
										<mat-option value="ladv">Digital</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
						</div>
						<div class="row"
							*ngIf="(LAVState === '' || LAPVState === '' || LADVState === '') || (PAVState === '' || PADVState === '' || PAPVState === '')">
							<div class="col-6 personal-info-columns" *ngIf="showAddress">
								<p class="label_class">Address</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<textarea matInput formControlName="address" required maxlength="300"></textarea>
									<mat-error *ngIf="addressVerificationForm.get('address').hasError('required')">
										This is a required field. </mat-error>
								</mat-form-field>
							</div>
						</div>
						<div class="row" *ngIf="(LAVState === '' || LAPVState === '' || LADVState === '') || (PAVState === '' || PADVState === '' || PAPVState === '')">
							<div class="col-6 personal-info-columns"></div>
							<div class="col-6 personal-info-columns">
								<button mat-button color="primary" style="margin-left: 3%;" class="add-button-css next-button" (click)="resetAddress()">Reset</button>
								<button mat-button color="primary" [disabled]="!addressVerificationForm.valid" class="add-button-css next-button"
									(click)="initiateAddressVerification()">Initiate Verification</button>
							</div>
						</div>
						<div class="row">
							<div *ngIf="LAVState !== ''" class="col-6 personal-info-columns">
								<p class="label_class"><b> Local Address - </b></p><em>{{currentAddress}}</em>
								<p class="label_class" ngDefaultControl>Address Verification (LAV)
									State of the individual: <b><em>{{LAVState}}</em></b></p>
							</div>
							<div *ngIf="LAPVState !== ''" class="col-6 personal-info-columns">
								<p class="label_class"><b> Local Address - </b></p><em>{{currentAddress}}</em>
								<p class="label_class" ngDefaultControl>Address Verification (LAPV)
									State of the individual: <b><em>{{LAPVState}}</em></b></p>
							</div>
							<div *ngIf="LADVState !== ''" class="col-6 personal-info-columns">
								<p class="label_class"><b> Local Address - </b></p><em>{{currentAddress}}</em>
								<p class="label_class" ngDefaultControl>Address Verification (LADV)
									State of the individual: <b><em>{{LADVState}}</em></b></p>
							</div>
							<div *ngIf="PAVState !== ''" class="col-6 personal-info-columns">
								<p class="label_class"><b> Permanent Address - </b></p><em>{{permanentAddress}}</em>
								<p class="label_class" ngDefaultControl>Address Verification (PAV)
									State of the individual: <b><em>{{PAVState}}</em></b></p>
							</div>
							<div *ngIf="PAPVState !== ''" class="col-6 personal-info-columns">
								<p class="label_class"><b> Permanent Address - </b></p><em>{{permanentAddress}}</em>
								<p class="label_class" ngDefaultControl>Address Verification (PAPV)
									State of the individual: <b><em>{{PAPVState}}</em></b></p>
							</div>
							<div *ngIf="PADVState !== ''" class="col-6 personal-info-columns">
								<p class="label_class"><b> Permanent Address - </b></p><em>{{permanentAddress}}</em>
								<p class="label_class" ngDefaultControl>Address Verification (PADV)
									State of the individual: <b><em>{{PADVState}}</em></b></p>
							</div>
						</div>
						<br><br>
					</form>
					<h6 class="sub_text" *ngIf="showcAddressInsufficiencyFields">Please fill in the insufficiency list for current address</h6>
					<form [formGroup]="addressInsufficiencyForm">
						<div class="row" *ngIf="showcAddressInsufficiencyFields">
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Status*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<mat-select (selectionChange)="statusSelected($event,'caddress')" formControlName="insufficiencyStatus" required>
										<mat-option value="Resolved">Resolved</mat-option>
										<mat-option value="Closed">Closed</mat-option>
									</mat-select>
									<mat-error
										*ngIf="addressInsufficiencyForm.get('insufficiencyStatus').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Reason*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<mat-select formControlName="insufficiencyReason" required>
										<mat-option value="LeftJob">Left Job</mat-option>
										<mat-option value="DataNotAvailable">Data Not Available</mat-option>
									</mat-select>
									<mat-error
										*ngIf="addressInsufficiencyForm.get('insufficiencyReason').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Remarks*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<input matInput formControlName="insufficiencyRemarks" maxlength="100" required>
									<mat-error
										*ngIf="addressInsufficiencyForm.get('insufficiencyRemarks').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
						</div>
					</form>
					<div class="row" *ngIf="showcAddressInsufficiencyFields">
						<div class="col-12">
							<button style="margin-right:20px ;" mat-raised-button
										class="add-button-css float_right" [disabled]="!addressInsufficiencyForm.valid" type="button"
										(click)="updateInsufficiencies('lav')">Update Insufficiencies
							</button>
						</div>
					</div>
					<h6 class="sub_text" *ngIf="showPAddressInsufficiencyFields">Please fill in the insufficiency list for permanent address</h6>
					<form [formGroup]="PAddressInsufficiencyForm">
						<div class="row" *ngIf="showPAddressInsufficiencyFields">
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Status*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<mat-select (selectionChange)="statusSelected($event,'pAddress')" formControlName="insufficiencyStatus" required>
										<mat-option value="Resolved">Resolved</mat-option>
										<mat-option value="Closed">Closed</mat-option>
									</mat-select>
									<mat-error
										*ngIf="PAddressInsufficiencyForm.get('insufficiencyStatus').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Reason*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<mat-select formControlName="insufficiencyReason" required>
										<mat-option value="LeftJob">Left Job</mat-option>
										<mat-option value="DataNotAvailable">Data Not Available</mat-option>
									</mat-select>
									<mat-error
										*ngIf="PAddressInsufficiencyForm.get('insufficiencyReason').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-4 personal-info-columns">
								<p class="label_class">Remarks*</p>
								<mat-form-field appearance="outline" class="form_field_style">
									<input matInput formControlName="insufficiencyRemarks" maxlength="100" required>
									<mat-error
										*ngIf="PAddressInsufficiencyForm.get('insufficiencyRemarks').hasError('required')">
										This is a required field.
									</mat-error>
								</mat-form-field>
							</div>
						</div>
					</form>
					<div class="row" *ngIf="showPAddressInsufficiencyFields">
						<div class="col-12">
							<button style="margin-right:20px ;" mat-raised-button
										class="add-button-css float_right" [disabled]="!PAddressInsufficiencyForm.valid" type="button"
										(click)="updateInsufficiencies(verificationMode)">Update Insufficiencies
							</button>
						</div>
					</div>
					<div class="dark-overlay" *ngIf="addressLoad">
						<mat-spinner class="loader" strokeWidth="3" [diameter]="50"> </mat-spinner>
					</div>
				</mat-tab>
				<mat-tab label="Overall Status" *ngIf="HRView">
					<div class="row" *ngIf="individualID !== null">
						<div class="col-12" style="padding: 20px 0px 0px; text-align: center;">
							<a class="pdf-report" [href]="pdfUrl" target="_blank">View Consolidated Report</a>
						</div>
					</div>
					<table class="table overall-table">
						<thead>
							<th>Module</th>
							<th>Status</th>
						</thead>
						<tr>
							<th>CCRV Status</th>
							<td
								[ngClass]="{orange_class: ccrvStatus === 'In Progress',yellow_class: ccrvStatus === 'Not Requested',green_class: ccrvStatus === 'Success',red_class: ccrvStatus === 'Failed'}">
								<b>{{ccrvStatus}}</b></td>
						</tr>
						<tr>
							<th>CC Status</th>
							<td
								[ngClass]="{orange_class: ccStatus === 'In Progress', yellow_class: ccStatus === 'Not Requested',green_class: ccStatus === 'Success',red_class: ccStatus === 'Failed'}">
								<b>{{ccStatus}}</b></td>
						</tr>
						<tr>
							<th>Education Status</th>
							<td
								[ngClass]="{orange_class: eduStatus === 'In Progress', yellow_class: eduStatus === 'Not Requested',green_class: eduStatus === 'Success',red_class: eduStatus === 'Failed'}">
								<b>{{eduStatus}}</b></td>
						</tr>
						<tr>
							<th>Employment Status</th>
							<td
								[ngClass]="{orange_class: empStatus === 'In Progress', yellow_class: empStatus === 'Not Requested',green_class: empStatus === 'Success',red_class: empStatus === 'Failed'}">
								<b>{{empStatus}}</b></td>
						</tr>
						<tr>
							<th>LAV Status</th>
							<td
								[ngClass]="{orange_class: overallLAVStatus === 'In Progress', yellow_class: overallLAVStatus === 'Not Requested',green_class: overallLAVStatus === 'Success',red_class: overallLAVStatus === 'Failed'}">
								<b>{{overallLAVStatus}}</b></td>
						</tr>
						<tr>
							<th>LAPV Status</th>
							<td
								[ngClass]="{orange_class: overallLAPVStatus === 'In Progress', yellow_class: overallLAPVStatus === 'Not Requested',green_class: overallLAPVStatus === 'Success',red_class: overallLAPVStatus === 'Failed'}">
								<b>{{overallLAPVStatus}}</b></td>
						</tr>
						<tr>
							<th>LADV Status</th>
							<td
								[ngClass]="{orange_class: overallLADVStatus === 'In Progress', yellow_class: overallLADVStatus === 'Not Requested',green_class: overallLADVStatus === 'Success',red_class: overallLADVStatus === 'Failed'}">
								<b>{{overallLADVStatus}}</b></td>
						</tr>
						<tr>
							<th>PAV Status</th>
							<td
								[ngClass]="{orange_class: PAVStatus === 'In Progress', yellow_class: PAVStatus === 'Not Requested',green_class: PAVStatus === 'Success',red_class: PAVStatus === 'Failed'}">
								<b>{{PAVStatus}}</b></td>
						</tr>
						<tr>
							<th>PAPV Status</th>
							<td
								[ngClass]="{orange_class: overallPAPVStatus === 'In Progress', yellow_class: overallPAPVStatus === 'Not Requested',green_class: overallPAPVStatus === 'Success',red_class: overallPAPVStatus === 'Failed'}">
								<b>{{overallPAPVStatus}}</b></td>
						</tr>
						<tr>
							<th>PADV Status</th>
							<td [ngClass]="{orange_class: overallPADVStatus === 'In Progress', yellow_class: overallPADVStatus === 'Not Requested',green_class: overallPADVStatus === 'Success',red_class: overallPADVStatus === 'Failed'}"><b>{{overallPADVStatus}}</b></td>
						</tr>
						<tr>
							<th>Overall Status</th>
							<td
								[ngClass]="{orange_class: totalStatus === 'In Progress', yellow_class: totalStatus === 'Not Requested', green_class: totalStatus === 'Success',red_class: totalStatus === 'Failed'}">
								<b>{{totalStatus}}</b>
							</td>
						</tr>
					</table>
				</mat-tab>
			</mat-tab-group>
		</div>
	</div>
</div>