import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../shared/authentication/authentication.service';
import { PreBoardingService } from '../shared/pre-boarding.service';

@Component({
  selector: 'app-completion',
  templateUrl: './completion.component.html',
  styleUrls: ['./completion.component.scss']
})
export class CompletionComponent implements OnInit {

  constructor(private _router: Router, private preBoardingService: PreBoardingService, public authentication: AuthenticationService,) { }

  ngOnInit(): void {
  }

  close() {
    this.preBoardingService.finalCompletion.next(false);
    this.authentication.logout();
    window.close();
  }

}
