<div class="row" style="margin-left: 300px;">
    <div class="col-12" style="padding: 0;margin-top: 67px;">
        <h3 style="padding: 20px;">Document</h3>
    </div>
    <div class="col-12" *ngIf="documentUploadStatusNull">
        The documents from candidate are yet to be submitted!
    </div>
    <div class="col-12" *ngIf="!documentUploadStatusNull">
        <!-- Initial file display -->
        <div *ngIf="initialSubmit">
            <a (click)="downloadZip(candidateZipFileDetails.zipFileUrl.split('/')[1],true)" style="cursor: pointer;">
                <b>
                    {{candidateZipFileDetails.zipFileUrl.split('/')[1]}}</b><img
                    src="../../../../assets/file_download.png" alt="file_download"></a>
                    <span *ngIf="candidateZipFileDetails.zipFileUrlSize > 0" class="file_size_class"> {{zipFileUrlSize}}</span>
            <button id="reject_id_btn" class="approve_btn_class" (click)="rejectTabOpened()">Review Documents</button>
        </div>
        <br>
        <!-- re-submitted documents -->
        <div *ngIf="displayHRApprovedandCandidateNewlySubmittedDocs" id="3rd-id">
            <a (click)="downloadZip(candidateZipFileDetails.zipFileUrl.split('/')[1],false)" style="cursor: pointer;">
                <b>{{candidateZipFileDetails.zipFileUrl.split('/')[1]}}</b> <img
                    src="../../../../assets/file_download.png" alt="file_download"></a>&nbsp;&nbsp;
                    <span *ngIf="candidateZipFileDetails.zipFileUrlSize > 0" class="file_size_class"> {{zipFileUrlSize}}</span>
            <span>
                <button class="approve_btn_class" id="reject_id_btn" (click)="rejectTabOpened()">Review
                    Documents</button></span>
        </div>
        <br>
        <!-- Partially approved documents -->
        <div *ngIf="partiallyApproved" id="2nd-id">
            <a id="2nd-id-A"
                (click)="downloadZip(candidateZipFileDetails.approvedZipFileUrl ?candidateZipFileDetails.approvedZipFileUrl.split('/')[2]:null,true)"
                style="cursor: pointer;"><b>{{candidateZipFileDetails.approvedZipFileUrl ?
                    candidateZipFileDetails.approvedZipFileUrl.split('/')[2]:null}}</b>
                <img src="../../../../assets/file_download.png" alt="file_download"></a> &nbsp;&nbsp;
                <span *ngIf="candidateZipFileDetails.approvedZipFileUrlSize > 0"
                    class="file_size_class"> {{approvedZipFileUrlSize}}</span>
            <span><button class="approve_btn_class" id="approve_id_btn"> partially approved</button></span>
        </div>

        <div *ngIf="conditionalApproved" id="2nd-id">
            <a id="2nd-id-A"
                (click)="downloadZip(candidateZipFileDetails.approvedZipFileUrl ? candidateZipFileDetails.approvedZipFileUrl.split('/')[2]:null,true)"
                style="cursor: pointer;"><b>{{candidateZipFileDetails.approvedZipFileUrl ?
                    candidateZipFileDetails.approvedZipFileUrl.split('/')[2]:null}}</b>
                <img src="../../../../assets/file_download.png" alt="file_download"></a> &nbsp;&nbsp;
                <span *ngIf="candidateZipFileDetails.approvedZipFileUrlSize > 0"
                    class="file_size_class"> {{approvedZipFileUrlSize}}</span>
            <span><button class="approve_btn_class" id="approve_id_btn">conditional approved</button></span>
        </div>
        <br>
        <!-- Finally Approved -->
        <div *ngIf="finalApproved && !displayOnlyHRRejectdApprovedDocs &&!conditionalApproved&&!partiallyApproved">
            <a (click)="downloadZip(candidateZipFileDetails.approvedZipFileUrl.split('/')[2],true)"
                style="cursor: pointer;">
                <b>{{candidateZipFileDetails.approvedZipFileUrl.split('/')[2]}}</b><img
                    src="../../../../assets/file_download.png" alt="file_download"></a> &nbsp;&nbsp;
                    <span *ngIf="candidateZipFileDetails.approvedZipFileUrlSize > 0"
                        class="file_size_class"> {{approvedZipFileUrlSize}}</span>
            <button class="approve_btn_class approved_btn" id="approve_id_btn">Approved </button>
            
        </div>
        <br>

    </div>
    <br>
    <br>
    <!-- <div class="col-12"></div> -->
    <div class="col-12" *ngIf="candidateDocumentsStatus.length > 0">
        <mat-accordion class="example-headers-align">
            <mat-expansion-panel [expanded]="step === 0" *ngIf="approvedArray.length > 0">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <strong>Approved Documents</strong>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <table class="table">
                    <tr>
                        <th>Sr No.</th>
                        <th>Document Name</th>
                    </tr>
                    <tr class="approved-array-tr" *ngFor="let word of approvedArray;let i=index;">
                        <td>{{i + 1}}</td>
                        <td>{{word.name}}</td>
                    </tr>
                </table>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="step === 0" *ngIf="rejectedArray.length > 0">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <strong>Rejected/Pending Documents</strong>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <table class="table">
                    <tr style="background-color:#f0f0f0;">
                        <th>Sr No.</th>
                        <th>Document Name</th>
                    </tr>
                    <tr class="rejected-array-tr" *ngFor="let word of rejectedArray;let i=index;">
                        <td>{{i + 1}}</td>
                        <td>{{word.name}}</td>
                    </tr>
                </table>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <br><br>
    <!-- <div class="col-6" *ngIf="finalApproved && !displayOnlyHRRejectdApprovedDocs &&!partiallyApproved">
        <a class="dropdown-item" (click)="sendRequest()" target="blank">
            Pre-Boarding
        </a>
    </div> -->
    <div class="col-12 mt-4" *ngIf="employeeType === 'FTE'">
        <p><mat-checkbox [(ngModel)]="provideConsent" disabled ngDefaultControl></mat-checkbox><strong> Consent for
            BGV Initiation</strong></p>
    </div>
        <br>
        <div class="heading_name">
            <h5>Upload documents on behalf of candidate</h5>
        </div>
        <div *ngIf="largeFileSize">
            <div class="error_msg_class" id="error_msg_id"> <img src="../../../../assets/round_cancel_black_24dp.png"
                    alt="Error"> The selected file could not be uploaded. Please select the file which is less than 200mb.
            </div>
        </div>
        <div class="col-12">
            <div class="bordered_box">
                <label *ngIf="!filestoUpload" class="file_input_text" id="file_input_text" for="file">Choose a zip file to
                    upload</label>
                <label *ngIf="filestoUpload" class="file_input_text" id="file_input_text" for="file">Upload the zip
                    file</label>
                <div id="hideFileName">
                    <p id="hide_fName_class" style="text-align: center;">File Name: <a style="cursor: pointer;">
                            <b>{{displayFileName}}</b></a>
                        <img *ngIf="displayFileName" id="delete-icon" style="margin-left: 12px;" (click)="removeFile($event)" src="../../../../assets/delete.png" class="cancel-icon" alt="Cancel" />
                    </p>
                </div>
                <!-- <p *ngIf="!filestoUpload" class="img_container" id="img_container"><img src="../../../../assets/baseline_description_black_24dp.png"
                    alt="File Upload"></p> -->
                <p *ngIf="!filestoUpload"class="img_container" id="img_container"><img
                        src="../../../../assets/baseline_description_black_24dp.png" alt="File Upload"></p>
                <p *ngIf="!filestoUpload" class="button_holder" id="button_holder">
                    <input type="file" id="file" accept=".zip" (change)="handleFileInput($event.target.files)"
                        id="file-upload-input" class="file_input_btn" />
                </p>
                <span class="cdk-visually-hidden" *ngIf="uploadComplete">
                    upload complete
                </span>
                <div *ngIf="filestoUpload" style="text-align:center;">
                    <button mat-raised-button color="primary" id="upload-id-btn" (click)="uploadFileToActivity()"
                        [disabled]="uploadComplete">Upload</button>
                </div>
            </div>
        </div>
        <div class="zip_color">
            <p>Only "Zip" Files are allowed and maximum file size limit is 200MB</p>
        </div>
        <div class="col-12 success-message" *ngIf="uploadComplete">
            <p>The file has been uploaded successfully!</p>
        </div>
        <div class="col-12 finish-block">
            <button type="submit" mat-raised-button class="submit-button-css" [disabled]= '!uploadComplete' (click)="submitDocuments()">Submit</button>
        </div>
    </div>
    <br>
    <div class="dark-overlay" *ngIf="load">
        <mat-spinner class="loader" strokeWidth="3" [diameter]="50"></mat-spinner>
    </div>
