import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, mergeMap, finalize } from 'rxjs/operators';
import { ErrorDialogService } from './error-dialog/errordialog.service';
import { KeycloakService } from 'keycloak-angular';


@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(public errorDialogService: ErrorDialogService,
        public kc : KeycloakService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.kc.addTokenToHeader(request.headers).pipe(mergeMap((headersWithBearer => {
            const kcReq = request.clone({ headers: headersWithBearer });
            const begin = performance.now();
            return next.handle(kcReq).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data = {};
                let testData = {};
                testData = {
                    reason: error.error.message ? error.error.message : ''
                }
                if (testData['reason'] !== '') {
                    console.log('data - ',data , ' ', 'testData',testData);
                    this.errorDialogService.openDialog(testData);
                } else {
                    data = {
                        // reason: error && error.error.error.message ? error.error.error.message : '',
                         reason: error && error.error.errors[0].message ? error.error.errors[0].message : '',
                    };
                    console.log('data - ',data , ' ', 'testData',testData);
                    this.errorDialogService.openDialog(data);
                }
                return throwError(error);
            }),
            finalize(()=> {
                this.logRequestTime(begin, request.url,request.method)
            }));
        })));
    }
    private logRequestTime(startTime: number, url: string, method: string) {
        const requestDuration = `${performance.now() - startTime}`;
        // console.log(`HTTP ${method} ${url} - ${requestDuration} milliseconds`);
      }
}