<div>
    <div style="font-size: 14px; text-align: center;">
      <p>  Hope you have reviewed all the information and uploaded the documents. Forms once submitted cannot
			be modified.</p><br/> <b> Do you want to submit?</b>
			<br>
    </div>
    <div style="background-color: #F7FCFE; padding: 37px;">
		<button mat-button class="cancel-btn" (click)="closeDialog()">Cancel</button>
	<button mat-button class="submit-btn" (click)="finish()">Submit</button>
    </div>
</div>
