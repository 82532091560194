
<!-- <button mat-icon-button class="close-button"(click)="onNoClick()">
    <img src="../../../assets/icon-close.png" style="height: 19px;" alt="edit">
</button> -->
	<p mat-dialog-title style="text-align: right">. <span><img src="../../../assets/icon-close.png" class="close-button" (click)="onNoClick()"  style="height: 19px;" ></span></p>
<div class="col-9" style="z-index: 999;position: absolute;">
    <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
        <img src="../../../assets/baseline_search_black_24dp.png" class="search_icon" matPrefix>
        <input matInput
            (keyup)="applyFilter($event)" placeholder="Type a name to search" #input>
    </mat-form-field>
</div>

<div class="col-12" style="margin-top: 84px;">
    <mat-table id="mat-candidate-table" [dataSource]="candidatesJoiningDataSource"class="mat-elevation-z0" style="margin-bottom: 5%;">
        <!-- Index Column -->
        <ng-container matColumnDef="srNo">
            <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
            <mat-cell *matCellDef="let element;let i=index"> {{i+1}} </mat-cell>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> <a class="hyper-link" (click)="getCandidate(element)"> {{element.name}}</a> </mat-cell>
        </ng-container>
        <!-- DOJ Column -->
        <ng-container matColumnDef="validUpto">
            <mat-header-cell *matHeaderCellDef> Expected Date of Joining </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.validUpto | date: 'dd/MM/yyyy'}} </mat-cell>
        </ng-container>
        <!-- Email Column -->
        <ng-container matColumnDef="emailId">
            <mat-header-cell *matHeaderCellDef> Email ID </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.emailId}} </mat-cell>
        </ng-container>
        <!-- Contact Type Column -->
        <ng-container matColumnDef="options">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
                <!-- <button class="icons-class"> <img src="../../../assets/edit.png" alt="edit" (click)="editdata(element)"> </button>
                <button class="icons-class"> <img src="../../../assets/delete.png" alt="delete" (click)="deletedata(element)">
                </button> -->
              
                <ng-container *ngIf="element.joiningStatus=='INPROGRESS'|| element.joiningStatus=='PENDING'">
                    <button mat-icon-button class="icons-css"  [matMenuTriggerFor]="menu">
                        <img src="../../../assets/baseline_more_vert_black_24dp.png" alt="More">
                    </button>
                    <mat-menu #menu="matMenu"  >
                        <button id="id_edit_btn"  (click)="editdata(element)" class="icons-css" mat-menu-item>
                            <span>Edit</span>
                        </button>
                        <ng-container *appHasAuthority="'DELETE_CANDIDATE'">
                            <button (click)="deletedata(element)" *ngIf="element.joiningStatus=='PENDING'" class="icons-css" mat-menu-item>
                                <span>Delete</span>
                            </button>
                        </ng-container>
                    </mat-menu>
                </ng-container>
              
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="candidatesDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: candidatesDisplayedColumns;"></mat-row>
    </mat-table>
    </div>
    <div class="col-6" style="display: inline;">
        <div mat-dialog-actions class="buttons-position">
            <button mat-raised-button class="cancel-button-css" style="width: 20%;" (click)="closeDialog()">CANCEL</button>
		</div>
    </div>
    