import { environment } from 'src/environments/environment';


export const SERVER_API_URL = environment.apiUrl;
export const ENV_NAME = environment.environmentName
//this.environmentUrl = environment.apiUrl;
// export const SERVER_API_URL = "http://localhost:8002";
export const DEFAULT_DATE_FORMAT = 'd MMM, yyyy';
export const DATE_FORMATE = 'dd MMM yyyy';
export const MY_MONTH_FORMATS = {
    parse: {
      dateInput: 'MM/YYYY'
    },
    display: {
      dateInput: 'MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    }
  };
  export const MY_COMPLETE_DATE_FORMATS = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY'
    }
  };
