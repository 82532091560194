import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PreBoardingService } from 'src/app/shared/pre-boarding.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { AuthenticationService } from 'src/app/shared/authentication/authentication.service';
import $ from "jquery";
import queryString from 'query-string';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormControl } from '@angular/forms';
export interface DocumentsList {
  name: string;
}
@Component({
  selector: 'app-hr-documents',
  templateUrl: './hr-documents.component.html',
  styleUrls: ['./hr-documents.component.scss'],
})
export class HRDocumentsComponent implements OnInit {
  docsDisplayedColumns=['zipFileUrl', 'inProgress','tooltip', 'options'];
  documentsDataSource=new MatTableDataSource([]);
  candidateID: string;
  downloadFromHR: boolean;
  candidateZipFileDetails: any;
  documentUploadStatusNull: boolean=true;
  isTotallyApproved: boolean;
  finalSubmit: boolean;
  showApprovedDocsOnly: boolean;
  displayOnlyHRRejectdApprovedDocs: boolean;
  displayHRApprovedandCandidateNewlySubmittedDocs: boolean=false;
  finalApproved: boolean;
  zipFileUrlNull: boolean;
  candidateDocumentsStatus=[];
  approvedArray=[];
  rejectedArray=[];
  documentProcessingPending: boolean=true;
  allDocsRejected: boolean;
  ai = 0;
  ri = 0;
  load = false;
  partiallyApproved: boolean;
  initialSubmit: boolean;
  conditionalApproved: boolean=false;
  router: Router;
  apertureEnvRedirectUri: string;
  zipFileUrlSize: any;
  approvedZipFileUrlSize: any;
  provideConsent: boolean = false;
  employeeType: any;
  filestoUpload: boolean;
  displayFileName: any;
  uploadComplete: boolean =false;
  private fileToUpload: File=null;
  fileSelected: boolean=false;
  fileData: any=null;
  isFileUploaded: boolean=false;
  largeFileSize: boolean;
  postApproveFile: any;
  filesSize=209715200;
  identityDocuments: any;
  educationalDocuments: any;
  employmentDocuments: any;
  approvedList=[];
  documentsrejected: boolean=false;
  checkedVariable: boolean =false;
  otherDocuments: any;
  progressDisplay=false;
  percentDone=100;
  CandidateID: any;
  isHR=true;
  dialogRef: any;
  postFile: any;
  constructor(public dialog: MatDialog, public preBoardingService: PreBoardingService, private authenticationService: AuthenticationService, private _router: Router) {
    this.router=_router;
    this.conditionalApproved=false;
    this.partiallyApproved=false;
    this.uploadComplete=false;
   }

  ngOnInit (): void {
    this.apertureEnvRedirectUri=environment.apertureUri;
    console.log(environment.apertureUri,environment);
    this.downloadFromHR=true;
    this.candidateID=sessionStorage.getItem('candidateID');
    this.isTotallyApproved=false;
    this.documentsDataSource=new MatTableDataSource([]);
    this.preBoardingService.getpersonalInfo(this.candidateID).subscribe(res => {
      this.candidateZipFileDetails=res.candidateInformation;
      this.employeeType = res.employeeType;
      this.zipFileUrlSize = this.readableBytes(this.candidateZipFileDetails.zipFileUrlSize);
      this.approvedZipFileUrlSize = this.readableBytes(this.candidateZipFileDetails.approvedZipFileUrlSize);
      if (this.candidateZipFileDetails.documentUploadStatus===null) {
        this.documentUploadStatusNull=true;
      } else {
        this.documentUploadStatusNull=false;
      }
      this.preBoardingService.retrieveDocumentStatus(this.candidateID).subscribe(res => {
        this.candidateDocumentsStatus=res;
        this.candidateDocumentsStatus.forEach(element => {
          if (element.status==='APPROVED') {
            this.finalApproved=true;
            this.approvedArray.push(element);
            this.displayOnlyHRRejectdApprovedDocs=false;
          } else if (element.status==='REJECTED'&&this.candidateZipFileDetails.approvedZipFileUrl!==null) {
            this.finalApproved=false;
            this.rejectedArray.push(element);
            this.displayOnlyHRRejectdApprovedDocs=true;
          }
        });
        this.candidateDocumentsStatus.forEach((element, i) => { 
          if (element.status==='REJECTED') {
            this.ri=this.ri+1
          }
        });
        if (this.ri >=1) {
          this.partiallyApproved=true;
          // this.initialSubmit = false;
        }
        if (this.candidateZipFileDetails && this.candidateZipFileDetails.documentUploadStatus&& this.candidateZipFileDetails.documentUploadStatus=="APPROVED") {
          this.conditionalApproved = false;
          this.partiallyApproved = false;
        }
        if (this.candidateZipFileDetails && this.candidateZipFileDetails.documentUploadStatus&& this.candidateZipFileDetails.documentUploadStatus=="APPROVEDINCONDTION") {
          this.conditionalApproved = true;
          this.partiallyApproved = false;
        }
      });
      if (this.candidateZipFileDetails.approvedZipFileUrl === null) {
        this.initialSubmit=true;
        this.partiallyApproved = false;
      }
      this.getFiles();
    });
    this.preBoardingService.getConsent(this.candidateID).subscribe(response=> {
      this.provideConsent = response;
    });
  }
  getFiles () {
    this.documentsDataSource=new MatTableDataSource([this.candidateZipFileDetails]);
    if (this.candidateZipFileDetails.documentUploadStatus==='SUBMITTED'&&this.candidateZipFileDetails.approvedZipFileUrl!==null&&this.candidateZipFileDetails.zipFileUrl!==null) {
      this.displayHRApprovedandCandidateNewlySubmittedDocs=true;
    }
    if (this.candidateZipFileDetails.documentUploadStatus==='REJECTED'&&this.candidateZipFileDetails.approvedZipFileUrl!==null&&this.candidateZipFileDetails.zipFileUrl===null) {
      // this.partiallyApproved=true;
    }
  }

  readableBytes(bytes) {
    let i = Math.floor(Math.log(bytes)/Math.log(1024));
    let sizes = ['B','KB','MB','GB','TB'];
    let a;
    a = (bytes/Math.pow(1024,i)).toFixed(2);
    console.log('a * 1 + "" + sizes[i]', a * 1 + '' + sizes[i]);
    return  a * 1 + '' + sizes[i];
  }

  submitDocuments() {
    this.preBoardingService.documentSubmissionMail(this.candidateID,this.isHR, true).subscribe(res=> {
      window.location.reload();
    })
  }

  rejectTabOpened () {
    const obj = {
      rejectedList: this.rejectedArray,
      approvedList: this.approvedArray
    };
    const dialogRef=this.dialog.open(RejectDialogComponent, {
      width: '1000px',
      height: '600px',
      disableClose: true,
      data: obj,
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getStatus () {
    this.preBoardingService.retrieveDocumentStatus(this.candidateID).subscribe(res => {
      this.candidateDocumentsStatus=res;
    });
  }
  sendRequest () {
    let removedSpaces=this.candidateZipFileDetails.name.split(' ').join('');
    let url=`${this.apertureEnvRedirectUri}/resource/add-resource/?candidateId=`+this.candidateID+'&'+'name='+removedSpaces+'&'+'gender='+this.candidateZipFileDetails.gender;
    window.location.href=url;
    var urlString=new URL(url);
    let allParams=urlString.href.split('?')[1];
    console.log('allParams', allParams);
    let queryParams=queryString.parseUrl(allParams);
  }
  downloadZip (fileName, status) {
    this.load = true;
    if (status===true) {
      this.downloadFromHR=true;
    } else if (status===false) {
      this.downloadFromHR=false;
    }
    // this.getStatus();
    this.preBoardingService.downloadZip(this.candidateID, this.downloadFromHR,false).subscribe(res => {
      const blob=new Blob([res], {
        type: 'application/zip'
      });
      this.load = false;
      const data=window.URL.createObjectURL(blob);
      var link=document.createElement('a');
      link.href=data;
      link.download=fileName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }
  handleFileInput (files: FileList) {
    this.fileToUpload=files.item(0);
    this.fileSelected=true;
    this.displayFileName=this.fileToUpload.name;
    this.filestoUpload=true;
    if ((this.fileToUpload.type === 'application/zip' || this.fileToUpload.type === 'application/x-zip-compressed') && this.fileToUpload.size<=this.filesSize) {
      const blob=new Blob([this.fileToUpload], {
        type: 'application/zip'
      });
      this.fileData=window.URL.createObjectURL(blob);
      this.isFileUploaded=true;
      document.getElementById('hideFileName').style.display=`block`;
			document.getElementById('hide_fName_class').style.display=`block`;
			document.getElementById('hide_fName_class').style.textAlign=`center`;
			document.getElementById('img_container').style.display='none';
    } else {
      this.largeFileSize=true;
      alert('Greater file size');
      setTimeout(function () {
        document.getElementById('error_msg_id').style.display='none';
      }, 3000);
      document.getElementById('hideFileName').style.display='none';
      document.getElementById('progress-bar-div').style.display='none';
      document.getElementById('file_input_text').innerText='Choose a zip file to upload';
      document.getElementById('img_container').style.display='block';
      this.uploadComplete=false;
      this.isFileUploaded=false;
    }
  }
  uploadFileToActivity () {
    this.load = true;
    this.postApproveFile=this.preBoardingService.pushFileToStorage(this.fileToUpload, this.candidateID, this.isHR,true).subscribe(
      event => {
        this.progressDisplay=true;
        this.load = false;
      },
      (err) => {
        this.load = false;
      }, () => {
        this.uploadComplete=true;
        this.load = false;
        document.getElementById('upload-id-btn').style.display='none';
      }
    )
  }
  removeFile (e) {
    e.target.value='';
    this.filestoUpload=false;
    this.uploadComplete=false;
    this.load = false;
    document.getElementById('hideFileName').style.display='none';
    document.getElementById('choose_file_text').innerText='Choose a zip file to upload';
    document.getElementById('img_container').style.display='block';
  }
  
}
@Component({
  selector: 'approve-dialog',
  templateUrl: 'approve-dialog.html',
  styleUrls: ['./hr-documents.component.scss'],
})
export class ApproveDialogComponent implements OnInit {
  percentDone=100;
  approveInputElements=[{ name: 'All', remarks: '', id: 0, status: 'APPROVED' }];
  docRemarkElements=[{ docName: '' }];
  displayFileName: any;
  @Output() filesSent=new EventEmitter<any>();
  selectFiles: any;
  selectedFiles: any;
  minimmumFileSize: any;
  filesSize=209715200;
  currentFile: File;
  approveCandidateID: any
  progress=0;
  message='';
  uploadSuccess: boolean;
  isFileUploaded: boolean=false;
  fileSelected: boolean=false;
  fileData: any=null;
  childDocRemarks: any;
  remarksadded: any;
  nameadded: any;
  documentsrejected: boolean=false;
  uploadComplete=false;
  progressDisplay=true;
  private fileToUpload: File=null;
  filestoUpload: boolean;
  postApproveFile: any;
  largeFileSize: boolean;
  identityDocuments: any;
  educationalDocuments: any;
  employmentDocuments: any;
  otherDocuments: any;
  documentCheckList: any;
  step=0;
  allComplete: boolean=false;
  aprovedlist=[];
  showidentiydocuments: boolean = false;
  showotherdocuments: boolean = false;
  showemploymentdocuments: boolean = false;
  showeducationaldocuments: boolean = false;
  employeeType: any;
  isfileuploaded: boolean = false;

  constructor(public dialogRef: MatDialogRef<ApproveDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private preBoardingService: PreBoardingService, private HRDocsComponent: HRDocumentsComponent) {
    data.forEach(element => {
      this.aprovedlist.push(element.name);
    });
    console.log('approvedList', this.aprovedlist);
  }
  isHR: boolean=true;

  ngOnInit () {
    this.HRDocsComponent.ngOnInit();
    this.employeeType=this.preBoardingService.employeeType;
    this.approveCandidateID=sessionStorage.getItem('candidateID');
    this.preBoardingService.retrieveDocumentCheckList(this.employeeType).subscribe(res => {
      this.documentCheckList=res.documentCheckList;
      // Educational
      this.documentCheckList.EDUCATIONAL_DOCUMENTS.forEach((ele, i) => {
        this.documentCheckList.EDUCATIONAL_DOCUMENTS[i]['remarks']='';
        this.documentCheckList.EDUCATIONAL_DOCUMENTS[i]['status']='REJECTED';
        this.documentCheckList.EDUCATIONAL_DOCUMENTS[i]['approved']='0';
        this.documentCheckList.EDUCATIONAL_DOCUMENTS[i]['name']=this.documentCheckList.EDUCATIONAL_DOCUMENTS[i]['documentName'];
        delete this.documentCheckList.EDUCATIONAL_DOCUMENTS[i]['id'];
        delete this.documentCheckList.EDUCATIONAL_DOCUMENTS[i]['documentName'];
      });
      this.educationalDocuments=this.documentCheckList.EDUCATIONAL_DOCUMENTS;
      this.educationalDocuments.forEach(element => {
        if (this.aprovedlist.indexOf(element.name) > -1) {
          this.showeducationaldocuments = true;
        } 
      });
      
      // Employment
      this.documentCheckList.EMPLOYMENT_DOCUMENTS.forEach((ele, i) => {
        this.documentCheckList.EMPLOYMENT_DOCUMENTS[i]['remarks']='';
        this.documentCheckList.EMPLOYMENT_DOCUMENTS[i]['status']='REJECTED';
        this.documentCheckList.EMPLOYMENT_DOCUMENTS[i]['approved']='0';
        this.documentCheckList.EMPLOYMENT_DOCUMENTS[i]['name']=this.documentCheckList.EMPLOYMENT_DOCUMENTS[i]['documentName'];
        delete this.documentCheckList.EMPLOYMENT_DOCUMENTS[i]['id'];
        delete this.documentCheckList.EMPLOYMENT_DOCUMENTS[i]['documentName'];
      });
      this.employmentDocuments=this.documentCheckList.EMPLOYMENT_DOCUMENTS;
      this.employmentDocuments.forEach(element => {
        if (this.aprovedlist.indexOf(element.name) > -1) {
          this.showemploymentdocuments = true;
        } 
      });
      
      /* Others */
      this.documentCheckList.OTHERS.forEach((ele, i) => {
        this.documentCheckList.OTHERS[i]['remarks']='';
        this.documentCheckList.OTHERS[i]['status']='REJECTED';
        this.documentCheckList.OTHERS[i]['approved']='0';
        this.documentCheckList.OTHERS[i]['name']=this.documentCheckList.OTHERS[i]['documentName'];
        delete this.documentCheckList.OTHERS[i]['id'];
        delete this.documentCheckList.OTHERS[i]['documentName'];
      });
      this.otherDocuments=this.documentCheckList.OTHERS;
      this.otherDocuments.forEach(element => {
        if (this.aprovedlist.indexOf(element.name) > -1) {
          this.showotherdocuments = true;
        } 
      });
      
      // Identity Docs
      this.documentCheckList.IDENTITY_DOCUMENTS.forEach((ele, i) => {
        this.documentCheckList.IDENTITY_DOCUMENTS[i]['remarks']='';
        this.documentCheckList.IDENTITY_DOCUMENTS[i]['status']='REJECTED';
        this.documentCheckList.IDENTITY_DOCUMENTS[i]['approved']='0';
        this.documentCheckList.IDENTITY_DOCUMENTS[i]['name']=this.documentCheckList.IDENTITY_DOCUMENTS[i]['documentName'];
        delete this.documentCheckList.IDENTITY_DOCUMENTS[i]['documentName'];
        delete this.documentCheckList.IDENTITY_DOCUMENTS[i]['id'];
      });
      this.identityDocuments=this.documentCheckList.IDENTITY_DOCUMENTS;
      this.identityDocuments.forEach(element => {
        if (this.aprovedlist.indexOf(element.name) > -1) {
          this.showidentiydocuments = true;
        } 
      });
    });
    document.getElementById('hideFileName').style.display='none';
    document.getElementById('progress-bar-div').style.display='none';
  }

  approveAndSubmit () {
    this.approveInputElements.push({ id: this.approveInputElements.length, name: 'All', remarks: '', status: 'APPROVED' });
  }
  checkAproveRemarks (event) {
    this.remarksadded=event.currentTarget.value? true:false;
    if (event.currentTarget.value) {
      this.documentsrejected=true;
    } else {
      this.documentsrejected=false;
    }
  }

  handleFileInput (files: FileList) {
    this.fileToUpload=files.item(0);
    this.fileSelected=true;
    this.displayFileName=this.fileToUpload.name;
    this.filestoUpload=true;
    if ((this.fileToUpload.type === 'application/zip' || this.fileToUpload.type === 'application/x-zip-compressed') && this.fileToUpload.size<=this.filesSize) {
      const blob=new Blob([this.fileToUpload], {
        type: 'application/zip'
      });
      this.fileData=window.URL.createObjectURL(blob);
      this.isfileuploaded=true;
    } else {
      this.largeFileSize=true;
      alert('Greater file size');
      setTimeout(function () {
        document.getElementById('error_msg_id').style.display='none';
      }, 3000);

      this.filestoUpload=false;
      document.getElementById('hideFileName').style.display='none';
      document.getElementById('progress-bar-div').style.display='none';
      document.getElementById('choose_file_text').innerText='Choose a zip file to upload';
      document.getElementById('img_container').style.display='block';
      this.uploadComplete=false;
      this.isfileuploaded=false;
    }
  }

  uploadFileToActivity () {
    document.getElementById('progress-bar-div').style.display='block';
    document.getElementById('hideFileName').style.display='block';
    document.getElementById('hide_fName_class').style.textAlign=`center`;
    this.postApproveFile=this.preBoardingService.pushFileToStorage(this.fileToUpload, this.approveCandidateID, this.isHR,false).subscribe(
      event => {
        this.progressDisplay=true;
        if (event.type==HttpEventType.UploadProgress) {
          this.percentDone=Math.round(100*event.loaded/event.total);
        } else if (event instanceof HttpResponse) {
        }
      },
      (err) => {
      }, () => {
        this.uploadComplete=true;
        document.getElementById('upload-id-btn').style.display='none';
      }
    )
  }


  removeFile (e) {
    e.target.value='';
    this.postApproveFile.unsubscribe();
    this.filestoUpload=false;
    this.uploadComplete=false;
    document.getElementById('hideFileName').style.display='none';
    document.getElementById('progress-bar-div').style.display='none';
    document.getElementById('choose_file_text').innerText='Choose a zip file to upload';
    document.getElementById('img_container').style.display='block';
  }

  onNoClick (): void {
    this.dialogRef.close();
  }
  statusUpdate (event) {
    this.ngOnInit();
  }
  selectedFilesSent (event) {
    this.selectFiles=event;
  }

  closeApproveDialog () {
    this.dialogRef.close();
  }
  closerejectedDialog () {
    this.dialogRef.close();

  }
  submitApproveDialog () {
    let pushArray=[];
    pushArray.push(...this.identityDocuments, ...this.educationalDocuments, ...this.employmentDocuments, ...this.otherDocuments);
    $('#approved_id_btn').text('Approved');
    $('#2nd-id-A').stop();
    $('#approve_id_btn').prop('disabled', true);
    $('#reject_id_btn').hide();
    this.isFileUploaded=true;
  }
  setStep (index: number) {
    this.step=index;
  }

  /* Identity Docs Methods */
  someCompleteIdentity (): boolean {
    if (this.identityDocuments===null) {
      return false;
    }
    return this.identityDocuments.filter(t => t.completed).length>0&&!this.allComplete;
  }
  updateAllIdentityComplete (e, i, name, type) {
    let isSelectedAll = true;
    if (type==='cb') {
      this.identityDocuments.forEach((ele, i) => {
        if (e.source.name===ele.name) {
          this.identityDocuments[i].status=e.checked? 'APPROVED':'REJECTED';
          this.identityDocuments[i].approved=e.checked? '1':'0';
          delete this.identityDocuments[i].completed;
        }
      });
    } else if (type==='ip') {
      this.identityDocuments.forEach((ele, i) => {
        if (e.target.name===ele.name) {
          this.identityDocuments[i].remarks=e.target.value;
        }
      });
    }
    this.identityDocuments.forEach(element => {
      if (!element.checked) {
        isSelectedAll = false;
      }
    });
    this.allComplete = isSelectedAll;
  }

  setAllIdentity (completed: boolean) {
    if (this.identityDocuments===null) {
      return;
    }
    this.identityDocuments.forEach((ele, i) => {
      ele.completed=completed;
      this.identityDocuments[i].status=completed? 'APPROVED':'REJECTED';
      this.identityDocuments[i].approved=completed? '1':'0';
    });
  }

  /* Educational Docs Methods */

  someCompleteEducational (): boolean {
    if (this.educationalDocuments===null) {
      return false;
    }
    return this.educationalDocuments.filter(t => t.completed).length>0&&!this.allComplete;
  }
  updateAllEducationalComplete (e, i, name, type) {
    if (type==='cb') {
      this.educationalDocuments.forEach((ele, i) => {
        if (e.source.name===ele.name) {
          this.educationalDocuments[i].status=e.checked? 'APPROVED':'REJECTED';
          this.educationalDocuments[i].approved=e.checked? '1':'0';
          delete this.educationalDocuments[i].completed;
        }
      });
    } else if (type==='ip') {
      this.educationalDocuments.forEach((ele, i) => {
        if (e.target.name===ele.name) {
          this.educationalDocuments[i].remarks=e.target.value;
        }
      });
    }
  }

  setAllEducational (completed: boolean) {
    if (this.educationalDocuments===null) {
      return;
    }
    this.educationalDocuments.forEach((ele, i) => {
      ele.completed=completed;
      this.educationalDocuments[i].status=completed? 'APPROVED':'REJECTED';
      this.educationalDocuments[i].approved=completed? '1':'0';
    });
  }

  /* Employment Docs Methods */

  someCompleteEmployment (): boolean {
    if (this.employmentDocuments===null) {
      return false;
    }
    return this.employmentDocuments.filter(t => t.completed).length>0&&!this.allComplete;
  }
  updateAllEmploymentComplete (e, i, name, type) {
    if (type==='cb') {
      this.employmentDocuments.forEach((ele, i) => {
        if (e.source.name===ele.name) {
          this.employmentDocuments[i].status=e.checked? 'APPROVED':'REJECTED';
          this.employmentDocuments[i].approved=e.checked? '1':'0';
          delete this.employmentDocuments[i].completed;
        }
      });
    } else if (type==='ip') {
      this.employmentDocuments.forEach((ele, i) => {
        if (e.target.name===ele.name) {
          this.employmentDocuments[i].remarks=e.target.value;
        }
      });
    }

  }

  setAllEmployment (completed: boolean) {
    if (this.employmentDocuments===null) {
      return;
    }
    this.employmentDocuments.forEach((ele, i) => {
      ele.completed=completed;
      this.employmentDocuments[i].status=completed? 'APPROVED':'REJECTED';
      this.employmentDocuments[i].approved=completed? '1':'0';
    });
  }

  /* Other docs Methods */
  someCompleteOthers (): boolean {
    if (this.otherDocuments===null) {
      return false;
    }
    return this.otherDocuments.filter(t => t.completed).length>0&&!this.allComplete;
  }
  updateAllOthersComplete (e, i, name, type) {
    if (type==='cb') {
      this.otherDocuments.forEach((ele, i) => {
        if (e.source.name===ele.name) {
          this.otherDocuments[i].status=e.checked? 'APPROVED':'REJECTED';
          this.otherDocuments[i].approved=e.checked? '1':'0';
          delete this.otherDocuments[i].completed;
        }
      });
    } else if (type==='ip') {
      this.otherDocuments.forEach((ele, i) => {
        if (e.target.name===ele.name) {
          this.otherDocuments[i].remarks=e.target.value;
        }
      });
    }
  }

  setAllOthers (completed: boolean) {
    if (this.otherDocuments===null) {
      return;
    }
    this.otherDocuments.forEach((ele, i) => {
      ele.completed=completed;
      this.otherDocuments[i].status=completed? 'APPROVED':'REJECTED';
      this.otherDocuments[i].approved=completed? '1':'0';
    });
  }

  /* END OF DOCS METHODS */
  rejectAndAdd () {
    let pushArray=[];
    pushArray.push(...this.identityDocuments, ...this.educationalDocuments, ...this.employmentDocuments, ...this.otherDocuments);
  }
}


@Component({
  selector: 'reject-dialog',
  templateUrl: 'reject-dialog.html',
  styleUrls: ['./hr-documents.component.scss'],
  // providers: [ChecklistDatabase],
})
export class RejectDialogComponent implements OnInit {
  inputelements=[{ name: '', remarks: '', id: 0, status: 'REJECTED' }];
  inputButtons=[{ delete: 'Delete' }];
  docRemarkElements=[{ docName: '' }];
  candidateId: string;
  allInputArray=[];
  testArray=[];
  nameadded: any;
  remarksadded: any;
  documentsrejected: boolean=false;
  selectedFiles: any;
  @Output() filesSent=new EventEmitter<any>();
  displayFileName: any;
  minimmumFileSize: any;
  isFileUploaded: boolean=false;
  fileSelected: boolean=false;
  filesSize=209715200;
  fileData: any=null;
  filestoUpload: boolean;
  progress=0;
  currentFile: File;
  approveCandidateID: any;
  rejectCandidateID: any;
  uploadSuccess: boolean;
  selectFiles: any;
  percentDone=100;
  message='';
  postFile: any;
  largeFileSize: boolean;
  resSubmitUpload: boolean;
  allDocumentCheckList: any;
  identityDocuments=[];
  educationalDocuments=[];
  employmentDocuments=[];
  otherDocuments=[];
  documentCheckList: any;
  identityArray=[];
  educationArray=[];
  employmentArray=[];
  otherArray=[];
  abc: any;
  load: boolean = false;
  candidateDocumentsStatus =[];
  rejectList=[];
  showidentiydocuments: boolean = false;
  showotherdocuments: boolean = false;
  showemploymentdocuments: boolean = false;
  showeducationaldocuments: boolean=false;
  rejectEmployeeType: any;
  condtionalApprovedChecked = false;
  checkedVariable: boolean =false;
  allOtherComplete:boolean=false;
  allEmploymentComplete:boolean=false;
  allEducationalComplete:boolean=false;
  isHR=true;
  uploadComplete: boolean =false;
  progressDisplay=true;
  private fileToUpload: File=null;
  approveRemarks: any;
  allComplete: boolean=false;
  step=0;
  identityControl=new FormControl();
  approvedList=[];
  
  
  constructor(public dialogRef: MatDialogRef<ApproveDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private preBoardingService: PreBoardingService) {
    this.rejectEmployeeType=this.preBoardingService.employeeType;
    console.log('this.rejectEmpType', this.rejectEmployeeType);
    this.uploadComplete=false;
  // this.rejectList = data;

  data.rejectedList.forEach(element => {
    this.rejectList.push(element.name);
  });
  this.approvedList=data.approvedList;
    console.log('rejected array', this.rejectList);
  }
 
  ngOnInit () {
    this.rejectCandidateID=sessionStorage.getItem('candidateID');
    this.preBoardingService.retrieveDocumentStatus(this.rejectCandidateID).subscribe(res => {
      this.candidateDocumentsStatus=res;
    });

      this.preBoardingService.retrieveDocumentCheckList(this.rejectEmployeeType).subscribe(res => {
        this.documentCheckList=res.documentCheckList;
        var a=Object.keys(this.documentCheckList);
        if (!this.documentCheckList.EDUCATIONAL_DOCUMENTS) {
          this.documentCheckList.EDUCATIONAL_DOCUMENTS = [];
        }
        // Educational
        this.documentCheckList.EDUCATIONAL_DOCUMENTS.forEach((ele, i) => {
          this.documentCheckList.EDUCATIONAL_DOCUMENTS[i]['remarks']='';
          this.documentCheckList.EDUCATIONAL_DOCUMENTS[i]['status']='APPROVED';
          this.documentCheckList.EDUCATIONAL_DOCUMENTS[i]['approved']='1';
          this.documentCheckList.EDUCATIONAL_DOCUMENTS[i]['name']=this.documentCheckList.EDUCATIONAL_DOCUMENTS[i]['documentName'];
          delete this.documentCheckList.EDUCATIONAL_DOCUMENTS[i]['id'];
          delete this.documentCheckList.EDUCATIONAL_DOCUMENTS[i]['documentName'];
        });
        this.educationalDocuments=this.documentCheckList.EDUCATIONAL_DOCUMENTS;
        this.educationalDocuments.forEach(element => {
          if (this.rejectList.indexOf(element.name)>-1) {
            this.showeducationaldocuments=true;
          }
        });
        if (!this.documentCheckList.EMPLOYMENT_DOCUMENTS) {
          this.documentCheckList.EMPLOYMENT_DOCUMENTS = [];
        }
        // Employment
        this.documentCheckList.EMPLOYMENT_DOCUMENTS.forEach((ele, i) => {
          this.documentCheckList.EMPLOYMENT_DOCUMENTS[i]['remarks']='';
          this.documentCheckList.EMPLOYMENT_DOCUMENTS[i]['status']='APPROVED';
          this.documentCheckList.EMPLOYMENT_DOCUMENTS[i]['approved']='1';
          this.documentCheckList.EMPLOYMENT_DOCUMENTS[i]['name']=this.documentCheckList.EMPLOYMENT_DOCUMENTS[i]['documentName'];
          delete this.documentCheckList.EMPLOYMENT_DOCUMENTS[i]['id'];
          delete this.documentCheckList.EMPLOYMENT_DOCUMENTS[i]['documentName'];
        });
        this.employmentDocuments=this.documentCheckList.EMPLOYMENT_DOCUMENTS;
        this.employmentDocuments.forEach(element => {
          if (this.rejectList.indexOf(element.name)>-1) {
            this.showemploymentdocuments=true;
          }
        });
        if (!this.documentCheckList.OTHERS) {
          this.documentCheckList.OTHERS = [];
        }
        /* Others */
        this.documentCheckList.OTHERS.forEach((ele, i) => {
          this.documentCheckList.OTHERS[i]['remarks']='';
          this.documentCheckList.OTHERS[i]['status']='APPROVED';
          this.documentCheckList.OTHERS[i]['approved']='1';
          this.documentCheckList.OTHERS[i]['name']=this.documentCheckList.OTHERS[i]['documentName'];
          delete this.documentCheckList.OTHERS[i]['id'];
          delete this.documentCheckList.OTHERS[i]['documentName'];
        });
        this.otherDocuments=this.documentCheckList.OTHERS;
        this.otherDocuments.forEach(element => {
          if (this.rejectList.indexOf(element.name)>-1) {
            this.showotherdocuments=true;
          }
        });
        if (!this.documentCheckList.IDENTITY_DOCUMENTS) {
          this.documentCheckList.IDENTITY_DOCUMENTS = [];
        }
        // Identity Docs
        this.documentCheckList.IDENTITY_DOCUMENTS.forEach((ele, i) => {
          this.documentCheckList.IDENTITY_DOCUMENTS[i]['remarks']='';
          this.documentCheckList.IDENTITY_DOCUMENTS[i]['status']='APPROVED';
          this.documentCheckList.IDENTITY_DOCUMENTS[i]['approved']='1';
          this.documentCheckList.IDENTITY_DOCUMENTS[i]['name']=this.documentCheckList.IDENTITY_DOCUMENTS[i]['documentName'];
          delete this.documentCheckList.IDENTITY_DOCUMENTS[i]['documentName'];
          delete this.documentCheckList.IDENTITY_DOCUMENTS[i]['id'];
        });
        this.identityDocuments=this.documentCheckList.IDENTITY_DOCUMENTS;
        this.identityDocuments.forEach(element => {
          if (this.rejectList.indexOf(element.name)>-1) {
            this.showidentiydocuments=true;
          }
        });
      });
    document.getElementById('hideFileNames').style.display='none';
  }
  setStep (index: number) {
    this.step=index;
  }

  /* Identity Docs Methods */
  someCompleteIdentity (): boolean {
    if (this.identityDocuments===null) {
      return false;
    }
    return this.identityDocuments.filter(t => t.completed).length>0&&!this.allComplete;
  }
  updateAllIdentityComplete (e, i, name, type) {
    if (type==='cb') {
      this.identityDocuments.forEach((ele, i) => {
        if (e.source.name===ele.name) {
          this.identityDocuments[i].status=!e.checked? 'APPROVED':'REJECTED';
          this.identityDocuments[i].approved=!e.checked? '1':'0';
          this.identityDocuments[i].checked = e.checked;
          delete this.identityDocuments[i].completed;
        }
      });
    } else if (type==='ip') {
      this.identityDocuments.forEach((ele, i) => {
        if (e.target.name===ele.name) {
          this.identityDocuments[i].remarks=e.target.value;
        }
      });
    }
    let isSelectedAll = true;
    this.identityDocuments.forEach(element => {
      if (!element.checked) {
        isSelectedAll = false;
      }
    });
    this.allComplete = isSelectedAll;
  }

  setAllIdentity (completed: boolean) {
    if (this.identityDocuments===null) {
      return;
    }
    this.identityDocuments.forEach((ele, i) => {
      ele.completed=completed;
      this.identityDocuments[i].status=!completed? 'APPROVED':'REJECTED';
      this.identityDocuments[i].approved=!completed? '1':'0';
    });
    this.allComplete = completed;
    if (completed && this.allEducationalComplete  && this.allEmploymentComplete  && this.allOtherComplete) {
      this.checkedVariable = true;
    }
  }
  

  conditionalApproveChecked (event) {
    // this.condtionalApprovedChecked=event.checked;
    this.condtionalApprovedChecked=event.checked;
    console.log(this.condtionalApprovedChecked);
    this.setAllIdentity(event.checked);
    this.setAllEducational(event.checked);
    this.setAllEmployment(event.checked);
    this.setAllOthers(event.checked);
    if (this.condtionalApprovedChecked) {
      this.checkedVariable=true;
    } else if (this.condtionalApprovedChecked===false) {
      this.checkedVariable = false;
    }
  }
  rejectAndAdd () {
    let pushArray=[];
    pushArray.push(...this.identityDocuments, ...this.educationalDocuments, ...this.employmentDocuments, ...this.otherDocuments);
  }

  /* Educational Docs Methods */

  someCompleteEducational (): boolean {
    if (this.educationalDocuments===null) {
      return false;
    }
    return this.educationalDocuments.filter(t => t.completed).length>0&&!this.allComplete;
  }
  updateAllEducationalComplete (e, i, name, type) {
    if (type==='cb') {
      this.educationalDocuments.forEach((ele, i) => {
        if (e.source.name===ele.name) {
          this.educationalDocuments[i].status=!e.checked? 'APPROVED':'REJECTED';
          this.educationalDocuments[i].approved=!e.checked? '1':'0';
          delete this.educationalDocuments[i].completed;
        }
      });
    } else if (type==='ip') {
      this.educationalDocuments.forEach((ele, i) => {
        if (e.target.name===ele.name) {
          this.educationalDocuments[i].remarks=e.target.value;
        }
      });
    }
  }

  setAllEducational (completed: boolean) {
    if (this.educationalDocuments===null) {
      return;
    }
    this.educationalDocuments.forEach((ele, i) => {
      ele.completed=completed;
      this.educationalDocuments[i].status=!completed? 'APPROVED':'REJECTED';
      this.educationalDocuments[i].approved=!completed? '1':'0';
    });
    this.allEducationalComplete = completed;
    if (completed && this.allComplete  && this.allEmploymentComplete  && this.allOtherComplete) {
      this.checkedVariable = true;
    }
  }

  /* Employment Docs Methods */

  someCompleteEmployment (): boolean {
    if (this.employmentDocuments===null) {
      return false;
    }
    return this.employmentDocuments.filter(t => t.completed).length>0&&!this.allComplete;
  }
  updateAllEmploymentComplete (e, i, name, type) {
    if (type==='cb') {
      this.employmentDocuments.forEach((ele, i) => {
        if (e.source.name===ele.name) {
          this.employmentDocuments[i].status=!e.checked? 'APPROVED':'REJECTED';
          this.employmentDocuments[i].approved=!e.checked? '1':'0';
          delete this.employmentDocuments[i].completed;
        }
      });
    } else if (type==='ip') {
      this.employmentDocuments.forEach((ele, i) => {
        if (e.target.name===ele.name) {
          this.employmentDocuments[i].remarks=e.target.value;
        }
      });
    }

  }

  setAllEmployment (completed: boolean) {
    if (this.employmentDocuments===null) {
      return;
    }
    this.employmentDocuments.forEach((ele, i) => {
      ele.completed=completed;
      this.employmentDocuments[i].status=!completed? 'APPROVED':'REJECTED';
      this.employmentDocuments[i].approved=!completed? '1':'0';
    });
    this.allEmploymentComplete = completed;
    if (completed && this.allComplete  && this.allEducationalComplete && this.allOtherComplete) {
      this.checkedVariable = true;
    }
  }

  /* Other docs Methods */
  someCompleteOthers (): boolean {
    if (this.otherDocuments===null) {
      return false;
    }
    return this.otherDocuments.filter(t => t.completed).length>0&&!this.allComplete;
  }
  updateAllOthersComplete (e, i, name, type) {
    if (type==='cb') {
      this.otherDocuments.forEach((ele, i) => {
        if (e.source.name===ele.name) {
          this.otherDocuments[i].status=!e.checked? 'APPROVED':'REJECTED';
          this.otherDocuments[i].approved=!e.checked? '1':'0';
          delete this.otherDocuments[i].completed;
        }
      });
    } else if (type==='ip') {
      this.otherDocuments.forEach((ele, i) => {
        if (e.target.name===ele.name) {
          this.otherDocuments[i].remarks=e.target.value;
        }
      });
    }
  }

  setAllOthers (completed: boolean) {
    if (this.otherDocuments===null) {
      return;
    }
    this.otherDocuments.forEach((ele, i) => {
      ele.completed=completed;
      this.otherDocuments[i].status=!completed? 'APPROVED':'REJECTED';
      this.otherDocuments[i].approved=!completed? '1':'0';
    });
    this.allOtherComplete = completed;
    if (completed && this.allComplete  && this.allEducationalComplete && this.allEmploymentComplete) {
      this.checkedVariable = true;
    }
  }

  /* END OF DOCS METHODS */
  onNoClick (): void {
    this.dialogRef.close();
  }

  submitRejectedDocs () {
    this.rejectCandidateID=sessionStorage.getItem('candidateID');
    let pushArray=[];
    pushArray.push(...this.identityDocuments, ...this.educationalDocuments, ...this.employmentDocuments, ...this.otherDocuments);
    $('#reject_id_btn').text('Review Documents');
    $('#reject_id_btn').prop('disabled', true);
    $('#approve_id_btn').hide();
    console.log('pusharray',pushArray);
    pushArray.forEach(element => {
      let index = this.approvedList.findIndex(x => x.name === element.name);
      if(index>-1){
        element.approved=1;
        element.completed=false;
        element.status="APPROVED";
      }
    })
    this.documentsrejected=true;
     this.load=true;
    this.preBoardingService.documentStatusChange(this.rejectCandidateID, this.checkedVariable, pushArray).subscribe(result => {
      this.preBoardingService.documentSubmissionMail(this.rejectCandidateID,true, false).subscribe(res=> {
        console.log(res);
      });
      this.dialogRef.close();
      this.load=false;
      window.location.reload();
    });

  }
  rejectDialogclose () {
    this.documentsrejected=false;
    this.dialogRef.close();
  }
  checkRejectName (event) {
    this.nameadded=event.currentTarget.value? true:false;
    if (event.currentTarget.value&&this.remarksadded) {
      this.documentsrejected=true;
    } else {
      this.documentsrejected=false;
    }

  }
  checkRejectRemarks (event) {
    this.remarksadded=event.currentTarget.value? true:false;
    if (event.currentTarget.value&&this.nameadded) {
      this.documentsrejected=true;
    } else {
      this.documentsrejected=false;
    }
  }


  handleFileInput (files: FileList) {
    this.fileToUpload=files.item(0);
    this.fileSelected=true;
    this.displayFileName=this.fileToUpload.name;
    this.filestoUpload=true;
    if (this.fileToUpload.size<=this.filesSize) {
      const blob=new Blob([this.fileToUpload], {
        type: 'application/octet-stream'
      });
      this.fileData=window.URL.createObjectURL(blob);
      document.getElementById('hideFileNames').style.display=`block`;
			document.getElementById('hide_fName_class').style.display=`block`;
			document.getElementById('hide_fName_class').style.textAlign=`center`;
			document.getElementById('img_container').style.display='none';
    } else {
      this.largeFileSize=true;
      alert('Greater file size');
      setTimeout(function () {
        document.getElementById('error_msg_id').style.display='none';
      }, 3000);
      this.filestoUpload=false;
      document.getElementById('hideFileNames').style.display='none';
      // document.getElementById('progress-bar-div').style.display='none';
      document.getElementById('choose_file_text').innerText='Choose a zip file to upload';
      document.getElementById('img_container').style.display='block';
      this.uploadComplete=false;
    }
  }

  uploadFileToActivity () {
    this.load = true;
    this.postFile=this.preBoardingService.pushFileToStorage(this.fileToUpload, this.rejectCandidateID, this.isHR,false).subscribe(
      event => {
        this.progressDisplay=true;
        this.load = false;
      },
      (err) => {
      }, () => {
        this.uploadComplete=true;
        this.load = false;
        document.getElementById('upload-id-btn').style.display='none';
      }
    )
  }

  removeFile (e) {
    e.target.value='';
    this.filestoUpload=false;
    this.uploadComplete=false;
    this.load = false;
    document.getElementById('hideFileNames').style.display='none';
    document.getElementById('choose_file_text').innerText='Choose a zip file to upload';
    document.getElementById('img_container').style.display='block';
  }

};
