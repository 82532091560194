import { KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment'
declare var keycloak : KeycloakService;
export function initializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => keycloak.init(
  {
    config: {
        url: environment.keycloackUrl,
        realm: 'preboarding',
        clientId: 'preboarding',
    },
    initOptions: {
      onLoad: 'login-required',
      checkLoginIframe: false,
      redirectUri: environment.redirectUri
    },
    enableBearerInterceptor: false
  });}
