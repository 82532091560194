import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_COMPLETE_DATE_FORMATS } from '../app-constants';
import { MatPaginatorModule } from '@angular/material/paginator';
import { HasAuthorityDirective } from './has-authority.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NoDblClickDirective } from '../directives/app-no-dbl-click.directive';





@NgModule({
  declarations: [HasAuthorityDirective,NoDblClickDirective],
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatNativeDateModule,
    MatInputModule,
    MatSidenavModule,
    MatTableModule,
    MatTabsModule,
    MatPaginatorModule,
    MatSelectModule,
    MatProgressSpinnerModule
  ],
  providers:[
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_COMPLETE_DATE_FORMATS },
    {provide: MAT_DATE_LOCALE, useValue: 'en-US'}
  ],
  exports: [
    HasAuthorityDirective,
    NoDblClickDirective,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatNativeDateModule,
    MatInputModule,
    MatSidenavModule,
    MatTableModule,
    MatTabsModule,
    MatPaginatorModule,
    MatSelectModule,
    MatProgressSpinnerModule
  ]
})
export class SharedModule { }
