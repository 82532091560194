import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import $ from 'jquery';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pre-boarding-UI';
  auth: any;
  constructor(private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private keycloakService : KeycloakService) {}
  ngOnInit() {
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          const child = this.activatedRoute.firstChild;
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
      this.auth = this.keycloakService.getUserRoles();
      for (let i=0;i<=this.auth.length;i++) {
       if (this.auth[i]==='DASHBOARD_VIEW_CANDIDATE') {
        $('head').append('<script disable-devtool-auto src="https://cdn.jsdelivr.net/npm/disable-devtool/disable-devtool.min.js"></script>');
        //  console.log('Commented for testing purpose');
        break;
       }
      }
  }
}

