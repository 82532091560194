<div class="row top-class">
	<div class="col-12 base-col-12">
		<div class="init-icons row" style="margin-left: 23px;">
			<div class="col-12">
				<mat-form-field appearance="outline" class="search-bar-class">
					<img src="../../../assets/baseline_search_black_24dp.png" class="search_icon" matPrefix>
					<input type="text" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
						(keyup)="applyFilter($event)"
						placeholder="Search Candidate(eg. type Candidate Name, Email-ID etc.)" matInput>
				</mat-form-field>
			</div>
		</div>
		<form [formGroup]="searchFormReports">
			<div class="row row-fields">
				<div class="col-sm-12 col-lg-3 col-md-6">
					<p class="label_class">Gender</p>
					<mat-form-field appearance="outline" class="form-field-width-class">
						<mat-select formControlName="Gender" panelClass="employeetype-select">
							<mat-option value="all">All</mat-option>
							<mat-option value="1">Male</mat-option>
							<mat-option value="2">Female</mat-option>
							<mat-option value="3">Other</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-sm-12 col-lg-3 col-md-6">
					<p class="label_class">Blood Group</p>
					<mat-form-field appearance="outline" class="form-field-width-class">
						<mat-select  formControlName="bloodGroup">
							<mat-option value="all">All</mat-option>
							<mat-option value="1"> A positive (A+) </mat-option>
							<mat-option value="2"> A negative (A-) </mat-option>
							<mat-option value="3"> B positive (B+) </mat-option>
							<mat-option value="4"> B negative (B-) </mat-option>
							<mat-option value="5"> O positive (O+) </mat-option>
							<mat-option value="6"> O negative (O-) </mat-option>
							<mat-option value="7"> AB positive (AB+) </mat-option>
							<mat-option value="8"> AB negative (AB-) </mat-option>
							<mat-option value="9"> Other </mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-sm-12 col-lg-3 col-md-6">
					<p class="label_class">Qualification</p>
					<mat-form-field class="form_field_style" appearance="outline" class="form-field-width-class">
						<mat-select formControlName="qualification">
							<mat-option value="all">All</mat-option>
							<mat-option value="graduate">Graduate</mat-option>
							<mat-option value="PG">PG</mat-option>
							<mat-option value="phd">Other</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-sm-12 col-lg-3 col-md-6">
					<p class="label_class">Marital Status</p>
					<mat-form-field appearance="outline" class="form-field-width-class">
						<mat-select  formControlName="maritalStatus">
							<mat-option value="all">All</mat-option>
							<mat-option value="1"> Married </mat-option>
							<mat-option value="2"> Unmarried </mat-option>
							<mat-option value="3">divorced</mat-option>
							<mat-option value="4">Widowed</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>

			<div class="row row-fields">
				<div class="col-sm-12 col-lg-3 col-md-6">
					 <!-- <p *ngIf="(FromDate || ToDate)" class="label_class">Date Type*</p>  -->
				   <p  class="label_class">Date Type</p>
					<mat-form-field appearance="outline" class="form-field-width-class" >
						<mat-select formControlName="eventType" panelClass="employeetype-select" >
							<mat-option value="DOB">Date of Birth</mat-option>
							<mat-option value="PASSPORTEXPIRY">Passport Expiry </mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-sm-12 col-lg-3 col-md-6">
					<p class="label_class">From Date</p>
					<mat-form-field appearance="outline" class="form-field-width-class">
						<input formControlName="FromDate" matInput [matDatepicker]="personalInfodob1" readonly>
						<mat-datepicker-toggle matSuffix [for]="personalInfodob1">
						</mat-datepicker-toggle>
						<mat-datepicker #personalInfodob1></mat-datepicker>
					</mat-form-field>
				</div>

				<div class="col-sm-12 col-lg-3 col-md-6">
					<p class="label_class">To Date</p>
					<mat-form-field appearance="outline" class="form-field-width-class">
						<input formControlName="ToDate" matInput [matDatepicker]="personalInfodob"readonly>
						<mat-datepicker-toggle matSuffix [for]="personalInfodob">
						</mat-datepicker-toggle>
						<mat-datepicker #personalInfodob></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="col-sm-12 col-lg-3 col-md-6">
					<p class="label_class">Experience</p>
					<mat-form-field appearance="outline" class="form-field-width-class">
						<mat-select  formControlName="TotalPreviousExperience">
							<mat-option value="all">All</mat-option>
							<mat-option value="0-2"> 0-2 </mat-option>
							<mat-option value="2-5"> 2-5 </mat-option>
							<mat-option value="5-10">5-10</mat-option>
							<mat-option value="10-40">10+</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>

		</form>
		<div class="row float-row">
			<div class="col-12">
				<div class="init-icons">
					<button mat-button class="export_btn search_btn" (click)="advancedSearch('search')"> <img
							src="../../../assets/outline_search_white_24dp.png" matPrefix>Search</button>
					<button mat-button class="export_btn reset_btn" (click)="resett()"> <img
							src="../../../assets/baseline_restart_alt_white_24dp.png" matPrefix>Reset</button>
					<button mat-button class="export_btn export_resource_btn" (click)="advancedSearch('export')"> <img
							src="../../../assets/outline_file_download_white_24dp.png" matPrefix>Export Resource
						List</button>
					<button mat-button class="export_btn" (click)="redirectToDashboard()">Dashboard</button>
				</div>
			</div>
		</div>
		<div class="row table-row">
			<div class="col-12">
				<table mat-table [dataSource]="reportsDataSource" class="mat-elevation-z8 reports-table">
					<!-- SINO -->
					<ng-container matColumnDef="srNo">
						<th class="reports-color" mat-header-cell *matHeaderCellDef># </th>
						<td mat-cell *matCellDef="let element;let i=index"  class="candidate-name-class">
							{{i+1}}
					</ng-container>

					<!-- location -->
					<ng-container matColumnDef="location">
						<th class="reports-color" mat-header-cell *matHeaderCellDef>Location</th>
						<td mat-cell *matCellDef="let element" class="candidate-name-class">
							<span class="mobile-label">location : </span>
							{{element.location}}
						</td>
					</ng-container>

					<!--employeeId -->
					<ng-container matColumnDef="employeeId">
						<th class="reports-color" mat-header-cell *matHeaderCellDef>Candidate ID</th>
						<td mat-cell *matCellDef="let element" class="candidate-name-class">
							<span class="mobile-label">Candidate ID : </span>
							{{element.employeeId}}
						</td>
					</ng-container>

					<!--employeeName -->
					<ng-container matColumnDef="employeeName">
						<th class="reports-color" mat-header-cell *matHeaderCellDef>Candidate Name</th>
						<td mat-cell *matCellDef="let element" class="candidate-name-class">
							<span class="mobile-label">CandidateName : </span>
							{{element.employeeName}}
						</td>
					</ng-container>

					<!-- employeeFatherName -->
					<ng-container matColumnDef="employeeFatherName">
						<th class="reports-color" mat-header-cell *matHeaderCellDef>Candidate Fathers Name</th>
						<td mat-cell *matCellDef="let element" class="candidate-name-class">
							<span class="mobile-label">CandidatFatherName : </span>
							{{element.employeeFatherName}}
						</td>
					</ng-container>

					<!-- employeegender -->
					<ng-container matColumnDef="employeegender">
						<th class="reports-color" mat-header-cell *matHeaderCellDef>Gender</th>
						<td mat-cell *matCellDef="let element" class="candidate-name-class">
							<span class="mobile-label">Gender : </span>
							{{element.employeegender}}
						</td>
					</ng-container>

					<!-- employeeMaritalStatus -->
					<ng-container matColumnDef="employeeMaritalStatus">
						<th class="reports-color" mat-header-cell *matHeaderCellDef>Marital Status</th>
						<td mat-cell *matCellDef="let element" class="candidate-name-class">
							<span class="mobile-label">employeeMaritalStatus : </span>
							{{element.employeeMaritalStatus}}
						</td>
					</ng-container>

                       <!-- employeeMobile1 -->
					<ng-container matColumnDef="employeeMobile1">
						<th class="reports-color" mat-header-cell *matHeaderCellDef>Mobile Number </th>
						<td mat-cell *matCellDef="let element" class="candidate-name-class">
							<span class="mobile-label">Mobile Number : </span>
							{{element.employeeMobile1}}
						</td>
					</ng-container>   


                   <!-- qualification -->
					<ng-container matColumnDef="qualification">
						<th class="reports-color" mat-header-cell *matHeaderCellDef>Qualification</th>
						<td mat-cell *matCellDef="let element" class="candidate-name-class">
							<span class="mobile-label">Qualification : </span>
							{{element.qualification}}
						</td>
					</ng-container>

					<!-- degree -->
					<ng-container matColumnDef="degree">
						<th class="reports-color" mat-header-cell *matHeaderCellDef>Degree</th>
						<td mat-cell *matCellDef="let element" class="candidate-name-class">
							<span class="mobile-label">Degree : </span>
							{{element.degree}}
						</td>
					</ng-container>
                    
					<!-- employeePersonalEmailId-->
					<ng-container matColumnDef="employeePersonalEmailId">
						<th class="reports-color" mat-header-cell *matHeaderCellDef>Email Id</th>
						<td mat-cell *matCellDef="let element" class="candidate-name-class">
							<span class="mobile-label">EmailId : </span>
							{{element.employeePersonalEmailId}}
						</td>
					</ng-container>

                    <!-- employeePassportNo -->
					<ng-container matColumnDef="employeePassportNo">
						<th class="reports-color" mat-header-cell *matHeaderCellDef>Passport No</th>
						<td mat-cell *matCellDef="let element" class="candidate-name-class">
							<span class="mobile-label">PassportNo : </span>
							{{element.employeePassportNo}}
						</td>
					</ng-container>

			
					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
				</table>
				<mat-paginator class="paginator-custom-class" [length]="reportsLength"
					[pageSizeOptions]="[5, 10, 25, 100]" [showFirstLastButtons]="showFirstLastButtons">
				</mat-paginator>
			</div>
		</div>
	</div>
</div>