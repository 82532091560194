<div [ngClass]="{'no-actions': load}" style="position: relative">
	<!-- This page content will be blurred/frosted when loading background overlay is showed -->

	<!-- Candidate Dashboard View -->
	<div class="content-div" *appHasAuthority="'DASHBOARD_VIEW_CANDIDATE'">
		<div class="dashboard-row">
			<div class="dashboard-col">
				<img src="../../../assets/header_image.png" class="dashboard-image"
					alt="Connect and Engage with the GSPANN family">
			</div>
			<div class="dashboard-column" *ngIf='userDeatils'>
				<div class="user-details">
					<h5><strong>Dear {{userDeatils.firstName}}!</strong>
					</h5>
					<div class="congrats_msg ">Congratulations and welcome to the GSPANN family!.</div>
					<div class="text_msg"> We are thrilled to have you with us and we believe you’ll accomplish great
						things here.
						To help you get started on your joining process, we request you to go through each section and
						fill in your information.</div>
					<div class="btn_div">
						<button mat-button class="get_started_btn" (click)="getStarted(userDeatils)">Get
							Started</button>
					</div>
					<div class="queries_msg"> In case of any questions, you can reach us at <a
							href="#hr_operations@gspann.com">#hr_operations@gspann.com</a>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- HR Dashboard View -->

	<!-- <div class="" > -->

	<!-- <mat-progress-spinner *ngIf="load" mode="determinate" [value]="50"></mat-progress-spinner> -->

	<div class="row HR_view_div" *appHasAuthority="'DASHBOARD_VIEW_HR'" style="max-width: 100%;min-height: 100%;">
		<div class="col-2 navigation-bar" *ngIf="userDeatils">
			<mat-card class="candidate_name_class">
				<h5>Hi {{userDeatils.firstName}}</h5>
				<br>
				<!-- <p>You have got <a href="#">2 messages.</a> </p> -->
			</mat-card>
			<div class="candidate_stepper_class">
				<div class="step">
					<div class="step_name step-focus" id="dashboard" (click)="moreClicked('dashboard')">Dashboard</div>
				</div>
				<div class="step" *appHasAuthority="'VIEW_INPROGRESS_CANDIDATE'">
					<div class="step_name" id="candidateInfoInProgress"
						(click)="moreClicked('candidateInfoInProgress')">In progress</div>
				</div>
				<div class="step" *appHasAuthority="'VIEW_PROCESSED_CANDIDATE'">
					<div class="step_name" id="candidateInfoProcessed" (click)="moreClicked('candidateInfoProcessed')">
						Processed</div>
				</div>
				<div class="step" *appHasAuthority="'VIEW_JOINED_CANDIDATE'">
					<div class="step_name" id="candidateInfoJoined" (click)="moreClicked('candidateInfoJoined')">Joined
					</div>
				</div>
				<div class="step" *appHasAuthority="'VIEW_DECLINED_CANDIDATE'">
					<div class="step_name" id="candidateInfoDeclined" (click)="moreClicked('candidateInfoDeclined')">Not
						Joined</div>
				</div>
				<div class="step" *appHasAuthority="'SWAGGER_DASHBOARD_VIEW'">
					<div class="step_name" id="apiCalled" (click)="moreClicked('apiCalled')">API</div>
				</div>
				<div class="step" *appHasAuthority="'PB_REPORTS'">
					<!-- <img class="step_name" style="width:20px" src="../../../assets/icons8-plus-48.png"> -->
					<div (click)="showMore = !showMore" class="step_name">Reports</div>
				</div>
				
				<div *ngIf="showMore">
					<ul>
						<li>Candidate Reports</li>
						<ul>
							<li>
							<a class="step_name step-focus"  style="cursor: pointer;" (click)="reportsClicked()">Candidates Reports</a>
							</li>
							<li>
							<a class="step_name step-focus" style="cursor: pointer;" (click)="candidatereportsClicked()">Candidates Personal Info</a>
							</li>
						</ul>
						<li>BGV Reports</li>
						<ul>
							<li>
							<a class="step_name step-focus" style="cursor: pointer;" (click)="bgvStatusReports('overall')">Overall Status</a>
							</li>
							<li>
							<a class="step_name step-focus" style="cursor: pointer;" (click)="bgvStatusReports('insufficiency')">Insufficiency</a>
							</li>
						</ul>
					</ul>
				</div>
			</div>
		</div>
		<div class="col-10" style="margin-top: 67px;padding-right: 0;">
			<div class="row">
				<div class="col-12" *ngIf="showApi">
					<div #testUi></div>
				</div>
				<div class="col-12" *ngIf="!showApi">
					<div class="row">
						<div class="col-12" *appHasAuthority="'CREATE_CANDIDATE'" style="padding: 20px 0px 30px 30px;box-shadow: 1px 2px 3px rgba(0,0,0,0.25);">
							<h3>New Candidate</h3>
							<p>Create a new candidate</p>
							<button mat-button class="create_candidate_btn" id="candidate_create_update_btn"
								(click)="openUpdateDialogBox()">CREATE CANDIDATE</button>
						</div>
		
						<div class="col-12" style="padding: 20px 55px;">
							<h3>All Candidates</h3>
						</div>
		
						<div class="row" style="min-width: 100%;padding-left: 20px;">
							<div class="col-3 max-width">
								<mat-toolbar class="recently_added_toolbar status_toolbar">
									<span>Recently Added</span>
								</mat-toolbar>
							</div>
							<div class="col-3 max-width" *appHasAuthority="'VIEW_INPROGRESS_CANDIDATE'">
								<mat-toolbar class="in_progress_toolbar status_toolbar">
									<span>In Progress</span>
								</mat-toolbar>
							</div>
							<div class="col-3 max-width" *appHasAuthority="'VIEW_PROCESSED_CANDIDATE'">
								<mat-toolbar class="process_toolbar status_toolbar">
									<span>Processed</span>
								</mat-toolbar>
							</div>
							<div class="col-3 max-width" *appHasAuthority="'VIEW_JOINED_CANDIDATE'">
								<mat-toolbar class="candidates_joined_toolbar status_toolbar">
									<span>Candidates Joined</span>
								</mat-toolbar>
							</div>
							<div class="col-3 max-width" *appHasAuthority="'VIEW_DECLINED_CANDIDATE'">
								<mat-toolbar class="candidates_declined_toolbar status_toolbar">
									<span>Candidates Declined</span>
								</mat-toolbar>
							</div>
						</div>
						<div class="row" style="min-width: 100%;padding-left: 20px;">
							<div class="col-3 max-width">
								<div *ngFor="let candidates of candidateInfo;let in=index">
									<div *ngIf="in<=2">
										<mat-card id="mat-card-pending" class="recently_added_card_class"
											matTooltip="Name: {{candidates.name}}&#13; Email ID: {{candidates.emailId}}"
											[matTooltipPosition]="'above'">
		
											<h5><a class="hyper-link" (click)="getCandidate(candidates)">{{candidates.name}}</a>
											</h5>
											<button mat-icon-button class="icons-css" [matMenuTriggerFor]="menu"
												style="float: right;bottom: 40px;left: 20px;">
												<img src="../../../assets/baseline_more_vert_black_24dp.png" alt="More">
											</button>
											<mat-menu #menu="matMenu">
												<button id="id_edit_btn" (click)="openUpdate(candidates)" class="icons-css"
													mat-menu-item>
													<span>Edit</span>
												</button>
												<button *appHasAuthority="'DELETE_CANDIDATE'" (click)="deleteCandidateDialogBox(candidates)" class="icons-css"
													mat-menu-item>
													<span>Delete</span>
												</button>
											</mat-menu>
											<p style="word-break: break-all;">{{candidates.emailId}}</p>
											<p>{{candidates.validUpto | date: 'dd/MM/yyyy'}}</p>
										</mat-card>
									</div>
								</div>
								<div *ngIf="candidateInfo != null && candidateInfo.length>=1">
									<mat-card id="mat-card-pending" class="recently_added_card_class">
										<a (click)="moreClicked('candidateInfo')"
											style="cursor: pointer;color: #007bff;">More</a>
									</mat-card>
								</div>
							</div>
							<div class="col-3 max-width" *appHasAuthority="'VIEW_INPROGRESS_CANDIDATE'">
								<div *ngFor="let candidates of candidateInfoInProgress;let i=index">
									<div *ngIf="i<=2">
										<mat-card class="recently_added_card_class"
											matTooltip=" Name: {{candidates.name}}&#13; Email ID: {{candidates.emailId}}"
											[matTooltipPosition]="'above'">
											<h5><a class="hyper-link" (click)="getCandidate(candidates)">{{candidates.name}}</a>
											</h5>
											<p style="word-break: break-all;">{{candidates.emailId}}</p>
											<p>{{candidates.validUpto | date: 'dd/MM/yyyy'}}</p>
										</mat-card>
									</div>
								</div>
								<div *ngIf="candidateInfoInProgressLength>=2">
									<mat-card class="recently_added_card_class">
										<a style="cursor: pointer;color: #007bff;"
											(click)="moreClicked('candidateInfoInProgress')">More</a>
									</mat-card>
								</div>
							</div>
		
							<div class="col-3 max-width" *appHasAuthority="'VIEW_PROCESSED_CANDIDATE'">
								<div *ngFor="let candidates of candidateInfoProcessed;let i=index">
									<div *ngIf="i<=2">
										<mat-card class="recently_added_card_class"
											matTooltip="Name:   {{candidates.name}} &#13; Email ID: {{candidates.emailId}}"
											[matTooltipPosition]="'above'">
											<h5><a class="hyper-link" (click)="getCandidate(candidates)">{{candidates.name}}</a>
											</h5>
											<p style="word-break: break-all;">{{candidates.emailId}}</p>
											<p>{{candidates.validUpto | date: 'dd/MM/yyyy'}}</p>
										</mat-card>
									</div>
								</div>
								<div *ngIf="candidateInfoProcessed && candidateInfoProcessed.length>=2">
									<mat-card id="mat-card-pending" class="recently_added_card_class">
										<a (click)="moreClicked('candidateInfoProcessed')"
											style="cursor: pointer;color: #007bff;">More</a>
									</mat-card>
								</div>
							</div>
							<div class="col-3 max-width" *appHasAuthority="'VIEW_JOINED_CANDIDATE'">
								<div *ngFor="let candidates of candidateInfoJoined;let i=index">
									<div *ngIf="i<=2">
										<mat-card class="recently_added_card_class">
											<h5><a class="hyper-link" (click)="getCandidate(candidates)">{{candidates.name}}</a>
											</h5>
											<p style="word-break: break-all;">{{candidates.emailId}}</p>
											<p>{{candidates.validUpto | date: 'dd/MM/yyyy'}}</p>
										</mat-card>
									</div>
								</div>
								<div *ngIf="candidateInfoJoined && candidateInfoJoined.length>=2">
									<mat-card id="mat-card-pending" class="recently_added_card_class">
										<a (click)="moreClicked('candidateInfoJoined')"
											style="cursor: pointer;color: #007bff;">More</a>
									</mat-card>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<mat-spinner *ngIf="load" class="loader" strokeWidth="3" [diameter]="50"></mat-spinner>
</div>