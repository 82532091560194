import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { ErrorDialogComponent } from './interceptor/error-dialog/errordialog.component';
import { ErrorDialogService } from './interceptor/error-dialog/errordialog.service';
import { MatDialogModule } from '@angular/material/dialog';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CompletionComponent } from './completion/completion.component';
import { KeycloakService } from 'keycloak-angular';
import { initializer } from './keycloak.service';
import { MatCardModule } from '@angular/material/card';
import { ImageCropperModule } from 'ngx-image-cropper';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { HRDocumentsComponent } from './features/documents/hr-documents/hr-documents.component';
import { ReportsComponent } from './features/reports/reports.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CandidatereportsComponent } from './features/candidatereports/candidatereports.component';
import {MatTreeModule} from '@angular/material/tree';

import { BackgroundVerificationComponent } from './features/background-verification/bgv.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BgvConsentComponent } from './features/bgv-consent/bgv-consent.component';

@NgModule({
    declarations: [
        AppComponent,
        CompletionComponent,
        ErrorDialogComponent,
        ReportsComponent,
        CandidatereportsComponent,
        BackgroundVerificationComponent,
        BgvConsentComponent,
       
    ],
    imports: [
        CoreModule,
        AppRoutingModule,
        HttpClientModule,
        MatSelectModule,
        MatDialogModule,
        MatCardModule,
        MatAutocompleteModule,
        BrowserAnimationsModule,
        ImageCropperModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatTableModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        MatTreeModule,
        MatTabsModule,
        MatCheckboxModule

    ],
    exports:[
        MatPaginatorModule,
        ReportsComponent,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        MatTabsModule,
        MatCheckboxModule
    ],
    providers: [Title, { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }, ErrorDialogService, KeycloakService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            deps: [KeycloakService],
            multi: true
        },HRDocumentsComponent],
    bootstrap: [AppComponent]
})
export class AppModule { }
