import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { PreBoardingService } from "src/app/shared/pre-boarding.service";
import { saveAs } from "file-saver";
import { MatTableDataSource } from "@angular/material/table";
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { SERVER_API_URL } from 'src/app/app-constants';
import { DatePipe } from '@angular/common';
import { Router } from "@angular/router";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit, AfterViewInit {
  reportsDataSource = new MatTableDataSource([]);
  originalData: any;
  pageSize=10;
  empSelected=['all'];
  documentSelected=['all'];
  loginSelected="all";
  disableSelect=true;
  disableDocumentStatus=true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  showFirstLastButtons=true;
  searchText: any;
  displayedColumns: string[]=[
    "candidateName",
    "eamilId",
    "mobileNumber",
    "employeeType",
    'documentStatus',
  ];
  datePipe = new DatePipe('en-US');
  public searchFormReports: FormGroup=this.fb.group({
    employeeType: new FormControl(''),
    // employeeType: any;
    loginStatus: new FormControl(''),
    documentationStatus: new FormControl(''),
    fromDate: new FormControl(''),
    toDate: new FormControl(''),
    statusData:new FormControl(''),
    
  });
  reportsLength: any;
  constructor(private preBoardingService: PreBoardingService, private fb: FormBuilder, private route: Router) {}

  ngOnInit(): void {
    this.empSelected=['all'];
    this.documentSelected=['all'];
    this.loginSelected="all";
    this.disableSelect=true;
    this.disableDocumentStatus=true;
    this.reportsDataSource=new MatTableDataSource([]);
    this.advancedSearch('search');
    // $('#mat-select-0-panel').css("min-width",100)
  }

  disabledSelect () {
    if (this.empSelected.includes('all')) {
      this.disableSelect=true;
      this.empSelected=['all'];
    } else if (!(this.empSelected.includes('all'))) {
      this.disableSelect=false;
    }
  }
  ngAfterViewInit() {
    this.reportsDataSource.paginator = this.paginator;
}

  disableDocumentationStatus () {
    if (this.documentSelected.includes('all')) {
      this.disableDocumentStatus=true;
      this.documentSelected=['all'];
    } else if (!(this.documentSelected.includes('all'))) {
      this.disableDocumentStatus=false;
    }
  }
  advancedSearch (option) {
    let empType=this.searchFormReports.get('employeeType').value;
    let loginStatus=this.searchFormReports.get('loginStatus').value;
    let documentationStatus=this.searchFormReports.get('documentationStatus').value;
    let fromDate=this.datePipe.transform(this.searchFormReports.get('fromDate').value,'yyyy-MM-dd');
    let toDate=this.datePipe.transform(this.searchFormReports.get('toDate').value,'yyyy-MM-dd');
    let statusData=this.searchFormReports.get('statusData').value;
    let url;
    if (option==='search') {
      url='/api/candidatesPreboardingReport/filter?';
    } else if (option === 'export') {
      url='/api/candidatesPreboardingReport/filter/export?'
    }
    if (documentationStatus==='') {
      documentationStatus='all';
      url+='documentationStatus='+documentationStatus;
    } else {
      documentationStatus=this.searchFormReports.get('documentationStatus').value;
      if (documentationStatus.length === 0) {
        documentationStatus=null;
      } else {
        documentationStatus=documentationStatus;
      }
      url+='documentationStatus='+documentationStatus;
    }
    if (empType==='') {
      empType='all';
      url+='&employeeType='+empType;
    } else {
      empType=empType;
      url+='&employeeType='+empType
    }
    if (loginStatus==='') {
      loginStatus='all';
      url+='&loginStatus='+loginStatus;
    } else {
      loginStatus=this.searchFormReports.get('loginStatus').value;
      url+='&loginStatus='+loginStatus;
    }
    if (statusData) {
      url+='&data='+statusData;
    }
    if (fromDate) {
      url+='&fromDate='+fromDate;
    }
    if (toDate) {
      url+='&toDate='+toDate;
    }
    if (option==='search') {
      this.preBoardingService.getPBFilter(SERVER_API_URL+url).subscribe(res => {
        this.reportsDataSource=new MatTableDataSource(res);
        this.reportsDataSource.paginator = this.paginator;
        this.reportsLength=this.reportsDataSource.data.length;
      });
    } else if (option==='export') {
      this.preBoardingService.getexport(SERVER_API_URL + url).subscribe((res) => {
        saveAs(res, "Candidates Report.xlsx");
      });
    }
  }

  applyFilter (event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.reportsDataSource.filter = filterValue.trim().toLowerCase();
  }
  
  resett(){
    this.searchFormReports.patchValue({
      employeeType: '',
      documentationStatus:'',
      statusData:'',
      fromDate:'',
      toDate:'',
    });
    this.searchText=null;
    this.ngOnInit();
  }
  redirectToDashboard() {
    this.route.navigate(['dashboard']);
  }
}
