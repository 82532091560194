<!-- <div class="col-sm-12 col-lg-9 col-md-9">
    <h3>LETTER OF AUTHORIZATION AND CONSENT</h3>
</div> -->
<div class="row emp-info-row">
	<div class="col-12 layout_class wrapper_class">
		<h5 class="heading_class text-center"> LETTER OF AUTHORIZATION AND CONSENT</h5>
		<hr class="employee-info-hr">
    </div>
    <div class="col-12 layout_class">
        <!-- <p><mat-checkbox [(ngModel)]="provideConsent" ngDefaultControl><strong> Consent for
            performing Aadhaar and Identity verification</strong></mat-checkbox></p> -->
        <p class="text-justify consent-text">
            I have no objection in sharing my personal information and documents, including but not limited to
Government issued ID such as Aadhaar, Voter ID, PAN card, DL, Name, Gender, Date of Birth,
Addresses, Mobile number, Email, Education record(s) and Employment record(s) with OnGrid (Handy
Online Solutions Private Limited) for the purpose of information and document collection and
management, and background checks and verifications, including but not limited to verification of ID,
Address, Employment, Education, Police/Court record checks, reference checks, etc., in order to avail
employment, benefits and/or services.
<br><br>
I authorize OnGrid to store my information, documents, verification reports and references on their
platform and I understand that OnGrid will ensure security and confidentiality of the same. I understand
that my digital record on OnGrid will be accessible to my employer or service provider for which this
consent is being taken, and my employer or service provider, and their authorized
users/partners/affiliates/contractors can access and update my digital record on the OnGrid platform,
including data, documents, certificates, verification reports, references, testimonials, etc. and can use
related services provided via OnGrid platform. I understand that my digital record on OnGrid will only be
available to any other users of the platform if/when I provide my explicit consent for the same. I
understand that I have the option of editing or deleting my digital record on OnGrid by sending mail
to contact@ongrid.in with the subject "My Data, My Privacy"
        </p>
        <p class="text-center">
            <button *ngIf="!providedConsent" mat-raised-button color="primary" (click)="submitConsent()">Provide Consent</button>
            <span *ngIf="providedConsent"><b>The BGV consent has already been provided.</b></span>
        </p>
    </div>
</div>