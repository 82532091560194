import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error',
  templateUrl: './errordialog.component.html'
})
export class ErrorDialogComponent {
  title = 'Preboarding-Interceptor';
  status: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    if(this.data){
      this.status ='error'
    } else {
      this.status = 'success'
    }
  }

}