import {
	HttpClient,
	HttpEventType,
	HttpResponse
} from '@angular/common/http';
import {
	Component,
	Inject,
	Input,
	OnInit
} from '@angular/core';
import {
	PreBoardingService
} from 'src/app/shared/pre-boarding.service';
import {
	Output,
	EventEmitter
} from '@angular/core';
import {
	Observable
} from 'rxjs';
import { saveAs } from 'file-saver';
import { AuthenticationService } from 'src/app/shared/authentication/authentication.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
	selector: 'app-file-uploader',
	templateUrl: './file-uploader.component.html',
	styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
	@Input() documentType;
	@Input() documentData;
	// @Input() isExportClicked;
	@Input() isDocumentNotSubmitted;
	@Output() docStatusChange = new EventEmitter < boolean > ();
	@Output() filesSent = new EventEmitter<any>();
	fileList: any;
	showSpinner = true;
	multiselectfileSize: any;
	percentDone: number;
	uploadSuccess: boolean;
	imageUrlList: any = [];
	imageUrl: string | ArrayBuffer;
	showModal: boolean;
	imageTodisplay: any;
	candidateID: string;
	imageOpened: any;
	imageStatus: any;
	fName: any;
	fromHR: boolean;
	downloadFromHR: boolean;
	filesSize = 209715200;
	minimmumFileSize: any;
	newFilesize: any;
	downloader: any;
	selectedFiles: FileList;
	currentFile: File;
	progress = 0;
	message = '';
	fileInfos: Observable < any > ;
	displayFileName: string;
	fileSelected: boolean=false;
	filestoUpload: boolean;
	fileData: any=null;
	load: boolean = false;
	private fileToUpload: File = null;
	percentageDone:Number = 0;
	progressDisplay = true;
	@Input() uploadComplete=false;
	documentsSubmittedandApproved: boolean;
	docsSubmittedNotTrue: boolean;
	resubmitDocs: boolean;
	test: any;
	largeFileSize: boolean;
	resSubmitUpload: boolean;
	ri=0;
	ai=0;
	employeeType: any;
	selectedFiles1: FileList;
  	currentFileUpload1: File;
 	progress1: { percentage: number } = { percentage: 0 };
  selectedFile = null;
	personalInformation: any;
	zipFileUrl: any;
	zipFileUrlSize: any;
	constructor(private http: HttpClient, private preBoardingService: PreBoardingService, private authenticationService: AuthenticationService,public dialog: MatDialog) {}
	ngOnInit () {
		this.isDocumentNotSubmitted=true;
		this.largeFileSize=false;
		this.candidateID=sessionStorage.getItem('candidateID');
		this.getStatus();
		this.preBoardingService.getpersonalInfo(this.candidateID).subscribe(res=> {
			this.personalInformation = res.candidateInformation;
			this.zipFileUrl = this.personalInformation.zipFileUrl;
			this.zipFileUrlSize = this.readableBytes(this.personalInformation.zipFileUrlSize);
		});
		this.employeeType=this.preBoardingService.employeeType;
		document.getElementById('error_msg_id').style.display='none';
		// document.getElementById('progress-bar-div').style.display='none';
	}

	readableBytes(bytes) {
		let i = Math.floor(Math.log(bytes)/Math.log(1024));
		let sizes = ['B','KB','MB','GB','TB'];
		let a;
		a = (bytes/Math.pow(1024,i)).toFixed(2);
		return  a * 1 + '' + sizes[i];
	  }
	getStatus () {
		this.load = true,
		this.preBoardingService.retrieveDocumentStatus(this.candidateID).subscribe(response => {
			this.load=false,
				response.forEach(element => {
					if (element.status==='APPROVED') {
						this.ai=this.ai+1;
						// this.documentsSubmittedandApproved=true;
						// this.isDocumentNotSubmitted=false;
					} else if (element.status==='REJECTED') {
						this.isDocumentNotSubmitted=true;
						this.ri=this.ri+1
						this.documentsSubmittedandApproved=false;
						this.resubmitDocs=true;
					} else {
						this.isDocumentNotSubmitted=true;
						this.documentsSubmittedandApproved=false;
					}
				});
			if (this.ai===21) {
				this.documentsSubmittedandApproved=true;
			}
			if (this.ri >=1) {
				this.documentsSubmittedandApproved=false;
			}
		});
	}

	selectFile(event) {
		this.largeFileSize = false;
		this.selectedFiles1 = event.target.files;
		this.displayFileName = this.selectedFiles1.item(0).name;
		if ((this.selectedFiles1.item(0).type === 'application/zip' || this.selectedFiles1.item(0).type === 'application/x-zip-compressed') && this.selectedFiles1.item(0).size <= this.filesSize) {
			const blob = new Blob([this.selectedFiles1.item(0)],{
				type: 'application/octet-stream'
			});
			this.isDocumentNotSubmitted=true;
			this.filestoUpload=true;
			this.uploadComplete=false;
			this.largeFileSize = false;
			// document.getElementById('progress-bar-div').style.display='block';
			document.getElementById('hideFileName').style.display=`block`;
			document.getElementById('hide_fName_class').style.display=`block`;
			document.getElementById('hide_fName_class').style.textAlign=`center`;
			document.getElementById('img_container').style.display='none';
		} else {
			this.largeFileSize = true;
			setTimeout(function(){
				document.getElementById('error_msg_id').style.display='none';
				( < HTMLInputElement > document.getElementById('file-upload-input')).value = '';
			}, 3000);
			this.isDocumentNotSubmitted=true;
			this.filestoUpload = false;
			document.getElementById('hideFileName').style.display='none';
			document.getElementById('choose_file_text').innerText='Choose a zip file to upload';
			document.getElementById('img_container').style.display='block';
			this.uploadComplete=false;
			// this.largeFileSize = false;
		}
		
	}
	
	showSuccessMessage() {
		const dialogRef = this.dialog.open(FileUploadSuccessComponent,{
			width:"400px",
			height:"110px",
		});
	}

	upload() {
		this.load = true;
		let authorities=this.authenticationService.authorities;
		authorities.forEach((element) => {
		if (element==="DASHBOARD_VIEW_CANDIDATE") {
			this.fromHR=false;
		} else if (element==="DASHBOARD_VIEW_HR") {
			this.fromHR=true;
			}
		});
		this.progress1.percentage = 0;
    	this.currentFileUpload1 = this.selectedFiles1.item(0);
		this.test = this.preBoardingService.pushFileToStorage(this.currentFileUpload1,this.candidateID,this.fromHR,false).subscribe(event => {
			this.load = false;
			this.uploadComplete=true;
		 this.showSuccessMessage();
		 if (!this.resSubmitUpload) {
			if (this.employeeType==='FTE') {
				this.preBoardingService.nextCount();
			} else if (this.employeeType==='DC') {
				this.preBoardingService.dircetContractorCount();
			} else if (this.employeeType==='VC') {
				this.preBoardingService.vendorContractorDocumentCount();
			}
		 this.resSubmitUpload=true;
		 document.getElementById('upload-id-btn').style.display='none';
      	}
      	this.selectedFiles1 = undefined;
     	},(err) => {
			if (err.error !== null) {
				this.test.unsubscribe();
				// this.progress1.percentage = 0;
				this.load = false;
				this.isDocumentNotSubmitted = true;
				this.filestoUpload = false;
				this.resSubmitUpload = false;
				// this.uploadComplete = false;
				document.getElementById('hideFileName').style.display = 'none';
				// document.getElementById('progress-bar-div').style.display = 'none';
				document.getElementById('choose_file_text').innerText = 'Choose a zip file to upload';
				document.getElementById('img_container').style.display = 'block';
			}
		}
		);
	}

	
	removeFile (e) {
		e.target.value='';
		this.isDocumentNotSubmitted=true;
		this.filestoUpload=false;
		this.resSubmitUpload=false;
		this.uploadComplete=false;
		this.load = false;
		this.progress1.percentage = 0;
		document.getElementById('hideFileName').style.display='none';
		// document.getElementById('progress-bar-div').style.display = 'none';
		document.getElementById('choose_file_text').innerText='Choose a zip file to upload';
		document.getElementById('img_container').style.display='block';
	}
	
	openModal() {
        const dialogRef = this.dialog.open(DocumentsDialogComponent,{
			width:"500px",
			height:"250px",
			data: this.employeeType
		});
    }

	deleteFile(index) {
		( < HTMLInputElement > document.getElementById('file-upload-input')).value = '';
		// this.currentFile.;
		document.getElementById('img_container').style.display = 'block';
		document.getElementById('btn_container').style.display = 'block';
		document.getElementById('choose_file_text').style.display = 'block';
		document.getElementById('hide_fName_class').style.display = 'none';
		// document.getElementById('progress-bar-div').style.display = 'none';
	}
	downloadZip(fileName){
		let authorities = this.authenticationService.authorities;
    	authorities.forEach((element) => {
      		if (element === "DASHBOARD_VIEW_CANDIDATE") {
				this.downloadFromHR = false;
      		} else if (element === "DASHBOARD_VIEW_HR"){
				  this.downloadFromHR = true;
			  }
    	});
		this.preBoardingService.downloadZip(this.candidateID, this.downloadFromHR,false).subscribe(res=>{
			const blob = new Blob([res], {
				type: 'application/zip'
			});
			const data = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = data;
            link.download = fileName;
			link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
		})
	}

}
@Component({
    selector: 'documents-dialog',
    templateUrl: 'documents-dialog.component.html',
    styleUrls: ['./file-uploader.component.scss'],
})
export class DocumentsDialogComponent implements OnInit{
    constructor(public dialogRef: MatDialogRef<DocumentsDialogComponent>,public preBoardingService: PreBoardingService, public _router: Router ,@Inject(MAT_DIALOG_DATA) public data: any,) { }
	candidateID: any;
	apiCall: any;
	fromHr = false;
	ngOnInit(){
		this.candidateID = sessionStorage.getItem('candidateID');
	}
    closeDialog() {
        this.dialogRef.close();
    }
	finish(){
		this.apiCall = this.preBoardingService.documentSubmissionMail(this.candidateID,this.fromHr,false).subscribe(res=> {
			this.closeDialog();
			if (this.data === 'FTE') {
				this._router.navigate(['bgv-consent']);
			} else {
				this._router.navigate(['complete']);
			}
		});
	}
}

@Component ({
	selector: 'file-upload-success',
	templateUrl: 'file-upload-success.component.html',
	styleUrls : ['./file-uploader.component.scss'],
})
export class FileUploadSuccessComponent implements OnInit {
	candidateID: string;
	constructor(public dialogRef: MatDialogRef<FileUploadSuccessComponent>,public preBoardingService: PreBoardingService, public _router: Router ,@Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit(){
		this.candidateID = sessionStorage.getItem('candidateID');
	}
	closeDialog() {
		this.dialogRef.close();
	}
}