<h6 mat-dialog-title><b> Delete Candidate</b></h6>
<hr>
<div class="row">
    <div class="col-sm-12 col-md-12">
        <p style="font-size: 14px;">
            Are you sure you want to delete <b style="text-transform: capitalize;">{{data.name}}?</b>
        </p>
    </div>
</div>
<div mat-dialog-actions class="buttons-position">
    <button mat-raised-button class="cancel-button-css" (click)="closeDialogBox()">Cancel</button>
    <button mat-raised-button class="update-button-css" (click)="deleteCandidate()">Delete</button>
</div>
