import { Component, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { PreBoardingService } from "src/app/shared/pre-boarding.service";
import { DatePipe } from '@angular/common';
import { AuthenticationService } from "src/app/shared/authentication/authentication.service";
import * as moment from "moment";
import { Observable } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import $ from "jquery";
@Component({
  selector: "app-bgv",
  templateUrl: "./bgv.component.html",
  styleUrls: ["./bgv.component.scss"],
})
export class BackgroundVerificationComponent implements OnInit {
  selectedIndex: number = 0;
  registrationBGVForm: FormGroup;
  educationalVerificationForm: FormGroup;
  employmentVerificationForm: FormGroup;
  ccrvForm: FormGroup;
  creditCheckForm: FormGroup;
  addressVerificationForm: FormGroup;
  @ViewChild('myForm') mytemplateForm: NgForm;
  @ViewChild('eduForm') educationForm: NgForm;
  @ViewChild('addForm') addressForm: NgForm;
  maxDob1: any;
  hasConsent: boolean;
  consentText: any;
  ccrvState: any;
  files: FileList;
  resumeLengthFlag = false;
  resumeSizeFlag = false;
  educationRequestPayload: any = {};
  generatedEducationalID: any;
  newRegister: boolean = true;
  alreadyRegister = false;
  datePipe = new DatePipe('en-US');
  disabled: boolean = false;
  multipleSalary: boolean = true;
  accept: string;
  addressState: any;
  displaySalaryList: any[] = [];
  expandedCols: boolean[] = [];
  multipleSalaryFiles = [];
  multipleSalaryFilesLength: any;
  professionList = [
    { id: '1', value: 'Security Guard' },
    { id: '4', value: 'Office Boy' },
    { id: '16', value: 'Accountant' },
    { id: '17', value: 'Admin Executive' },
    { id: '18', value: 'Admin Supervisor' },
    { id: '49', value: 'Marketing Executive' },
    { id: '50', value: 'Marketing Supervisor' },
    { id: '55', value: 'Office Assisstant/Executive' },
    { id: '60', value: 'Pantry Personnel - Office' },
    { id: '61', value: 'Receptionist / Front-desk executive' },
    { id: '73', value: 'Software Engineer' },
    { id: '76', value: 'Housekeeping Attendant' },
    { id: '78', value: 'Admin Manager' },
    { id: '79', value: 'Audit Professional' },
    { id: '89', value: 'IT/ITES Executive' },
    { id: '90', value: 'IT/ITES Manager' },
    { id: '93', value: 'Project Manager' },
    { id: '97', value: 'Designer' },
    { id: '98', value: 'Data Analyst' },
    { id: '99', value: 'Engineer' },
    { id: '69', value: 'Other' },

  ];
  workExDisplayedColumns: string[] = [
    "srNo",
    "nameAsPerEmployerRecords",
    "employerName",
    "documents",
    "state",
    "options"
  ];
  educationDisplayedColumns: string[] = [
    'srNo',
    'nameAsPerDocument',
    'level',
    'files',
    'state',
    'options'
  ];
  displayOtherField: boolean;
  candidateID: string;
  getRegistrationDetails: any;
  maxIssueDate: Date;
  individualID: number;
  showEduDocId: boolean;
  educaationDocID: any;
  eduServingUrl: any;
  edufileName: any;
  eduState: any;
  qualifications: any = [];
  workExperience: any;
  employerNameList: any[];
  candidateName: any;
  saveData: any;
  jobFilesSize: number = 10485760;
  displayJobFileName: string;
  jobToUpload: File;
  CCRVRequestID: any;
  panDocumentID: any;
  getCCAddressID: any;
  ccReqID: any;
  ccState: any;
  workExpCount: any;
  removeEmployer: FormArray;
  sortedExpTable: any;
  salarySlipData: string;
  salarySlipName = [];
  salarySlipList = [];
  salaryData = [];
  appointmentLetterData: string;
  expLetterData: string;
  appointmentLetterName = [];
  expLetterName = [];
  appointmentData = [];
  experienceData = [];
  salaryDocType: any;
  jobFileSelected: boolean;
  jobsToUpload: boolean;
  fileData: string;
  eduPayload: {};
  eduLoad: boolean;
  empLoad: boolean;
  registerLoad: boolean = false;
  educationPayload = {};
  selectedFiles: FileList;
  progressInfos = [];
  message = '';
  fileInfos: Observable<any>;
  panID: any;
  apptjobToUpload: any;
  apptjobFileSelected: boolean;
  displayApptJobFileName: string;
  expjobToUpload: File;
  expjobFileSelected: boolean;
  displayexpJobFileName: string;
  salaryToUpload: File;
  displaySalaryFileName: string;
  workExDataSource = new MatTableDataSource([]);
  educationDataSource = new MatTableDataSource([]);
  isUpdateEmployment: boolean = false;
  overallStatus: any;
  ccrvStatus: any;
  ccStatus: any;
  eduStatus: any;
  PAVStatus: any;
  empStatus: any;
  totalStatus: any;
  candidateEducationDetailsList: any = [];
  displayEducationList: any[] = [];
  multipleEdcationFiles: any[] = [];
  multipleEducationFilesLength: number;
  displayEducationFileName: any;
  educationToUpload: File;
  isUpdateEducational: boolean = false;
  HRView: boolean = false;
  candidateView: boolean = false;
  showRegistrationID: boolean = false;
  currentAddress: any;
  permanentAddress: any;
  panNumber: any;
  lavAddressID: any;
  currentAddressState: any;
  permanentAddressID: any;
  isPAVTrue: boolean = false;
  isPAPVTrue: boolean = false;
  permanentAddressState: any;
  overallPAPVStatus: any;
  overallLAVStatus: any;
  overallPADVStatus: any;
  showverificationMode: boolean = false;
  showAddress: boolean;
  getPAVReqID: any;
  getPADVReqID: any;
  getPAPVReqID: any;
  PAVState: any;
  PADVState: any;
  PAPVState: any;
  LAVState: any;
  LAPVState: any;
  LADVState: any;
  showInsufficiencyFormFields: boolean;
  communityInsufficiencyDTO = [];
  updateEduInsufficientStatus: any;
  dataInsufficient: boolean;
  educationInsufficiencyForm: FormGroup;
  eduRequestID: any;
  eduInsufficiencyId: any;
  employmentInsufficiencyForm: FormGroup;
  EmpDataInsufficient: boolean;
  empRequestID: any;
  empInsufficiencyId: any;
  showEmpInsufficiencyFormFields: boolean;
  updateEmpInsufficientStatus: any;
  ccrvInsufficiencyForm: FormGroup;
  showCCRVInsufficiencyFormFields: boolean;
  ccrvInsufficiencyDTO: any;
  ccrvInsufficiencyId: any;
  ccInsufficiencyForm: FormGroup;
  ccInsufficiencyDTO: any;
  ccInsufficiencyId: any;
  CCRequestID: any;
  showCCInsufficiencyFormFields: boolean;
  addressInsufficiencyForm: FormGroup;
  getLAVRequestID: any;
  addressInsufficiencyDTO: any;
  addressInsufficiencyId: any;
  addressRequestID: any;
  PAddressInsufficiencyDTO: any;
  PAddressInsufficiencyId: any;
  PAddressRequestID: any;
  PAddressInsufficiencyForm: FormGroup;
  showcAddressInsufficiencyFields: boolean;
  showPAddressInsufficiencyFields: boolean;
  verificationMode: any;
  ccrvLoad: boolean;
  ccLoad: boolean;
  addressLoad: boolean;
  testDesg: any;
  pdfUrl: any;
  showCAVerificationMode: boolean = false;
  getLAPVRequestID: number;
  getLADVRequestID: number;
  isLAVTrue: boolean;
  overallLAPVStatus: any;
  overallLADVStatus: any;
  constructor(private fb: FormBuilder, private preboardingService: PreBoardingService, public authenticationService: AuthenticationService, public dialog: MatDialog,) {
    this.registrationBGVForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      dob: new FormControl(''),
      email: new FormControl('', [Validators.email, Validators.required]),
      employeeID: new FormControl(''),
      fathersName: new FormControl('',[Validators.required]),
      gender: new FormControl(''),
      id: new FormControl(''),
      joiningDate: new FormControl(''),
      currentAddress: new FormControl('', [Validators.required]),
      permanentAddress: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      workingCity: new FormControl('', [Validators.required]),
      professionId: new FormControl('', [Validators.required]),
      otherProfession: new FormControl(''),
      hasConsentFlag: new FormControl('', [Validators.requiredTrue]),
      hasConsentText: new FormControl(''),
      panNumber: new FormControl('', [Validators.required,
      Validators.pattern(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/),
      Validators.maxLength(10),
      Validators.minLength(10),]),
      aadharNumber: new FormControl('', [Validators.required, Validators.pattern(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/)])
    });
  }
  ngOnInit(): void {
    this.candidateID = sessionStorage.getItem('candidateID');
    this.maxDob1 = new Date();
    this.maxIssueDate = new Date();
    this.maxDob1.setFullYear(this.maxDob1.getFullYear() - 18);
    this.getCandidateRegistrationDetails();
    this.intialiseEducationalForm();
    this.initialiseEmploymentForm();
    this.initialiseCCRVForm();
    this.initialiseCCForm();
    this.intialiseAddressVerification();
    let authorities = this.authenticationService.authorities;
    if (authorities) {
      authorities.forEach(element => {
        if (element === "DASHBOARD_VIEW_CANDIDATE") {
          this.candidateView = true;
        } else if (element === 'DASHBOARD_VIEW_HR') {
          this.HRView = true;
        }
      })
    }
  }

  /* General Methods */

  omit_special_char(event) {
    var k;
    k = event.charCode;
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }

  lettersOnly(event) {
    var charCode = event.keyCode;
    if (
      (charCode > 64 && charCode < 91) ||
      (charCode > 96 && charCode < 123) ||
      charCode == 8 ||
      charCode === 32
    )
      return true;
    else return false;
  }

  validateNumber(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  moveToNextTab() {
    window.scrollTo(0, 0);
    this.selectedIndex++;
  }

  tabChanged(event) {
    if (event.tab.textLabel === 'Registration') {
      this.getCandidateRegistrationDetails();
    } else if (event.tab.textLabel === 'Education') {
      this.getEducationalDocs();
    } else if (event.tab.textLabel === 'Employment') {
      this.getEmploymentDocs();
    } else if (event.tab.textLabel === 'Additional Verification') {
      this.getCCRVDetails();
      this.getCreditCheckDetails();
      this.getAddressDetails();
      this.resetAddress();
      if (this.ccrvState && this.ccState && (this.LAVState || this.LAPVState || this.LADVState) && (this.PADVState || this.PAPVState || this.PAVState)) {
        $(".mat-tab-labels div[aria-posinset='4']").attr(
          "style",
          "background-color: #6CB543 !important; color: white !important; opacity:1 !important"
        );
        $(".mat-tab-labels div[aria-posinset='4']").addClass("some-active-class");
      }
    } else if (event.tab.textLabel === 'Overall Status') {
      this.getAllStatuses();
      if (this.totalStatus === 'Success') {
        $(".mat-tab-labels div[aria-posinset='5']").attr(
          "style",
          "background-color: #6CB543 !important; color: white !important; opacity:1 !important"
        );
        $(".mat-tab-labels div[aria-posinset='5']").addClass("some-active-class");
      }
    }
  }

  /* On-grid Registration tab methods */
  getCandidateRegistrationDetails() {

    this.preboardingService.getCandidateDetailsForRegistration(this.candidateID).subscribe(res => {
      this.getRegistrationDetails = res;
      this.candidateName = res.name;
      this.panDocumentID = res.panDocumentId;
      this.registrationBGVForm.patchValue({
        name: res.name,
        dob: res.dob,
        email: res.email,
        gender: res.gender,
        phone: res.phone,
        fathersName: res.fathersName ? res.fathersName : null,
        employeeID: res.employeeId ? res.employeeId : null,
        joiningDate: res.joiningDate,
        workingCity: res.city,
        currentAddress: res.currentAddress,
        permanentAddress: res.permanentAddress.fullAddress,
        aadharNumber: res.uid,
        hasConsentFlag: res.hasConsent,
        id: res.id,
        panNumber: res.panDocumentId ? res.panDocumentId : null,
      });
      this.individualID = res.id;
      if (res.id !== null) {
        this.alreadyRegister = true;
        this.newRegister = false;
        this.registrationBGVForm.get('hasConsentFlag').setValue(true);
        $(".mat-tab-labels div[aria-posinset='1']").attr(
          "style",
          "background-color: #6CB543 !important; color: white !important; opacity:1 !important"
        );
        $(".mat-tab-labels div[aria-posinset='1']").addClass("some-active-class");
      } else {
        this.newRegister = true;
        this.alreadyRegister = false;
      }
      this.consentText = res.consentText;
      // this.hasConsent = res.hasConsent;
      const otherProfession = this.professionList.find(c => c.id === '69');
      if (res.professionId === null) {
        this.registrationBGVForm.get('professionId').setValue(otherProfession.id);
        this.displayOtherField = true;
        this.registrationBGVForm.get('otherProfession').setValidators(Validators.required);
        this.registrationBGVForm.get('otherProfession').updateValueAndValidity();
      } else if (res.professionId !== null && res.professionId !== '69') {
        this.displayOtherField = false;
        this.registrationBGVForm.get('professionId').setValue(res.professionId);
        this.registrationBGVForm.get('otherProfession').setValue(res.otherProfession);
        this.registrationBGVForm.get('otherProfession').clearValidators();
        this.registrationBGVForm.get('otherProfession').updateValueAndValidity();
      } else if (res.professionId !== null && res.professionId === '69') {
        this.displayOtherField = true;
        this.registrationBGVForm.get('professionId').setValue(res.professionId);
        this.registrationBGVForm.get('otherProfession').setValue(res.otherProfession);
        this.registrationBGVForm.get('otherProfession').setValidators(Validators.required);
        this.registrationBGVForm.get('otherProfession').updateValueAndValidity();
      }
    });
  }

  registerCandidate() {
    let payload = {};
    this.registerLoad = true;
    payload = {
      name: this.registrationBGVForm.get('name').value,
      dob: this.registrationBGVForm.get('dob').value,
      gender: this.registrationBGVForm.get('gender').value,
      email: this.registrationBGVForm.get('email').value,
      phone: this.registrationBGVForm.get('phone').value,
      fathersName: this.registrationBGVForm.get('fathersName').value,
      employeeId: this.registrationBGVForm.get('employeeID').value,
      joiningDate: this.registrationBGVForm.get('joiningDate').value,
      uid: this.registrationBGVForm.get('aadharNumber').value,
      professionId: this.registrationBGVForm.get('professionId').value,
      otherProfession: this.registrationBGVForm.get('otherProfession').value,
      city: this.registrationBGVForm.get('workingCity').value,
      currentAddress: this.registrationBGVForm.get('currentAddress').value,
      permanentAddress: { fullAddress: this.registrationBGVForm.get('currentAddress').value },
      hasConsent: true,
      consentText: this.consentText,
      id: this.registrationBGVForm.get('id').value,
      otherIdentifiers: { pan: this.registrationBGVForm.get('panNumber').value }
    }
    this.preboardingService.registerCandidateToOngrid(payload, this.candidateID).subscribe(res => {
      this.getCandidateRegistrationDetails();
      this.registerLoad = false;
      this.newRegister = false;
      this.alreadyRegister = true;
      if (this.HRView) {
        this.selectedIndex++;
      } else if (this.candidateView) {
        this.showRegistrationID = true;
      }
    }, (err => {
      this.registerLoad = false;
    }));
  }

  professionSelected(event) {
    if (event.value === '69') {
      this.displayOtherField = true;
      this.registrationBGVForm.get('otherProfession').setValidators(Validators.required);
      this.registrationBGVForm.get('otherProfession').updateValueAndValidity();
    } else {
      this.displayOtherField = false;
      this.registrationBGVForm.get('otherProfession').clearValidators();
      this.registrationBGVForm.get('otherProfession').updateValueAndValidity();
    }
  }


  /* Education Verification Methods */
  intialiseEducationalForm() {
    this.educationalVerificationForm = this.fb.group({
      nameOfInstitute: new FormControl('', [Validators.required]),
      nameAsPerDocument: new FormControl('', [Validators.required]),
      issueDate: new FormControl('', [Validators.required]),
      registrationNumber: new FormControl('', [Validators.required]),
      level: new FormControl('', [Validators.required]),
      state: new FormControl(''),
      //  documentType: new FormControl(''),
      documentId: new FormControl(''),
      id: new FormControl(''),
      fileName: new FormControl(''),
      fileInput: new FormControl(''),
      servingUrl: new FormControl(''),
      requestId: new FormControl(''),
    });

    this.educationInsufficiencyForm = this.fb.group({
      insufficiencyReason: new FormControl(''),
      insufficiencyRemarks: new FormControl(''),
      insufficiencyStatus: new FormControl(''),
      insufficiencyId: new FormControl(''),
    });
  }

  statusSelected(event,formType) {
    if (formType === 'education') {
      if (event.value === 'Resolved') {
        this.educationInsufficiencyForm.get('insufficiencyReason').setValue(null);
        this.educationInsufficiencyForm.get('insufficiencyReason').disable();
      } else if (event.value === 'Closed') {
        this.educationInsufficiencyForm.get('insufficiencyReason').setValue('');
        this.educationInsufficiencyForm.get('insufficiencyReason').enable();
      }
    } else if (formType === 'employment') {
      if (event.value === 'Resolved') {
        this.employmentInsufficiencyForm.get('insufficiencyReason').setValue(null);
        this.employmentInsufficiencyForm.get('insufficiencyReason').disable();
      } else if (event.value === 'Closed') {
        this.employmentInsufficiencyForm.get('insufficiencyReason').setValue('');
        this.employmentInsufficiencyForm.get('insufficiencyReason').enable();
      }
    } else if (formType === 'ccrv') {
      if (event.value === 'Resolved') {
        this.ccrvInsufficiencyForm.get('insufficiencyReason').setValue(null);
        this.ccrvInsufficiencyForm.get('insufficiencyReason').disable();
      } else if (event.value === 'Closed') {
        this.ccrvInsufficiencyForm.get('insufficiencyReason').setValue('');
        this.ccrvInsufficiencyForm.get('insufficiencyReason').enable();
      }
    } else if (formType === 'cc') {
      if (event.value === 'Resolved') {
        this.ccInsufficiencyForm.get('insufficiencyReason').setValue(null);
        this.ccInsufficiencyForm.get('insufficiencyReason').disable();
      } else if (event.value === 'Closed') {
        this.ccInsufficiencyForm.get('insufficiencyReason').setValue('');
        this.ccInsufficiencyForm.get('insufficiencyReason').enable();
      }
    } else if (formType === 'caddresss') {
      if (event.value === 'Resolved') {
        this.addressInsufficiencyForm.get('insufficiencyReason').setValue(null);
        this.addressInsufficiencyForm.get('insufficiencyReason').disable();
      } else if (event.value === 'Closed') {
        this.addressInsufficiencyForm.get('insufficiencyReason').setValue('');
        this.addressInsufficiencyForm.get('insufficiencyReason').enable();
      }
    } else if (formType === 'pAddress') {
      if (event.value === 'Resolved') {
        this.PAddressInsufficiencyForm.get('insufficiencyReason').setValue(null);
        this.PAddressInsufficiencyForm.get('insufficiencyReason').disable();
      } else if (event.value === 'Closed') {
        this.PAddressInsufficiencyForm.get('insufficiencyReason').setValue('');
        this.PAddressInsufficiencyForm.get('insufficiencyReason').enable();
      }
    }
  }

  handleFileInput(files: FileList) {
    let educationList = [];
    educationList = this.displayEducationList;
    this.educationToUpload = files.item(0);
    this.jobFileSelected = true;
    this.displayEducationFileName = this.educationToUpload.name;
    educationList.push(this.displayEducationFileName);
    this.multipleEdcationFiles.push(this.educationToUpload);
    this.multipleEducationFilesLength = this.multipleEdcationFiles.length;
    this.displayEducationList = educationList;
    this.jobsToUpload = true;
    /*  Do not remove console log, used for testing purpose */
    console.log('Document Type to be uploaded- ',this.educationToUpload);
    /*  Do not remove console log, used for testing purpose */
    if ((this.educationToUpload.type === 'application/pdf') || (this.educationToUpload.type === 'image/jpg') || (this.educationToUpload.type === 'image/jpeg') && this.educationToUpload.size <= this.jobFilesSize) {
      const blob = new Blob([this.educationToUpload], {
        type: 'application/octet-stream'
      });
      this.fileData = window.URL.createObjectURL(blob);
    } else {
      this.displayEducationList = [];
      this.multipleEdcationFiles = [];
    }
    // }

    // if ((this.selectedFiles1.item(0).type === 'application/zip' || this.selectedFiles1.item(0).type === 'application/x-zip-compressed') && this.selectedFiles1.item(0).size <= this.filesSize) {}
  }

  removeResume(i?) {
    this.displayJobFileName = undefined;
  }

  educationSelected(event) {
    this.qualifications.forEach(loop=> {
      if (event.value === 'TENTH_STD' && loop.qualification === 'High School (10th)' || event.value === 'GRADUATE' && loop.qualification === "Bachelor's Degree" || event.value === 'MASTERS' && loop.qualification === "PG Degree/Diploma" || event.value === 'TWELFTH_STD' && loop.qualification === "Pre University (+2)" || event.value === 'OTHER' && loop.qualification === 'other') {
        this.educationalVerificationForm.get('nameOfInstitute').setValue(loop.university);
      } else if (event.value ==='DIPLOMA' || event.value ==='PHD' || event.value ==='POST_DOC' || event.value ==='POST_GRADUATE_DIPLOMA' || event.value ==='PROFESSIONAL_COURSE') {
        this.educationalVerificationForm.get('nameOfInstitute').setValue('');
      }
    })
  }
  getEducationalDocs() {
    this.preboardingService.getQualificationInfo(this.candidateID).subscribe(res => {
      this.qualifications = res;
    });
    this.preboardingService.getEducationVerificationStatus(this.individualID, this.candidateID).subscribe(response => {});
    this.preboardingService.getCandidateEduDetails(this.candidateID).subscribe(res => {
      this.candidateEducationDetailsList = res.candidateEducationDetailsList;
      if (this.candidateEducationDetailsList.length !==0) {
        $(".mat-tab-labels div[aria-posinset='2']").attr(
          "style",
          "background-color: #6CB543 !important; color: white !important; opacity:1 !important"
        );
        $(".mat-tab-labels div[aria-posinset='2']").addClass("some-active-class");
      }
      this.educationDataSource = new MatTableDataSource(res.candidateEducationDetailsList);
      this.educationalVerificationForm.get('nameAsPerDocument').setValue(this.registrationBGVForm.get('name').value);
    });
  }

  removeEduDocs(i) {
    this.multipleEdcationFiles.splice(i, 1);
    this.displayEducationList.splice(i, 1);
  }

  resetEducationalData() {
    this.educationalVerificationForm.reset();
    this.educationForm.resetForm();
    this.displayEducationFileName = undefined;
    this.multipleEdcationFiles = [];
    this.displayEducationList = [];
    this.isUpdateEducational = false;
    this.showInsufficiencyFormFields = false;
    this.getEducationalDocs();
  }

  updateEduData(row) {
    this.isUpdateEducational = true;
    this.educationalVerificationForm.get('nameAsPerDocument').setValue(row.nameAsPerDocument);
    this.educationalVerificationForm.get('registrationNumber').setValue(row.registrationNumber);
    this.educationalVerificationForm.get('nameOfInstitute').setValue(row.nameOfInstitute);
    this.educationalVerificationForm.get('issueDate').setValue(this.datePipe.transform(row.issueDate, 'yyyy-MM-dd'));
    this.educationalVerificationForm.get('level').setValue(row.level);
    this.educationalVerificationForm.get('documentId').setValue(row.id);
    this.educationalVerificationForm.get('state').setValue(row.state);
    this.educationalVerificationForm.get('requestId').setValue(row.requestId);
    this.eduRequestID = row.requestId;
    if (row.state === "DataInsufficient") {
      this.dataInsufficient = true;
    } else {
      this.dataInsufficient = false;
    }
  }

  saveEducationalDocuments() {
    this.eduPayload = {}
    this.eduLoad = true;
    this.eduPayload['id'] = this.educationalVerificationForm.get('documentId').value ? this.educationalVerificationForm.get('documentId').value : 0,
      this.eduPayload['issueDate'] = this.datePipe.transform(this.educationalVerificationForm.get('issueDate').value, 'yyyy-MM-dd');
    this.eduPayload['level'] = this.educationalVerificationForm.get('level').value;
    this.eduPayload['nameAsPerDocument'] = this.educationalVerificationForm.get('nameAsPerDocument').value;
    this.eduPayload['nameOfInstitute'] = this.educationalVerificationForm.get('nameOfInstitute').value;
    this.eduPayload['registrationNumber'] = this.educationalVerificationForm.get('registrationNumber').value;
    this.eduPayload['file'] = this.multipleEdcationFiles;
    this.eduPayload['state'] = this.educationalVerificationForm.get('state').value ? this.educationalVerificationForm.get('state').value : null;
    if (this.isUpdateEducational === false) {
      let uploadEdu = this.preboardingService.uploadEduDoctoOngrid(this.individualID, this.eduPayload, this.candidateID).subscribe(res => {
        this.generatedEducationalID = res.id;
        let eduPayload = {
          educationDocumentId: this.generatedEducationalID,
        }
        let reqEduCall = this.preboardingService.requestEducationVerification(this.individualID, eduPayload, this.candidateID).subscribe(res => {
          this.eduLoad = false;
          this.resetEducationalData();
          this.getEducationalDocs();
        }, (err => {
          console.log('err', err);
          this.eduLoad = false;
          this.resetEducationalData();
          this.getEducationalDocs();
          reqEduCall.unsubscribe();
        }));
      }, (error => {
        this.eduLoad = false;
        this.resetEducationalData();
        this.getEducationalDocs();
        uploadEdu.unsubscribe();
      }));
    } else if (this.isUpdateEducational === true) {
      let uploadEducation = this.preboardingService.updateEducationalDetails(this.candidateID, this.individualID, this.eduPayload).subscribe(result => {
        this.updateEduInsufficientStatus = result;
        let educationID = result.id;
        let ePayLoad = {
          educationDocumentId: this.educationalVerificationForm.get('documentId').value ? this.educationalVerificationForm.get('documentId').value : 0,
        };
        if (this.dataInsufficient) {
          this.educationalVerificationForm.reset();
          this.educationForm.resetForm();
          this.displayEducationFileName = undefined;
          this.multipleEdcationFiles = [];
          this.displayEducationList = [];
          this.eduLoad = false;
          this.showInsufficiencyFormFields = true;
          this.preboardingService.getInsufficiencies(Number(this.candidateID), this.individualID, this.eduRequestID).subscribe(response => {
            this.communityInsufficiencyDTO = response.communityInsufficiencyDTO;
            this.communityInsufficiencyDTO.forEach(loop=> {
              loop.insufficiencyList.forEach(innerLoop=> {
                this.eduInsufficiencyId = innerLoop.insufficiencyId;
                this.educationInsufficiencyForm.get('insufficiencyId').setValue(innerLoop.insufficiencyId);
              });
            });
          });
        } 
        if (!this.dataInsufficient) {
          this.showInsufficiencyFormFields = false;
          let verifyCall = this.preboardingService.requestEducationVerification(this.individualID, ePayLoad, this.candidateID).subscribe(results => {
            this.eduLoad = false;
            this.resetEducationalData();
            this.getEducationalDocs();
          }, (err => {
            this.eduLoad = false;
            this.isUpdateEducational = false;
            this.resetEducationalData();
            this.getEducationalDocs();
            verifyCall.unsubscribe();
          }));
        }

      }, (err => {
        this.eduLoad = false;
        this.isUpdateEducational = false;
        this.resetEducationalData();
        this.getEducationalDocs();
        uploadEducation.unsubscribe();
      }));
    }
  }

  updateInsufficiencies(verificationType) {
    let payload = {};
    if (verificationType === 'education') {
      payload = 
      {
        "insufficiencyList": [
          {
            "insufficiencies": [
              {
                "insufficiencyId": this.educationInsufficiencyForm.get('insufficiencyId').value,
                "reason": this.educationInsufficiencyForm.get('insufficiencyReason').value,
                "remarks": this.educationInsufficiencyForm.get('insufficiencyRemarks').value,
                "status": this.educationInsufficiencyForm.get('insufficiencyStatus').value
              }
            ],
            "requestId": this.updateEduInsufficientStatus.id,
          }
        ]
      };
    } else if (verificationType === 'employment') {
      payload = 
      {
        "insufficiencyList": [
          {
            "insufficiencies": [
              {
                "insufficiencyId": this.employmentInsufficiencyForm.get('insufficiencyId').value,
                "reason": this.employmentInsufficiencyForm.get('insufficiencyReason').value,
                "remarks": this.employmentInsufficiencyForm.get('insufficiencyRemarks').value,
                "status": this.employmentInsufficiencyForm.get('insufficiencyStatus').value
              }
            ],
            "requestId": this.updateEmpInsufficientStatus,
          }
        ]
      };
    } else if (verificationType === 'ccrv') {
      payload = 
      {
        "insufficiencyList": [
          {
            "insufficiencies": [
              {
                "insufficiencyId": this.ccrvInsufficiencyForm.get('insufficiencyId').value,
                "reason": this.ccrvInsufficiencyForm.get('insufficiencyReason').value,
                "remarks": this.ccrvInsufficiencyForm.get('insufficiencyRemarks').value,
                "status": this.ccrvInsufficiencyForm.get('insufficiencyStatus').value
              }
            ],
            "requestId": this.CCRVRequestID,
          }
        ]
      };
    } else if (verificationType === 'cc') {
      payload = 
      {
        "insufficiencyList": [
          {
            "insufficiencies": [
              {
                "insufficiencyId": this.ccInsufficiencyForm.get('insufficiencyId').value,
                "reason": this.ccInsufficiencyForm.get('insufficiencyReason').value,
                "remarks": this.ccInsufficiencyForm.get('insufficiencyRemarks').value,
                "status": this.ccInsufficiencyForm.get('insufficiencyStatus').value
              }
            ],
            "requestId": this.CCRequestID,
          }
        ]
      };
    } else if (verificationType === 'lav') {
      payload = 
      {
        "insufficiencyList": [
          {
            "insufficiencies": [
              {
                "insufficiencyId": this.addressInsufficiencyForm.get('insufficiencyId').value,
                "reason": this.addressInsufficiencyForm.get('insufficiencyReason').value,
                "remarks": this.addressInsufficiencyForm.get('insufficiencyRemarks').value,
                "status": this.addressInsufficiencyForm.get('insufficiencyStatus').value
              }
            ],
            "requestId": this.addressRequestID,
          }
        ]
      };
    } else if (verificationType === 'pav' || verificationType === 'papv' || verificationType === 'padv') {
      payload = 
      {
        "insufficiencyList": [
          {
            "insufficiencies": [
              {
                "insufficiencyId": this.PAddressInsufficiencyForm.get('insufficiencyId').value,
                "reason": this.PAddressInsufficiencyForm.get('insufficiencyReason').value,
                "remarks": this.PAddressInsufficiencyForm.get('insufficiencyRemarks').value,
                "status": this.PAddressInsufficiencyForm.get('insufficiencyStatus').value
              }
            ],
            "requestId": this.PAddressRequestID,
          }
        ]
      };
    }
      this.preboardingService.updateInsufficiency(this.individualID,payload).subscribe(result=> {
        if (verificationType === 'education') {
          this.resetEducationalData();
        } else if (verificationType === 'employment') {
          this.resetEmploymentData();
        } else if (verificationType === 'ccrv') {
          this.showCCRVInsufficiencyFormFields = false;
        } else if (verificationType === 'cc') {
          this.showCCInsufficiencyFormFields = false;
        } else if (verificationType === 'lav') {
          this.showcAddressInsufficiencyFields = false;
        } else if (verificationType === 'pav' || verificationType === 'papv' || verificationType === 'padv') {
          this.showPAddressInsufficiencyFields = false;
        }
      });
  }

  /*  Employment Verification Methods */

  initialiseEmploymentForm() {
    this.employmentVerificationForm = new FormGroup({
      nameAsPerEmployerRecords: new FormControl('', [Validators.required]),
      employerName: new FormControl('', [Validators.required]),
      designation: new FormControl('', [Validators.required]),
      joiningDate: new FormControl('', [Validators.required]),
      lastWorkingDate: new FormControl('', [Validators.required]),
      employeeId: new FormControl(''),
      employmentRecordId: new FormControl('',),
      servingUrl: new FormControl(''),
      documentType: new FormControl(''),
      id: new FormControl(''),
      salaryDetails: new FormControl(''),
      appointmentDetails: new FormControl(''),
      salaryList: new FormControl(''),
    });
    this.employmentInsufficiencyForm = this.fb.group({
      insufficiencyReason: new FormControl(''),
      insufficiencyRemarks: new FormControl(''),
      insufficiencyStatus: new FormControl(''),
      insufficiencyId: new FormControl(''),
    })
  }

  getEmploymentDocs() {
    this.employerNameList = [];
    this.workExDataSource = new MatTableDataSource([]);
    this.preboardingService.getWorkEx(this.candidateID).subscribe(res => {
      this.workExperience = res;
      this.workExpCount = res.length;
      if (this.workExpCount !== 0) {
        this.sortedExpTable = res.sort((a, b) => {
          return moment.utc(b.dateOfJoining).diff(moment.utc(a.dateOfJoining));
        });
      }
      res.forEach(loop => {
        this.employerNameList.push(loop.employerNameAndLocation);
      });
      this.employmentVerificationForm.get('nameAsPerEmployerRecords').setValue(this.candidateName);
    });
    this.preboardingService.getEmployment(this.candidateID).subscribe(res => {
      this.workExDataSource = new MatTableDataSource(res.employmentRecords);
      if (this.workExDataSource.data.length !== 0) {
        $(".mat-tab-labels div[aria-posinset='3']").attr(
          "style",
          "background-color: #6CB543 !important; color: white !important; opacity:1 !important"
        );
        $(".mat-tab-labels div[aria-posinset='3']").addClass("some-active-class");
      }
    });

    this.preboardingService.getEmploymentVerificationStatus(this.individualID,this.candidateID).subscribe(response=> {});

  }
  employerSelected(event) {
    this.sortedExpTable.forEach(loop => {
      if (event.value === loop.employerNameAndLocation) {
        this.employmentVerificationForm.get('designation').setValue(loop.designationHeld);
        this.employmentVerificationForm.get('joiningDate').setValue(loop.dateOfJoining);
        this.employmentVerificationForm.get('lastWorkingDate').setValue(loop.dateOfLeaving);
      }
    });
  }

  handleSalaryDetails(files: FileList) {
    let salaryList = [];
    salaryList = this.displaySalaryList;
    if (this.displaySalaryList.length === 3) {
      this.displaySalaryList = [];
      this.multipleSalaryFiles = [];
      this.displaySalaryFileName = undefined;
    } else if (this.displaySalaryList.length < 3) {
      this.salaryToUpload = files.item(0);
      this.jobFileSelected = true;
      this.displaySalaryFileName = this.salaryToUpload.name;
      salaryList.push(this.displaySalaryFileName);
      this.multipleSalaryFiles.push(this.salaryToUpload);
      this.multipleSalaryFilesLength = this.multipleSalaryFiles.length;
      this.displaySalaryList = salaryList;
      this.jobsToUpload = true;
      if ((this.salaryToUpload.type === 'application/pdf') || (this.salaryToUpload.type === 'image/jpeg') || (this.salaryToUpload.type === 'image/jpg') && this.salaryToUpload.size <= this.jobFilesSize) {
        const blob = new Blob([this.salaryToUpload], {
          type: 'application/octet-stream'
        });
        this.fileData = window.URL.createObjectURL(blob);
      } else {
        this.displaySalaryFileName = undefined;
        this.displaySalaryList = [];
        this.multipleSalaryFiles = [];
      }
    }
  }

  attachExperienceLetter(files: FileList) {
    this.expjobToUpload = files.item(0);
    this.expjobFileSelected = true;
    this.displayexpJobFileName = this.expjobToUpload.name;
    this.jobsToUpload = true;
    if ((this.expjobToUpload.type === 'application/pdf') || (this.expjobToUpload.type === 'image/jpeg') || (this.expjobToUpload.type === 'image/jpg') && this.expjobToUpload.size <= this.jobFilesSize) {
      const blob = new Blob([this.expjobToUpload], {
        type: 'application/octet-stream'
      });
      this.fileData = window.URL.createObjectURL(blob);
    } else {
      this.displayexpJobFileName = undefined;
    }
  }

  attachAppointmentLetter(files: FileList) {
    this.apptjobToUpload = files.item(0);
    this.apptjobFileSelected = true;
    this.displayApptJobFileName = this.apptjobToUpload.name;
    this.jobsToUpload = true;
    if ((this.apptjobToUpload.type === 'application/pdf') || (this.apptjobToUpload.type === 'image/jpeg') || (this.apptjobToUpload.type === 'image/jpg') && this.apptjobToUpload.size <= this.jobFilesSize) {
      const blob = new Blob([this.apptjobToUpload], {
        type: 'application/octet-stream'
      });
      this.fileData = window.URL.createObjectURL(blob);
    } else {
      this.displayApptJobFileName = undefined;
      this.apptjobFileSelected = false;
    }
  }

  removeAttachedFiles() {
    this.displayexpJobFileName = undefined;
  }

  removeSalarySlip(i) {
    this.multipleSalaryFiles.splice(i, 1);
    this.displaySalaryList.splice(i, 1); 
  }

  removeAppointmentLetter() {
    this.displayApptJobFileName = undefined;
  }

  resetEmploymentData() {
    this.employmentVerificationForm.reset();
    this.mytemplateForm.resetForm();
    this.isUpdateEmployment = false;
    this.showEmpInsufficiencyFormFields = false;
    this.employmentVerificationForm.get('nameAsPerEmployerRecords').setValue(this.candidateName);
    this.displayApptJobFileName = undefined;
    this.displayexpJobFileName = undefined;
    this.displaySalaryFileName = undefined;
    this.apptjobToUpload = undefined;
    this.expjobToUpload = undefined;
    this.salaryToUpload = undefined;
    this.multipleSalaryFiles = [];
    this.displaySalaryList = [];
  }

  updateEmpData(row) {
    this.isUpdateEmployment = true;
    this.employmentVerificationForm.get('nameAsPerEmployerRecords').setValue(row.nameAsPerEmployerRecords);
    this.employmentVerificationForm.get('employerName').setValue(row.employerName);
    this.employmentVerificationForm.get('joiningDate').setValue(this.datePipe.transform(row.joiningDate, 'yyyy-MM-dd'));
    this.employmentVerificationForm.get('lastWorkingDate').setValue(this.datePipe.transform(row.lastWorkingDate, 'yyyy-MM-dd'));
    this.employmentVerificationForm.get('designation').setValue(row.lastDesignation);
    this.employmentVerificationForm.get('employmentRecordId').setValue(row.employmentRecordId);
    this.empRequestID = row.requestId;
    if (row.state === "DataInsufficient") {
      this.EmpDataInsufficient = true;
    } else {
      this.EmpDataInsufficient = false;
    }
  }


  postEmploymentData() {
    let payload = {}
    this.empLoad = true;
    let expPayload, apptPayload, salaryPayload;
    let employmentRecID = this.employmentVerificationForm.get('employmentRecordId').value;
    payload['employeeId'] = this.employmentVerificationForm.get('employeeId').value;
    payload['employerName'] = this.employmentVerificationForm.get('employerName').value,
      payload['nameAsPerEmployerRecords'] = this.employmentVerificationForm.get('nameAsPerEmployerRecords').value;
    payload['individualId'] = this.individualID;
    payload['joiningDate'] = this.employmentVerificationForm.get('joiningDate').value;
    payload['lastWorkingDate'] = this.employmentVerificationForm.get('lastWorkingDate').value;
    payload['lastDesignation'] = this.employmentVerificationForm.get('designation').value;
    payload['employmentRecordId'] = employmentRecID ? employmentRecID : '';
    if (this.expjobToUpload !== undefined) {
      expPayload = {
        documentType: 'experienceletter',
        fileName: this.displayexpJobFileName,
        data: this.expjobToUpload
      };
      payload['experienceletter'] = this.expjobToUpload;
    } else {
      payload['experienceletter'] = '';
    }
    if (this.apptjobToUpload !== undefined) {
      apptPayload = {
        documentType: 'appointmentletter',
        fileName: this.displayApptJobFileName,
        data: this.apptjobToUpload
      }
      payload['appointmentletter'] = this.apptjobToUpload;
    } else {
      payload['appointmentletter'] = '';
    }
    if (this.salaryToUpload !== undefined) {
      salaryPayload = {
        documentType: 'salaryslip',
        fileName: this.displaySalaryList,
        data: this.multipleSalaryFiles
      }
      payload['salaryslip'] = this.multipleSalaryFiles;
    } else {
      payload['salaryslip'] = '';
    }
    if (this.isUpdateEmployment === false) {
      let uploadCall = this.preboardingService.uploadEmploymentDocs(this.individualID, this.candidateID, payload).subscribe(res => {
        let empRecordId;
        res.employmentRecords.forEach(loop => {
          empRecordId = loop.employmentRecordId
        });
        const verificationPayload = [{
          employmentRecordId: empRecordId
        }];
        let reqCall = this.preboardingService.requestEmploymentVerification(this.individualID, this.candidateID, verificationPayload).subscribe(result => {
          this.empLoad = false;
          this.resetEmploymentData();
          this.getEmploymentDocs();
        }, (err => {
          this.empLoad = false;
          reqCall.unsubscribe();
        }))
      }, (error => {
        this.empLoad = false;
        this.resetEmploymentData();
        this.getEmploymentDocs();
        uploadCall.unsubscribe();
      }));
    } else if (this.isUpdateEmployment === true) {
      let updateCall = this.preboardingService.updateEmploymentDocument(this.individualID, this.candidateID, payload).subscribe(res => {
        this.resetEmploymentData();
        this.getEmploymentDocs();
        this.empLoad = false;
        this.isUpdateEmployment = false;
        if (this.EmpDataInsufficient) {
          this.employmentVerificationForm.reset();
          this.mytemplateForm.reset();
          this.displayApptJobFileName = undefined;
          this.displayexpJobFileName = undefined;
          this.displaySalaryFileName = undefined;
          this.apptjobToUpload = undefined;
          this.expjobToUpload = undefined;
          this.salaryToUpload = undefined;
          this.multipleSalaryFiles = [];
          this.displaySalaryList = [];
          this.showEmpInsufficiencyFormFields = true;
          this.preboardingService.getInsufficiencies(Number(this.candidateID), this.individualID, this.empRequestID).subscribe(response => {
            this.communityInsufficiencyDTO = response.communityInsufficiencyDTO;
            this.communityInsufficiencyDTO.forEach(loop=> {
              loop.insufficiencyList.forEach(innerLoop=> {
                this.empInsufficiencyId = innerLoop.insufficiencyId;
                this.updateEmpInsufficientStatus = innerLoop.requestId;
                this.employmentInsufficiencyForm.get('insufficiencyId').setValue(innerLoop.insufficiencyId);
              });
            });
          });
        }
      }, (err => {
        this.empLoad = false;
        this.resetEmploymentData();
        this.getEmploymentDocs();
        updateCall.unsubscribe();
      }));
    }
  }


  /*  CCRV Implementation  */
  initialiseCCRVForm() {
    this.ccrvForm = this.fb.group({
      permanentAddress: new FormControl('', [Validators.required]),
      currentAddress: new FormControl('', [Validators.required]),
      fathersName: new FormControl('', [Validators.required]),
      panNumber: new FormControl('', [Validators.required]),
      requestId: new FormControl('',),
      state: new FormControl(''),
      reportResult: new FormControl(''),
      resultReason: new FormControl(''),
      remarks: new FormControl(''),
      id: new FormControl(''),
    });
    this.ccrvInsufficiencyForm = this.fb.group({
      insufficiencyReason: new FormControl(''),
      insufficiencyRemarks: new FormControl(''),
      insufficiencyStatus: new FormControl(''),
      insufficiencyId: new FormControl(''),
    });
  }

  getCCRVDetails() {
    this.panNumber = this.getRegistrationDetails.panDocumentId;
    this.ccrvForm.get('panNumber').setValue(this.getRegistrationDetails.panDocumentId);
    // if (this.ccrvState !== undefined)
    this.preboardingService.getCCRVDetails(this.individualID, this.candidateID).subscribe(res => {
      if (res) {
        this.CCRVRequestID = res.requestId;
        this.ccrvState = res.state;
        if (res.state === 'DataInsufficient') {
          this.showCCRVInsufficiencyFormFields = true;
          this.preboardingService.getInsufficiencies(this.candidateID,this.individualID,res.requestId).subscribe(response=> {
            this.ccrvInsufficiencyDTO = response.communityInsufficiencyDTO;
                this.ccrvInsufficiencyDTO.forEach(loop=> {
                  loop.insufficiencyList.forEach(innerLoop=> {
                    this.ccrvInsufficiencyId = innerLoop.insufficiencyId;
                    this.CCRVRequestID = innerLoop.requestId;
                    this.ccrvInsufficiencyForm.get('insufficiencyId').setValue(innerLoop.insufficiencyId);
                  });
                });
          });
        }
      }
    });
  }

  initiateCCRV() {
    const payload = {
      individualId: this.individualID,
    }
    this.ccrvLoad = true;
    this.preboardingService.requestCCRV(this.candidateID, this.individualID, payload).subscribe(res => {
      this.ccrvState = res.state;
      let ccrvCall = this.preboardingService.getInsufficiencies(this.candidateID,this.individualID,res.requestId).subscribe(response=> {
        this.ccrvLoad = false;
      }, (err => {
        console.log('ccrv error',err);
        this.ccrvLoad = false;
        ccrvCall.unsubscribe();
      }));
      this.getCCRVDetails();
    });
  }

  /* Credit Check Implementation  */

  initialiseCCForm() {
    this.creditCheckForm = this.fb.group({
      permanentAddress: new FormControl('', [Validators.required]),
      panDocumentId: new FormControl('', [Validators.required]),
      panNumber: new FormControl(''),
      requestId: new FormControl('',),
      state: new FormControl(''),
      ccReportResult: new FormControl(''),
      resultReason: new FormControl(''),
      closedRemarks: new FormControl(''),
      closedReason: new FormControl(''),
    });
    this.ccInsufficiencyForm = this.fb.group({
      insufficiencyReason: new FormControl(''),
      insufficiencyRemarks: new FormControl(''),
      insufficiencyStatus: new FormControl(''),
      insufficiencyId: new FormControl(''),
    });
  }

  initiateCCProcess() {
    const payload = {
      addressId: this.getCCAddressID,
      individualId: this.individualID,
      panDocumentId: this.panID,
    }
    this.ccLoad = true;
    let ccCall = this.preboardingService.postCreditCheck(this.candidateID, this.individualID, payload).subscribe(res => {
      this.ccLoad = false;
      this.getCreditCheckDetails();
    },(err=> {
      console.log('CC error',err);
      this.ccLoad = false;
      ccCall.unsubscribe();
    }));
  }

  getCreditCheckDetails() {
    this.creditCheckForm.get('panNumber').setValue(this.getRegistrationDetails.panDocumentId);
    this.preboardingService.getCandidateDetailsCC(this.candidateID).subscribe(res => {
      this.getCCAddressID = res.addressId;
      this.ccReqID = res.requestId;
      this.panID = res.panDocumentId;
      if (res.requestId !== null) {
        this.getCCverificationStatus();
      } else {
        console.log('NULL');
      }
    });
    // this.getAllStatuses();
  }
  getCCverificationStatus() {
    this.preboardingService.getCCVerificationStatus(this.candidateID, this.individualID).subscribe(res => {
      this.ccState = res.state;
      if (res.state === 'DataInsufficient') {
        this.preboardingService.getInsufficiencies(this.candidateID,this.individualID,this.ccReqID).subscribe(response=> {
          this.ccInsufficiencyDTO = response.communityInsufficiencyDTO;
              this.ccInsufficiencyDTO.forEach(loop=> {
                loop.insufficiencyList.forEach(innerLoop=> {
                  this.ccInsufficiencyId = innerLoop.insufficiencyId;
                  this.CCRequestID = innerLoop.requestId;
                  this.ccInsufficiencyForm.get('insufficiencyId').setValue(innerLoop.insufficiencyId);
                });
              });
        });
      }
    });
  }

  /* Address Verification */
  intialiseAddressVerification() {
    this.addressVerificationForm = this.fb.group({
      addressType: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      addressId: new FormControl(''),
      addressMode: new FormControl(''),
    });
    this.addressInsufficiencyForm = this.fb.group({
      insufficiencyReason: new FormControl(''),
      insufficiencyRemarks: new FormControl(''),
      insufficiencyStatus: new FormControl(''),
      insufficiencyId: new FormControl(''),
    });
    this.PAddressInsufficiencyForm = this.fb.group({
      insufficiencyReason: new FormControl(''),
      insufficiencyRemarks: new FormControl(''),
      insufficiencyStatus: new FormControl(''),
      insufficiencyId: new FormControl(''),
    });
  }

  addressVerificationSelected(event) {
    if (event.value === 'lav') {
      this.showverificationMode = false;
      this.showCAVerificationMode = true;
      this.showAddress = false;
    } else if (event.value === 'pav') {
      this.showverificationMode = true;
      this.showAddress = false;
      this.showCAVerificationMode = false;
    }
  }

  verificationModeSelected(event,addressType) {
    if (addressType === 'permanent') {
      this.verificationMode = event.value;
      if (event.value) {
        this.showAddress = true;
        this.addressVerificationForm.get('address').setValue(this.permanentAddress);
      }
    } else if (addressType === 'current') {
      this.verificationMode = event.value;
      if (event.value) {
        this.showAddress = true;
        this.addressVerificationForm.get('address').setValue(this.currentAddress);
      }
    }
  }

  getVerificationStatus() {
    this.preboardingService.getAddressVerificationStatus(this.individualID, 'lav').subscribe(response => {
      this.getLAVRequestID = response.requestId;
      this.LAVState = response.state ? response.state : '';
      if (this.LAVState === 'DataInsufficient' && this.getLAVRequestID !== 0) {
        this.showcAddressInsufficiencyFields = true;
        this.preboardingService.getInsufficiencies(this.candidateID,this.individualID,this.getLAVRequestID).subscribe(ds=> {
          this.addressInsufficiencyDTO = ds.communityInsufficiencyDTO;
          this.addressInsufficiencyDTO.forEach(loop=> {
            loop.insufficiencyList.forEach(innerLoop=> {
              this.addressInsufficiencyId = innerLoop.insufficiencyId;
              this.addressRequestID = innerLoop.requestId;
              this.addressInsufficiencyForm.get('insufficiencyId').setValue(innerLoop.insufficiencyId);
              });
            });
        });
      }
    });
    this.preboardingService.getAddressVerificationStatus(this.individualID, 'lapv').subscribe(response => {
      this.getLAVRequestID = response.requestId;
      this.LAPVState = response.state ? response.state : '';
      if (this.LAPVState === 'DataInsufficient' && this.getLAPVRequestID !== 0) {
        this.showcAddressInsufficiencyFields = true;
        this.preboardingService.getInsufficiencies(this.candidateID,this.individualID,this.getLAPVRequestID).subscribe(ds=> {
          this.addressInsufficiencyDTO = ds.communityInsufficiencyDTO;
          this.addressInsufficiencyDTO.forEach(loop=> {
            loop.insufficiencyList.forEach(innerLoop=> {
              this.addressInsufficiencyId = innerLoop.insufficiencyId;
              this.addressRequestID = innerLoop.requestId;
              this.addressInsufficiencyForm.get('insufficiencyId').setValue(innerLoop.insufficiencyId);
              });
            });
        });
      }
    });
    this.preboardingService.getAddressVerificationStatus(this.individualID, 'ladv').subscribe(response => {
      this.getLAVRequestID = response.requestId;
      this.LADVState = response.state ? response.state : '';
      if (this.LADVState === 'DataInsufficient' && this.getLADVRequestID !== 0) {
        this.showcAddressInsufficiencyFields = true;
        this.preboardingService.getInsufficiencies(this.candidateID,this.individualID,this.getLADVRequestID).subscribe(ds=> {
          this.addressInsufficiencyDTO = ds.communityInsufficiencyDTO;
          this.addressInsufficiencyDTO.forEach(loop=> {
            loop.insufficiencyList.forEach(innerLoop=> {
              this.addressInsufficiencyId = innerLoop.insufficiencyId;
              this.addressRequestID = innerLoop.requestId;
              this.addressInsufficiencyForm.get('insufficiencyId').setValue(innerLoop.insufficiencyId);
              });
            });
        });
      }
    });
    this.preboardingService.getAddressVerificationStatus(this.individualID, 'pav').subscribe(response => {
      this.getPAVReqID = response.requestId;
      this.PAVState = response.state ? response.state : '';
      if (this.PAVState === 'DataInsufficient' && this.getPAVReqID !== 0) {
        this.preboardingService.getInsufficiencies(this.candidateID,this.individualID,this.getPAVReqID).subscribe(ds=> {
          this.PAddressInsufficiencyDTO = ds.communityInsufficiencyDTO;
          this.PAddressInsufficiencyDTO.forEach(loop=> {
            loop.insufficiencyList.forEach(innerLoop=> {
              this.PAddressInsufficiencyId = innerLoop.insufficiencyId;
              this.PAddressRequestID = innerLoop.requestId;
              this.PAddressInsufficiencyForm.get('insufficiencyId').setValue(innerLoop.insufficiencyId);
              });
            });
        });
      }
    });
    this.preboardingService.getAddressVerificationStatus(this.individualID, 'padv').subscribe(response => {
      this.getPADVReqID = response.requestId;
      this.PADVState = response.state ? response.state : '';
      if (this.PAVState === 'DataInsufficient' && this.getPADVReqID !== 0) {
        this.preboardingService.getInsufficiencies(this.candidateID,this.individualID,this.getPADVReqID).subscribe(ds=> {
          this.PAddressInsufficiencyDTO = ds.communityInsufficiencyDTO;
          this.PAddressInsufficiencyDTO.forEach(loop=> {
            loop.insufficiencyList.forEach(innerLoop=> {
              this.PAddressInsufficiencyId = innerLoop.insufficiencyId;
              this.PAddressRequestID = innerLoop.requestId;
              this.PAddressInsufficiencyForm.get('insufficiencyId').setValue(innerLoop.insufficiencyId);
              });
            });
        });
      }
    });
    this.preboardingService.getAddressVerificationStatus(this.individualID, 'papv').subscribe(response => {
      this.getPAPVReqID = response.requestId;
      this.PAPVState = response.state ? response.state : '';
      if (this.PAVState === 'DataInsufficient' && this.getPAPVReqID !== 0) {
        this.preboardingService.getInsufficiencies(this.candidateID,this.individualID,this.getPAPVReqID).subscribe(ds=> {
          this.PAddressInsufficiencyDTO = ds.communityInsufficiencyDTO;
          this.PAddressInsufficiencyDTO.forEach(loop=> {
            loop.insufficiencyList.forEach(innerLoop=> {
              this.PAddressInsufficiencyId = innerLoop.insufficiencyId;
              this.PAddressRequestID = innerLoop.requestId;
              this.PAddressInsufficiencyForm.get('insufficiencyId').setValue(innerLoop.insufficiencyId);
              });
            });
        });
      }
    });
  }

  resetAddress() {
    this.addressVerificationForm.reset();
    this.addressForm.resetForm();
    this.isPAVTrue = false;
    this.isPAPVTrue = false;
    this.showverificationMode = false;
    this.showCAVerificationMode = false;
    this.showAddress = false;
  }

  initiateAddressVerification() {
    if ((this.getLAVRequestID === 0 && this.getLAPVRequestID === 0 && this.getLADVRequestID === 0) || (this.getPADVReqID === 0 && this.getPAPVReqID === 0 && this.getPAVReqID === 0)){
      const payload = {
        addressId: null,
        individualId: this.individualID,
        verificationType: this.addressVerificationForm.get('addressMode').value
      };
      if (payload.verificationType === 'lav' || payload.verificationType === 'lapv' || payload.verificationType === 'ladv') {
        payload.addressId = Number(this.lavAddressID);
        this.isPAVTrue = false;
        this.isLAVTrue = true;
      } else if (payload.verificationType === 'pav'|| payload.verificationType === 'papv' || payload.verificationType === 'padv') {
        payload.addressId = Number(this.permanentAddressID);
        this.isPAVTrue = true;
        this.isLAVTrue = false;
      }
     let addressCall = this.preboardingService.initiateAddressverification(this.individualID, this.candidateID, payload).subscribe(res => {
        this.addressLoad = false;
        this.resetAddress();
        this.getAddressDetails();
      },(err=> {
        console.log('Address error',err);
        this.addressLoad = false;
        addressCall.unsubscribe();
      }));
    } else {
      this.addressLoad = false;
    }
  }

  getAddressDetails() {
    this.currentAddress = this.getRegistrationDetails.currentAddress;
    this.permanentAddress = this.getRegistrationDetails.permanentAddress.fullAddress;
    this.preboardingService.getAddressDetailsforVerification(this.candidateID).subscribe(result => {
      result.candidateAddressDetails.forEach(loop => {
        if (loop.addressType === 'CA') {
          this.lavAddressID = loop.addressId,
            this.currentAddressState = loop.state
        } else if (loop.addressType === 'PA') {
          this.permanentAddressID = loop.addressId
          this.permanentAddressState = loop.state
        }
      })
    });
    this.getVerificationStatus();
  }

  /* Overall Status */
  getAllStatuses() {
    this.preboardingService.getOverallBGVStatus(this.candidateID, this.individualID).subscribe(res => {
      this.overallStatus = res;
      this.ccrvStatus = res.overallCCRVStatus.replace(/([a-z])([A-Z])/g, "$1 $2");
      this.ccStatus = res.overallCCStatus.replace(/([a-z])([A-Z])/g, "$1 $2");
      this.eduStatus = res.overallEDUVStatus.replace(/([a-z])([A-Z])/g, "$1 $2");
      this.empStatus = res.overallEMPVStatus.replace(/([a-z])([A-Z])/g, "$1 $2");
      this.overallLAVStatus = res.overallLAVStatus.replace(/([a-z])([A-Z])/g, "$1 $2");
      this.overallLAPVStatus = res.overallLAPVStatus.replace(/([a-z])([A-Z])/g, "$1 $2");
      this.overallLADVStatus = res.overallLADVStatus.replace(/([a-z])([A-Z])/g, "$1 $2")
      this.PAVStatus = res.overallPAVStatus.replace(/([a-z])([A-Z])/g, "$1 $2");
      this.overallPAPVStatus = res.overallPAPVStatus.replace(/([a-z])([A-Z])/g, "$1 $2");
      this.overallPADVStatus = res.overallPADVStatus.replace(/([a-z])([A-Z])/g, "$1 $2");
      this.totalStatus = res.overallStatus.replace(/([a-z])([A-Z])/g, "$1 $2");
    });
    this.preboardingService.downloadCandidateIndividualReport(this.individualID).subscribe(response=>{
      this.pdfUrl = response.servingUrl;
    });
  }
}

@Component({
  selector: 'address-verification-dialog',
  template: `
  <div class="row">
      <div class="col-sm-12 col-md-12">
      Address Verification already initiated!
      </div>
      <div class="col-sm-12 col-md-12">
          <button mat-button class="add-button-css float_right" (click)="closeDialog()">Close</button>
      </div>
  </div>
  `,
  styleUrls: ["./bgv.component.scss"],
})
export class AddressVerificationDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<AddressVerificationDialogComponent>) { }
  
  closeDialog() {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    
  }
}