import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EditProfilePictureComponent, MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DashboardComponent } from '../features/dashboard/dashboard.component';





@NgModule({
    declarations: [
        MainLayoutComponent,
        FooterComponent,
        EditProfilePictureComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        SharedModule,
        MatStepperModule,
        MatProgressBarModule,
        MatToolbarModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSidenavModule,
        MatListModule,
        // MatIconModule,
        ImageCropperModule

    ],
    exports: [
        MatToolbarModule,
        MatButtonModule,
        MatFormFieldModule,
        MainLayoutComponent,
        MatSidenavModule,
        MatStepperModule,
        MatProgressBarModule,
        // MatIconModule
    ],
    entryComponents: [EditProfilePictureComponent],
    providers:[DashboardComponent],
})
export class CoreModule { }
