<div>
    <div>
        <p [ngClass]='[status]'>
            Reason: {{data.reason}}
        </p>
        <!-- <p [ngClass]='[status]'>
            Status Code: {{data.status}}
        </p> -->
    </div>
</div>
