<div class="row top-class">
  <div class="col-12 base-col-12">
    <div class="init-icons row" style="margin-left: 23px;">
      <div class="col-12">
        <mat-form-field appearance="outline" class="search-bar-class">
          <img src="../../../assets/baseline_search_black_24dp.png" class="search_icon" matPrefix>
          <input type="text" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" (keyup)="applyFilter($event)" placeholder="Search Candidate(eg. type Candidate Name, Email-ID etc.)" matInput>
        </mat-form-field>
      </div>
    </div>
    <form [formGroup]="searchFormReports">
      <div class="row row-fields">
        <div class="col-sm-12 col-lg-4 col-md-6">
          <p class="label_class">Employee Type</p>
          <mat-form-field appearance="outline" class="form-field-width-class">
            <mat-select formControlName="employeeType" panelClass="employeetype-select" [(ngModel)]="empSelected" multiple
              (selectionChange)="disabledSelect()" disableOptionCentering>
              <mat-option value="all">All</mat-option>
              <mat-option value="FTE"[disabled]="disableSelect">Full Time Employee</mat-option>
              <mat-option value="DC"[disabled]="disableSelect">Direct Contractor</mat-option>
              <mat-option value="VC"[disabled]="disableSelect">Vendor Contractor</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6">
          <p class="label_class">Login Status</p>
          <mat-form-field appearance="outline" class="form-field-width-class">
            <mat-select formControlName="loginStatus" [(ngModel)]="loginSelected">
              <mat-option value="all">All</mat-option>
              <mat-option value="true">True</mat-option>
              <mat-option value="false">False</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6">
          <p class="label_class">Documentation Status</p>
          <mat-form-field appearance="outline" class="form-field-width-class">
            <mat-select formControlName="documentationStatus" panelClass="employeetype-select" [(ngModel)]="documentSelected" multiple
              (selectionChange)="disableDocumentationStatus()" disableOptionCentering>
              <mat-option value="all">All</mat-option>
              <mat-option value="SUBMITTED" [disabled]="disableDocumentStatus">Submitted</mat-option>
              <mat-option value="REJECTED" [disabled]="disableDocumentStatus">Rejected</mat-option>
              <mat-option value="APPROVED" [disabled]="disableDocumentStatus">Approved</mat-option>
              <mat-option value="APPROVEDINCONDTION" [disabled]="disableDocumentStatus">Conditionally Approved
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row row-fields">
        <div class="col-sm-12 col-lg-4 col-md-6">
          <p class="label_class">Event Date</p>
          <mat-form-field appearance="outline" class="form-field-width-class">
            <mat-select formControlName="statusData">
              <!-- <mat-option value="all">All</mat-option> -->
              <mat-option value="dateOfJoining">Date of Joining</mat-option>
              <mat-option value="docsSubmittedDate">Document Submitted Date</mat-option>
              <mat-option value="docsVerificationStatusDate">Verification status date</mat-option>
              <mat-option value="accountCreatedDate">Account Creation Date</mat-option>
              <!-- <mat-option value="APPROVEDINCONDTION">Conditionally Approved</mat-option> -->
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6">
          <p class="label_class">From Date</p>
          <mat-form-field appearance="outline" class="form-field-width-class">
            <input matInput formControlName="fromDate" [matDatepicker]="frompicker" readonly="true">
            <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
            <mat-datepicker #frompicker></mat-datepicker>
          </mat-form-field>

        </div>
        <div class="col-sm-12 col-lg-4 col-md-6">
          <p class="label_class">To Date</p>
          <mat-form-field appearance="outline" class="form-field-width-class">
            <input matInput formControlName="toDate" [matDatepicker]="topicker" readonly="true">
            <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
            <mat-datepicker #topicker></mat-datepicker>
          </mat-form-field>

        </div>
      </div>
    </form>
    <div class="row float-row">
      <div class="col-12 init-icons">
          <button mat-button class="export_btn search_btn" (click)="advancedSearch('search')"> <img
              src="../../../assets/outline_search_white_24dp.png" matPrefix>Search</button>
          <button mat-button class="export_btn reset_btn" (click)="resett()"> <img
              src="../../../assets/baseline_restart_alt_white_24dp.png" matPrefix>Reset</button>
          <button mat-button class="export_btn export_resource_btn" (click)="advancedSearch('export')"> <img
              src="../../../assets/outline_file_download_white_24dp.png" matPrefix>Export Resource List</button>
          <button mat-button class="export_btn reset_btn" (click)="redirectToDashboard()">Dashboard</button>
      </div>
    </div>
    <div class="row table-row">
      <div class="col-12">
        <table mat-table [dataSource]="reportsDataSource" class="mat-elevation-z8 reports-table">

          <!-- Candidate Name Column -->
          <ng-container matColumnDef="candidateName">
            <th class="reports-color" mat-header-cell *matHeaderCellDef>Candidate Name</th>
            <td mat-cell *matCellDef="let element" class="candidate-name-class">
              <span class="mobile-label">Candidate Name : </span>
               {{element.candidateName}} </td>
          </ng-container>

          <!-- Email ID Column -->
          <ng-container matColumnDef="eamilId">
            <th class="reports-color" mat-header-cell *matHeaderCellDef> Email ID </th>
            <td mat-cell *matCellDef="let element"> <span class="mobile-label">Email ID : </span> {{element.eamilId}} </td>
          </ng-container>

          <!-- Mobile no Column -->
          <ng-container matColumnDef="mobileNumber">
            <th class="reports-color" mat-header-cell *matHeaderCellDef> Mobile Number </th>
            <td mat-cell *matCellDef="let element"> <span class="mobile-label">Mobile Number : </span> {{element.mobileNumber}} </td>
          </ng-container>

          <!-- employee type Column -->
          <ng-container matColumnDef="employeeType">
            <th class="reports-color" mat-header-cell *matHeaderCellDef> Employee Type </th>
            <td mat-cell *matCellDef="let element"> <span class="mobile-label">Employee Type : </span>
              {{element.employeeType}} </td>
          </ng-container>

           <!-- Document status Column -->
           <ng-container matColumnDef="documentStatus">
            <th class="reports-color" mat-header-cell *matHeaderCellDef> Document Status </th>
            <td mat-cell *matCellDef="let element">
              <span class="mobile-label">Document Status : </span>
              <span *ngIf="documentStatus==='null'">N/A</span>
              <span *ngIf="documentStatus!==null">{{element.documentStatus}}</span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <mat-paginator class="paginator-custom-class" [length]="reportsLength" [pageSizeOptions]="[5, 10, 25, 100]"
          [showFirstLastButtons]="showFirstLastButtons">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
