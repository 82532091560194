import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/authentication/authentication.service';

@Directive({
  selector: '[appHasAuthority]'
})
export class HasAuthorityDirective {
  private authorities: string[];

  constructor(private authenticationService: AuthenticationService, private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef)  { }
  @Input()
  set appHasAuthority(value: string | string[]) {
    this.authorities = typeof value === 'string' ? [value] : value;
    this.updateView();
    // Get notified each time authentication state changes.
    // this.principal.getAuthenticationState().subscribe(identity => this.updateView());
  }
  private updateView(): void {
    this.authenticationService.hasAnyAuthority(this.authorities).then(result => {
      this.viewContainerRef.clear();
      if (result) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    });
  }
}
