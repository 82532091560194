import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PreBoardingService } from 'src/app/shared/pre-boarding.service';

@Component({
  selector: 'app-bgv-consent',
  templateUrl: './bgv-consent.component.html',
  styleUrls: ['./bgv-consent.component.scss']
})
export class BgvConsentComponent implements OnInit {
  providedConsent: boolean = false;
  consentText: any;
  registrationDetails: any;
  constructor(public _router: Router,private preBoardingService: PreBoardingService) { }
  candidateID: any;
  ngOnInit(): void {
    this.candidateID = sessionStorage.getItem("candidateID");
    this.preBoardingService.getConsent(this.candidateID).subscribe(response=> {
      this.providedConsent = response;
    });
  }

  submitConsent() {
    this.preBoardingService.bgvInitiationConsent(this.candidateID,true).subscribe(res=> {
      this.ngOnInit();
       setTimeout(()=> {
        this._router.navigate(['complete']);
       },3000);
    });
  }

}
