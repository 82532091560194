import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoDblClick]'
})
export class NoDblClickDirective {
  
  constructor() { }
  
  @HostListener('click', ['$event'])
  clickEvent(event) {
    console.log('click event from directive',event);
    event.srcElement.parentElement.setAttribute('disabled', true);
    setTimeout(function(){
      event.srcElement.parentElement.removeAttribute('disabled');
    }, 10000);
  }

}