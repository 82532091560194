<h6 mat-dialog-title class="update-details-heading">Update Details<span><img src="../../../assets/icon-close.png"
			class="close-button" (click)="onNoClick()"></span></h6>
<form [formGroup]="updateDetailsForm">
	<div class="row">
		<div class="col-lg-6">
			<p class="placeholder_para">Full Name*</p>
			<mat-form-field appearance="outline" class="form_field_width">
				<input matInput (keypress)="lettersOnly($event)" maxlength="250" required formControlName="fullName"
					[readonly]="isemailreadonly">
				<mat-error *ngIf="updateDetailsForm.get('fullName').hasError('required')">This is a required field
				</mat-error>
			</mat-form-field>
			<br>
		</div>
		<div class="col-lg-6">
			<p class="placeholder_para">Expected Date of Joining*</p>
			<mat-form-field appearance="outline" class="form_field_width">
				<input formControlName="expectedDOJ" matInput
					[matDatepicker]="expectedJoiningDate" readonly="true" required>
				<mat-datepicker-toggle matSuffix [for]="expectedJoiningDate">
				</mat-datepicker-toggle>
				<mat-datepicker startView="year" #expectedJoiningDate></mat-datepicker>
				<mat-error *ngIf="updateDetailsForm.get('expectedDOJ').hasError('required')">
					This is a required field</mat-error>
			</mat-form-field>
			<br>
		</div>
	</div>

	<div class="row">
		<div class="col-lg-6">
			<p class="placeholder_para">Contact Number</p>
			<mat-form-field appearance="outline" class="form_field_width">
				<input matInput required formControlName="contactNumber" matInput (keypress)="validateNumber($event)"
					pattern="([0-9]+)" minlength="10" maxlength="10" required>
				<mat-error *ngIf="updateDetailsForm.get('contactNumber').hasError('required')">This is a required field
				</mat-error>
				<mat-error *ngIf="updateDetailsForm.get('contactNumber').hasError('minlength')">A contact number should
					be of 10 digits.</mat-error>
			</mat-form-field>
			<br>
		</div>

		<div class="col-lg-6">
			<p class="placeholder_para">Employee Type</p>
			<mat-form-field appearance="outline" class="form_field_width">
				<mat-select required formControlName="employeeType">
					<mat-option value="FTE">FTE</mat-option>
					<mat-option value="DC">Direct Contractor</mat-option>
					<mat-option value="VC">Vendor Contractor</mat-option>
				</mat-select>
				<mat-error *ngIf="updateDetailsForm.get('employeeType').hasError('required')">
					This is a required field</mat-error>
			</mat-form-field>
		</div>
	</div>

	<div class="row">
		<div class="col-lg-6">
			<p class="placeholder_para">Email*</p>
			<mat-form-field appearance="outline" class="form_field_width">
				<input class="email-address-input" formControlName="emailAddress" type="email" matInput required
					[readonly]="isemailreadonly"
					pattern="^[A-Za-z0-9._%+-]+@(?!gspann.com)[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$">
				<mat-hint>GSPANN domain not allowed</mat-hint>
				<mat-error *ngIf="updateDetailsForm.get('emailAddress').hasError('required')">This is a required field
				</mat-error>
				<mat-error *ngIf="updateDetailsForm.get('emailAddress').hasError('pattern')">Please enter a valid email
					ID.</mat-error>
			</mat-form-field>
		</div>

			<div class="col-lg-6">
				<p class="checkbox-main">
					<mat-checkbox class="example-margin" formControlName="emailFlag">
						Email Notification
					</mat-checkbox>
				</p>
			</div>
	</div>
		<div class="col-lg-6">
			<div mat-dialog-actions class="buttons-position">
				<button mat-raised-button class="cancel-button-css" (click)="closeDialogBox()">CANCEL</button>
				<button mat-raised-button class="update-button-css" (click)="updateDetails(data)"
					[disabled]="!updateDetailsForm.valid">Update</button>
			</div>
		</div>
</form>