<mat-toolbar class="toolbar-style">
	<mat-toolbar-row>
		<div class="col-12 logout">
			<a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="dashboard"><img
					class="mainLogo" src="../../../../assets/gspann_new_logo.png"
					alt="With us, you are ready for new opportunities"></a>
			<div *appHasAuthority="'DASHBOARD_VIEW_CANDIDATE'">
				<img *ngIf="!setProfilePic" src="../../../../assets/User_Profile_Pic.png" class="avatar" (click)="openProfilePicture()"/>
				<img *ngIf="setProfilePic" [src]="setProfilePic" class="avatar" (click)="openProfilePicture()"/>
			</div>
			<a (click)="logout()" id="logout">
				<img src="../../../../assets/logout.png" class="logout-btn" />
			</a>
		</div>
	</mat-toolbar-row>
</mat-toolbar>
<div class="row" style="margin: 0;" *ngIf="(router.url !== '/dashboard' && router.url !== '/complete' && router.url !== '/reports' && router.url !=='/candidatesreports')">
	<div class="col-3 navigation-bar clearfix" *ngIf="dataChecked">

		<!-- CANDIDATE top sidenav view -->
		<mat-card class="candidate_name_class" *appHasAuthority="'DASHBOARD_VIEW_CANDIDATE'">
			<h5>Hi {{userDeatils.firstName}}!</h5>
			<p>Kindly furnish the information for each of the sections below.</p>
			<p>{{count}}% completed</p>
			<mat-progress-bar mode="determinate" [value]="count"></mat-progress-bar>
			<br>
			<!-- <p>Let's Begin</p> -->
		</mat-card>

		<!-- HR top sidenav view -->
		<mat-card class="candidate_name_class" *appHasAuthority="'DASHBOARD_VIEW_HR'">
			<h5 *ngIf="!candidateZipFileDetails">Hi {{userDeatils.firstName}}</h5>
			<br>
			<h6 *ngIf="candidateZipFileDetails">Candidate Name: {{candidateZipFileDetails.name}}</h6>
		</mat-card>

		<!-- HR employment screen  --> 
		<div class="candidate_stepper_class" *appHasAuthority="'DASHBOARD_VIEW_HR'">
			<div class="step">
				<div class="step_name" style="padding-bottom: 20px;"><a style="cursor: pointer; font-weight: bold;" (click)="goToDashboards()">Dashboard</a></div>
			</div>
			<hr style="margin-bottom: 0;">
			<div class="candidate_stepper_class">
				<div class="step">
					<div>
						<span class="circle" id="circle-0-1">1</span>
					</div>
					<div>
						<div class="title" (click)="empInfoClicked()">Employee Information Form</div>
					</div>
				</div>
				<div class="step" *ngIf="personalEmployeeType === 'FTE'">
					<div>
						<span class="circle" id="circle-0-2">2</span>
					</div>
					<div>
						<div class="title" routerLinkActive="active" routerLink="epf-declaration"
							[routerLinkActiveOptions]="{exact: true}">EPF Declaration</div>
					</div>
				</div>
				<div class="step"  *ngIf="personalEmployeeType === 'FTE'">
					<div>
						<span class="circle" id="circle-0-3" >3</span>
					</div>
					<div>
						<div class="title" routerLinkActive="active" routerLink="nominee-declaration"
							[routerLinkActiveOptions]="{exact: true}">Nominee Declaration</div>
					</div>
				</div>
				<div class="step"  *ngIf="personalEmployeeType === 'FTE'">
					<div>
						<span class="circle" id="circle-0-4">4</span>
					</div>
					<div>
						<div class="title" routerLinkActive="active" routerLink="police-verfication"
							[routerLinkActiveOptions]="{exact: true}">Police Verification</div>
					</div>
				</div>
				<div class="step" *ngIf="personalEmployeeType ==='FTE'">
					<div>
						<span class="circle" id="circle-0-5">5</span>
					</div>
					<div>
						<div class="title" (click)="hrDocsClicked()">Document Status</div>
					</div>
				</div>
				<div class="step" *ngIf="personalEmployeeType !=='FTE'">
					<div>
						<span class="circle" id="circle-0-2">2</span>
					</div>
					<div>
						<div class="title" (click)="hrDocsClicked()">Document Status</div>
					</div>
				</div>
				
				<div class="step" *ngIf="personalEmployeeType !=='FTE' && provideConsent">
					<div>
						<span class="circle" id="circle-0-6">3</span>
					</div>
					<div>
						<div class="title" (click)="BGVClicked()">BGV</div>	
					</div>
				</div>
				<div class="step" *ngIf="personalEmployeeType !=='FTE'">
					<div *ngIf="!provideConsent">
						<span class="circle" id="circle-0-6">3</span>
					</div>
					<div *ngIf="provideConsent">
						<span class="circle" id="circle-0-6">4</span>
					</div>
					<div>
						<div class="title" (click)="sendRequest()">OnBoard</div>	
					</div>
				</div>
				<div class="step" *ngIf="personalEmployeeType ==='FTE' && provideConsent">
					<div>
						<span class="circle" id="circle-0-6">6</span>
					</div>
					<div>
						<div class="title" (click)="BGVClicked()"
							>BGV
						</div>	
					</div>
				</div>
				<div class="step" *ngIf="personalEmployeeType ==='FTE'">
					<div *ngIf="!provideConsent">
						<span class="circle" id="circle-0-6">6</span>
					</div>
					<div *ngIf="provideConsent">
						<span class="circle" id="circle-0-6">7</span>
					</div>
					<div>
						<div class="title" (click)="sendRequest()"
							>OnBoard
						</div>	
					</div>
				</div>
			</div>
		</div>

		<!-- FTE sidenavbar on screen  --> 
		<div class="candidate_stepper_class" *appHasAuthority="'FULL_TIME_EMPLOYMENT'">
			<div class="step">
				<div>
					<span class="circle" id="circle-0-1">1</span>
					<!-- <i class="fa fa-check"></i> -->
				</div>
				<div>
					<div class="title" routerLinkActive="active" routerLink="emp-data"
						[routerLinkActiveOptions]="{exact: true}">Employee Information Form</div>
				</div>
			</div>
			<div class="step">
				<!-- <div class="step step-active"> -->
				<div>
					<span class="circle" id="circle-0-2">2</span>
				</div>
				<div>
					<div class="title" routerLinkActive="active" routerLink="epf-declaration"
						[routerLinkActiveOptions]="{exact: true}">EPF Declaration</div>
				</div>
			</div>
			<div class="step">
				<div>
					<span class="circle" id="circle-0-3">3</span>
				</div>
				<div>
					<div class="title" routerLinkActive="active" routerLink="nominee-declaration"
						[routerLinkActiveOptions]="{exact: true}">Nominee Declaration</div>
				</div>
			</div>
			<div class="step">
				<div>
					<span class="circle" id="circle-0-4">4</span>
				</div>
				<div>
					<div class="title" routerLinkActive="active" routerLink="police-verfication"
						[routerLinkActiveOptions]="{exact: true}">Police Verification</div>
				</div>
			</div>
			<div class="step">
				<div>
					<span class="circle" id="circle-0-5">5</span>
				</div>
				<div>
					<div class="title" (click)='docsClicked()'>Documents</div>
				</div>
			</div>
			<div class="step" *ngIf="showBGVOption">
				<div>
					<span class="circle" id="circle-0-6">6</span>
				</div>
				<div>
					<div class="title" routerLinkActive="active" routerLink="bgv-consent"
					[routerLinkActiveOptions]="{exact: true}">BGV Consent</div>	
				</div>
			</div>
		</div>

		<!-- DC and VC sidenavbar on screen  -->
		<div class="candidate_stepper_class" *appHasAuthority="['DIRECT_CONTRACT', 'VENDOR_CONTRACT']">
			<!-- <div *ngIf="empType !== 'FTE'"> -->
				<div class="step">
					<div>
						<span class="circle" id="circle-0-1">1</span>
					</div>
					<div>
						<div class="title" routerLinkActive="active" routerLink="emp-data"
							[routerLinkActiveOptions]="{exact: true}">Employee Information Form</div>
					</div>
				</div>
				<div class="step">
					<div>
						<span class="circle" id="circle-0-2">2</span>
					</div>
					<div>
						<div class="title" routerLinkActive="active" routerLink="documents"
							[routerLinkActiveOptions]="{exact: true}">Documents
						</div>	
					</div>
				</div>
				<!-- <div class="step" *ngIf="showBGVOption">
					<div>
						<span class="circle" id="circle-0-6">3</span>
					</div>
					<div>
						<div class="title" (click)="BGVClicked()">BGV</div>	
					</div>
				</div> -->
			<!-- </div> -->
		</div>
	</div>
</div>
<router-outlet></router-outlet>
<!-- <app-footer></app-footer> -->