import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import { PreBoardingService } from '../pre-boarding.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  isAuthenticated: boolean = false;
  private userIdentity: any;
  private authenticated = false;
  private authenticationState = new Subject<any>();
  authorities: string[];
  userDetails: any;
  token: any;

  constructor(private _router: Router, private preBoardingService: PreBoardingService, private keycloakService : KeycloakService) { }
  authenticate(signInData) {
    let payload = {
      username: signInData.email,
      password: signInData.password
    }
    // this.preBoardingService.login(payload).subscribe(res => {
    // })
    if (signInData.email === "ankit@gmail.com" && signInData.password === "ankit@123") {
      this.isAuthenticated = true;
      return true;
    } else {
      this.isAuthenticated = false;
      return false;
    }
  }

  async getLoggedUser():Promise<any> {
    try {
      this.authorities = this.keycloakService.getUserRoles();
      this.userDetails  = await this.keycloakService.loadUserProfile();

      return this.userDetails;
    } catch (e) {
      return undefined;
    }
  }

  async getToken ():Promise<any> {
    try {
      this.token = await this.keycloakService.getToken();
      return this.token
    }
    catch (err){
      return undefined;
    }
  }

  hasAnyAuthority(authorities: string[]): Promise<boolean> {
    this.authorities = this.keycloakService.getUserRoles();
    return Promise.resolve(this.hasAnyAuthorityDirect(authorities));
  }

  hasAnyAuthorityDirect(authorities: string[]): boolean {
    for (let i = 0; i < authorities.length; i++) {
        if (this.authorities.includes(authorities[i])) {
            return true;
        }
    }
    return false;
  }

  logout() {
    this.isAuthenticated = false;
    this._router.navigate(['']);
    this.keycloakService.logout();
  }
}
